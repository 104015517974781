import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getRecordTypes, SubsetName } from "api/dataList/recordTypesAPI";
import { AppThunk } from "app/store";
import { RecordTypeName } from "utils/types/General.types";

interface RecordTypeState {
  recordTypeList: Partial<RecordTypeName>[];
  isLoading: boolean;
  error: string | null;
}

const initialState: RecordTypeState = {
  recordTypeList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: RecordTypeState) {
  state.isLoading = true;
}

function loadingFailed(state: RecordTypeState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const recordTypesSlice = createSlice({
  name: "recordTypes",
  initialState,
  reducers: {
    getRecordTypesStart: startLoading,
    getRecordTypesSuccess(
      state,
      { payload }: PayloadAction<Partial<RecordTypeName>[]>
    ) {
      state.isLoading = false;
      state.error = null;
      state.recordTypeList = payload;
    },
    getRecordTypesFailure: loadingFailed,
  },
});

export const {
  getRecordTypesStart,
  getRecordTypesSuccess,
  getRecordTypesFailure,
} = recordTypesSlice.actions;

export default recordTypesSlice.reducer;

export const fetchRecordTypes =
  (accessToken: string, subsetName?: SubsetName): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getRecordTypesStart());

      const recordTypeList = await getRecordTypes(accessToken, subsetName);

      dispatch(getRecordTypesSuccess(recordTypeList));
    } catch (err: any) {
      dispatch(getRecordTypesFailure(err.toString()));
    }
  };
