import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import { IppFormDivider } from "components/IppFormDivider";
import { IppAutocomplete } from "components/IppAutocomplete";
import LoadingIndicator from "components/LoadingIndicator";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import { Prompt } from "react-router";
import { IppDisplayField } from "components/IppDisplayField";
import { IppDatePicker } from "components/IppDatePicker";
import {
  ConvertDateOffset,
  ConvertToJSDate,
  GetJSDate,
} from "utils/DateFunctions";
import { addContract, updContract, delContract } from "./ContractSlice";
import { fetchCompanies } from "features/benefits/companies/CompaniesSlice";
import { fetchProjects } from "features/project/ProjectSlice";
import { ContractValidationSchema } from "../contract/ContractValidation";
import { IsMedSmall } from "utils/mediaQueries";
import { UserWriteAccess } from "utils/userAccess";
import { ShowAsPercent } from "utils/functions";
import { IppCheckbox } from "components/IppCheckbox";
import { IppDisplayCheckbox } from "components/IppDisplayCheckbox";
import { fetchCompanyProjects } from "features/notifications/companyProjectSlice";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { IppPercentField } from "components/IppPercentField";
import { useTypedTranslation } from "utils/customHooks";

const PREFIX = "Contract";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const ContractForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const customBP = IsMedSmall();
  const t = useTypedTranslation(["objBen", "objPlt"]);

  const dataItem = props.contract || {};
  const [isEditing, setIsEditing] = useState(
    dataItem.ContractID ? false : true
  );
  const [isAdding, setIsAdding] = useState(dataItem.ContractID ? false : true);
  const [projectID, setProjectID] = useState(dataItem.ProjectID || 0);
  const [companyID, setCompanyID] = useState(dataItem.CompanyID || 0);
  const showEdit = UserWriteAccess("Benefits", dataItem.ProjectID);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { currentProfile, currentUserRoleList, currentUserRolesById } =
    useSelector((state: RootState) => state.profile);

  // if non-admin, only show currentUserRoles correspondering to benefits Module (ModuleRoleID === 1 || 2)
  const currentUserRoles = currentProfile.IsClientAdmin
    ? currentUserRoleList.map((id) => currentUserRolesById[id])
    : currentUserRoleList
        .map((id) => currentUserRolesById[id])
        .filter((role) => role.ModuleRoleID === 1 || role.ModuleRoleID === 2);

  // list of project IDs available in this Module
  const accessibleProjectIDs = currentUserRoles.map((role) => role.ProjectID);

  const {
    companiesById,
    companyList,
    error: companyError,
    isLoading: companyIsLoading,
  } = useSelector((state: RootState) => state.companies);

  const companies = companyList.map((CompanyID) => companiesById[CompanyID]);
  const confirmedCompanies = companies.filter((comp) => comp.ListingConfirmed);

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
    error: projectError,
  } = useSelector((state: RootState) => state.projects);

  // if admin, show all projects
  // if non-admin, only show projects included in accessibleProjectIds
  const projects = currentProfile.IsClientAdmin
    ? projectList.map((id) => projectsById[id])
    : projectList
        .map((id) => projectsById[id])
        .filter((project) => accessibleProjectIDs.includes(project.ProjectID));

  const { companyProjectList, companyProjectsById } = useSelector(
    (state: RootState) => state.companyProjects
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchProjects(accessToken));
        dispatch(fetchCompanies(accessToken, clientId));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(addContract(accessToken, values));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (!isAdding) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          // Remove Null values
          if (!values.IsLocal) values.IsLocal = false;
          if (!values.IsIndigenous) values.IsIndigenous = false;

          dispatch(updContract(accessToken, values.ContractID, values));
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    dataItem.CompanyID = currentProfile.IsClient
      ? -1
      : currentProfile.CompanyID;
    dataItem.CompanyName = "";
    dataItem.ProjectID = -1;
    dataItem.ContractNumber = "";
    dataItem.ContractTitle = "";
    dataItem.ContractScope = "";
    dataItem.AwardDate = null;
    dataItem.ExpiryDate = null;
    dataItem.ContractNotes = "";
    dataItem.EmployeeTarget = 0;
    dataItem.HoursTarget = 0;
    dataItem.ExpenditureTarget = 0;
    dataItem.IsLocal = false;
    dataItem.IsIndigenous = false;
    dataItem.SpendReportingRequired = false;
    dataItem.EmployeeReportingRequired = false;
    dataItem.IsActive = true;
  }

  const handleDelete = () => {
    // function to delete current Contract entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delContract(accessToken, dataItem.ContractID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const formik = useFormik({
    initialValues: dataItem,
    validationSchema: ContractValidationSchema(),
    onSubmit: submitFunc,
  });

  const reportingRequired =
    formik.values.SpendReportingRequired ||
    formik.values.EmployeeReportingRequired;

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (formik.values.CompanyID > 0 && formik.values.ProjectID > 0) {
          if (isEditing) {
            dispatch(fetchCompanyProjects(accessToken));
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [
    formik.values.CompanyID,
    formik.values.ProjectID,
    isEditing,
    dispatch,
    getAccessTokenSilently,
  ]);

  const companyProjects = companyProjectList.map(
    (id) => companyProjectsById[id]
  );

  const currentCompanyProject = companyProjects.find(
    (item) =>
      item.CompanyID === formik.values.CompanyID &&
      item.ProjectID === formik.values.ProjectID
  );

  const activeUsers = currentCompanyProject
    ? currentCompanyProject.ActiveUsers
    : 0;

  let viewForm = (
    <Root>
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>
              <Grid container spacing={1}>
                <IppFormHeader
                  title="Contract"
                  isEditing={isEditing}
                  isAdding={isAdding}
                  returnPath="/benefits/contracts"
                />
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Contract Number"
                    value={formik.values.ContractNumber}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEmphasis={true}
                    showEdit={showEdit && currentProfile.IsClient}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Contract Title"
                    value={formik.values.ContractTitle}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEmphasis={true}
                    showEdit={showEdit && currentProfile.IsClient}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Award Date"
                    value={ConvertToJSDate(formik.values.AwardDate)}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit && currentProfile.IsClient}
                    //showEmphasis={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Expiry Date"
                    value={ConvertToJSDate(formik.values.ExpiryDate)}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit && currentProfile.IsClient}
                    //showEmphasis={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Project"
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    value={
                      formik.values.ProjectID > 0 &&
                      projectsById[formik.values.ProjectID]
                        ? projectsById[formik.values.ProjectID].ProjectName
                        : ""
                    }
                    showEdit={showEdit && currentProfile.IsClient}
                    //showEmphasis={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Company"
                    value={
                      formik.values.CompanyID > 0 &&
                      companiesById[formik.values.CompanyID]
                        ? companiesById[formik.values.CompanyID].CompanyName
                        : ""
                    }
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    isWebLink={formik.values.CompanyName ? true : false}
                    route={`${window.location.href.substring(
                      0,
                      window.location.href.lastIndexOf("contracts")
                    )}companies/${formik.values.CompanyID}`}
                    openInNewTab={false}
                    tooltip="Click to view Company"
                    showEdit={showEdit && currentProfile.IsClient}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Contract Scope"
                    value={formik.values.ContractScope}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit && currentProfile.IsClient}
                    //showEmphasis={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Contract Notes"
                    value={formik.values.ContractNotes}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit && currentProfile.IsClient}
                    //showEmphasis={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayCheckbox
                    label="Active Contract"
                    isChecked={formik.values.IsActive}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                  />
                </Grid>
                <IppFormDivider title="Reporting Requirements" />
                <Grid item xs={6}>
                  <IppDisplayCheckbox
                    label="Spend Reporting Required"
                    isChecked={formik.values.SpendReportingRequired}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={currentProfile.IsClient && showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayCheckbox
                    label="Employee Reporting Required"
                    isChecked={formik.values.EmployeeReportingRequired}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={currentProfile.IsClient && showEdit}
                  />
                </Grid>

                <IppFormDivider title="Contract Classification" />
                <Grid item xs={6}>
                  <IppDisplayCheckbox
                    label="Local"
                    isChecked={formik.values.IsLocal}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={currentProfile.IsClient && showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayCheckbox
                    label={t("objPlt:platformwide.indigenous.name")}
                    isChecked={formik.values.IsIndigenous}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={currentProfile.IsClient && showEdit}
                  />
                </Grid>
                {!currentProfile.IsClient && (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                  >
                    <div>
                      {/* even when a company user has write permissions they still cannot edit contracts*/}
                      <h4>You can only view a contract</h4>
                    </div>
                  </Grid>
                )}

                {/* targets  */}
                <IppFormDivider title="Targets" />
                <Grid item xs={4}>
                  <IppDisplayField
                    label="Employees"
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit && currentProfile.IsClient}
                    value={ShowAsPercent(formik.values.EmployeeTarget)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <IppDisplayField
                    label="Hours"
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit && currentProfile.IsClient}
                    value={ShowAsPercent(formik.values.HoursTarget)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <IppDisplayField
                    label="Expenditures"
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit && currentProfile.IsClient}
                    value={ShowAsPercent(formik.values.ExpenditureTarget)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <IppFormButtons
                    isEditing={isEditing}
                    isAdding={isAdding}
                    setIsEditing={setIsEditing}
                    resetFunction={() => formik.resetForm()}
                    showDelete={currentProfile.IsClient && showEdit}
                    deleteFunction={() => handleDelete()}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Root>
  );

  let editForm = (
    <Root>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message="You have unsaved changes; are you sure you want to leave this page?"
        />
      )}

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <IppFormHeader
            title="Contract"
            isEditing={isEditing}
            isAdding={isAdding}
            returnPath="/benefits/contracts"
          />
          <IppFormDivider title="Contract Header" />
          <Grid item xs={6}>
            <IppTextField
              id="ContractNumber"
              label="Contract Number*"
              value={formik.values.ContractNumber}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.ContractNumber}
              errorsExpression={formik.errors.ContractNumber}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="ContractTitle"
              label="Contract Title*"
              value={formik.values.ContractTitle}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.ContractTitle}
              errorsExpression={formik.errors.ContractTitle}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              multiLine
            />
          </Grid>
          <Grid item xs={6}>
            <IppDatePicker
              id="AwardDate"
              label="Award Date"
              value={ConvertDateOffset(formik.values.AwardDate)}
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue("AwardDate", GetJSDate(newValue), true);
                formik.setFieldTouched("AwardDate", true, false); //affects live validations
              }}
              errorsExpression={formik.errors.AwardDate}
              touchedExpression={formik.touched.AwardDate}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppDatePicker
              id="ExpiryDate"
              label="Expiry Date"
              value={ConvertDateOffset(formik.values.ExpiryDate)}
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue("ExpiryDate", GetJSDate(newValue), true);
                formik.setFieldTouched("ExpiryDate", true, true); //affects live validations
              }}
              errorsExpression={formik.errors.ExpiryDate}
              touchedExpression={formik.touched.ExpiryDate}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>

          <Grid item xs={6}>
            <IppAutocomplete
              id="CompanyID"
              options={confirmedCompanies.sort((a, b) =>
                a.ShowAsActive.localeCompare(b.ShowAsActive)
              )}
              groupBy={(option: any) => option.ShowAsActive}
              value={confirmedCompanies.find((obj) => {
                return obj.CompanyID === formik.values.CompanyID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("CompanyID", newValue.CompanyID);
                } else {
                  formik.setFieldValue("CompanyID", -1);
                }
              }}
              label="Company"
              required={true}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.CompanyName}
              errorFunction={formik.errors.CompanyID}
              touchedFunction={formik.touched.CompanyID}
              textValueFunction={formik.values.CompanyName}
              disabled={!currentProfile.IsClient}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="ProjectID"
              options={projects}
              value={projects.find((obj) => {
                return obj.ProjectID === formik.values.ProjectID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("ProjectID", newValue.ProjectID);
                } else {
                  formik.setFieldValue("ProjectID", -1);
                }
              }}
              label="Project*"
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.ProjectName}
              errorFunction={formik.errors.ProjectID}
              touchedFunction={formik.touched.ProjectID}
              textValueFunction={formik.values.ProjectName}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="ContractScope"
              label="Contract Scope"
              value={formik.values.ContractScope}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.ContractScope}
              errorsExpression={formik.errors.ContractScope}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              multiLine
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="ContractNotes"
              label="Contract Notes"
              value={formik.values.ContractNotes}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.ContractNotes}
              errorsExpression={formik.errors.ContractNotes}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              multiLine
            />
          </Grid>
          <Grid item xs={6}>
            <IppCheckbox
              id="IsActive"
              label="Active Contract"
              value={formik.values.IsActive}
              onChangeFunction={formik.handleChange}
              isEditing={isEditing}
            />
          </Grid>
          <IppFormDivider title="Reporting Requirements" />
          <Grid item xs={6}>
            <IppCheckbox
              id="SpendReportingRequired"
              label="Spend Reporting Required"
              value={formik.values.SpendReportingRequired}
              onChangeFunction={formik.handleChange}
              isEditing={isEditing}
              disabled={
                !(formik.values.CompanyID > 0 && formik.values.ProjectID > 0)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <IppCheckbox
              id="EmployeeReportingRequired"
              label="Employee Reporting Required"
              value={formik.values.EmployeeReportingRequired}
              onChangeFunction={formik.handleChange}
              isEditing={isEditing}
              disabled={
                !(formik.values.CompanyID > 0 && formik.values.ProjectID > 0)
              }
            />
          </Grid>
          {activeUsers === 0 && reportingRequired && (
            <Grid item xs={6}>
              <Typography variant="body2" color="error">
                The selected Company and Project do not have an appropriate User
                with permissions to perform these tasks. Please make sure to add
                a user with the appropriate permissions to the Company if you
                create a contract with these requirements.{" "}
                <Link to="/admin/users/all/new">Add User</Link>{" "}
              </Typography>
            </Grid>
          )}
          <IppFormDivider title="Contract Classification" />
          <Grid item xs={6}>
            <IppCheckbox
              id="IsLocal"
              label="Local"
              value={formik.values.IsLocal}
              onChangeFunction={formik.handleChange}
              isEditing={isEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppCheckbox
              id="IsIndigenous"
              label={t("objPlt:platformwide.indigenous.name")}
              value={formik.values.IsIndigenous}
              onChangeFunction={formik.handleChange}
              isEditing={isEditing}
            />
          </Grid>

          {/* targets  */}
          <IppFormDivider title="Targets By Percent" />
          <Grid item xs={4}>
            <IppPercentField
              id="EmployeeTarget"
              label={t("objBen:objects.contract.fields.targets.employee")}
              value={formik.values.EmployeeTarget}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.EmployeeTarget}
              errorsExpression={formik.errors.EmployeeTarget}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              toolTip="Values are rounded to whole numbers."
            />
          </Grid>
          <Grid item xs={4}>
            <IppPercentField
              id="HoursTarget"
              label={t("objBen:objects.contract.fields.targets.hours")}
              value={formik.values.HoursTarget}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.HoursTarget}
              errorsExpression={formik.errors.HoursTarget}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={4}>
            <IppPercentField
              id="ExpenditureTarget"
              label={t("objBen:objects.contract.fields.targets.expenditure")}
              value={formik.values.ExpenditureTarget}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.ExpenditureTarget}
              errorsExpression={formik.errors.ExpenditureTarget}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>

          <Grid item xs={12}>
            <IppFormButtons
              isEditing={isEditing}
              isAdding={isAdding}
              setIsEditing={setIsEditing}
              resetFunction={() => formik.resetForm()}
              showDelete={true}
              deleteFunction={() => handleDelete()}
            />
          </Grid>
        </Grid>
      </form>
    </Root>
  );

  let ContractForm =
    clientIsLoading || companyIsLoading || projectIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>{editForm}</Paper>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xl={4} xs={customBP ? 12 : 5}>
          {isEditing && currentProfile.IsClient ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xl={8} xs={customBP ? 12 : 7}>
          <Box>
            <Paper>
              {!isAdding && (
                <Root>
                  <Box display="flex" justifyContent="center">
                    <Paper className={classes.boxSpace}>
                      <Grid container>
                        <IppAttachmentInventory
                          recordType="Contract"
                          itemID={dataItem.ContractID}
                          companyID={formik.values.CompanyID}
                          projectID={projectID}
                          moduleID={1}
                          title={t("objBen:objects.contract.attachments")}
                          categoryRecordType="Contract"
                        />
                      </Grid>
                    </Paper>
                  </Box>
                </Root>
              )}
            </Paper>
          </Box>
        </Grid>
      </Grid>
    );

  return <Root>{ContractForm}</Root>;
};
