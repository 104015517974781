// ------------ Module Types ------------
export enum Modules {
  Benefits = 1,
  Commitments = 2,
  Engagements = 3,
  Planning = 4,
  Supplier = 5,
  SSHE = 6,
}

export enum ModuleRoles {
  BenefitsPowerUser = 1,
  BenefitsCompanyUser = 2,
  CommitmentsPowerUser = 3,
  CommitmentsContributor = 4,
  EngagementsPowerUser = 5,
  BenefitsViewOnly = 7,
  CommitmentsViewOnly = 8,
  EngagementsViewOnly = 9,
  SupplierPowerUser = 10,
  SSHEUser = 11,
}

//----------------- Record Types -----------------
// All record types and their properties.
// as const is used to infer a readonly type.
export const RecordTypes = {
  Asset: { IsAttachmentCategoryRecordType: true },
  Commitment: { IsAttachmentCategoryRecordType: true },
  CommitmentAction: { IsAttachmentCategoryRecordType: true },
  CommitmentSource: { IsAttachmentCategoryRecordType: true },
  CommunityInvestment: { IsAttachmentCategoryRecordType: true },
  Company: { IsAttachmentCategoryRecordType: true },
  Contact: { IsAttachmentCategoryRecordType: true },
  Contract: { IsAttachmentCategoryRecordType: true },
  CustomRnD: { IsAttachmentCategoryRecordType: false },
  EducationTraining: { IsAttachmentCategoryRecordType: false },
  Employee: { IsAttachmentCategoryRecordType: false },
  EnTExpense: { IsAttachmentCategoryRecordType: true },
  Grievance: { IsAttachmentCategoryRecordType: true },
  Initiative: { IsAttachmentCategoryRecordType: true },
  Interaction: { IsAttachmentCategoryRecordType: true },
  InteractionAction: { IsAttachmentCategoryRecordType: true },
  InteractionComment: { IsAttachmentCategoryRecordType: false },
  Issue: { IsAttachmentCategoryRecordType: true },
  Payment: { IsAttachmentCategoryRecordType: true },
  PaymentPeriod: { IsAttachmentCategoryRecordType: true },
  Phase: { IsAttachmentCategoryRecordType: false },
  Project: { IsAttachmentCategoryRecordType: true },
  ProjectComponent: { IsAttachmentCategoryRecordType: false },
  ProjectType: { IsAttachmentCategoryRecordType: false },
  RnDExpense: { IsAttachmentCategoryRecordType: true },
  SSHE: { IsAttachmentCategoryRecordType: false },
  StakeholderGroup: { IsAttachmentCategoryRecordType: true },
  Supplier: { IsAttachmentCategoryRecordType: true },
  Tag: { IsAttachmentCategoryRecordType: false },
  Thread: { IsAttachmentCategoryRecordType: true },
  Tier: { IsAttachmentCategoryRecordType: false },
  WorkHourDetail: { IsAttachmentCategoryRecordType: false },
  WorkHourPeriod: { IsAttachmentCategoryRecordType: true },
} as const;

export type RecordTypeName = keyof typeof RecordTypes;

// Type for record type names that are attachment category record types.
export type AttachmentCategoryRecordTypeName = {
  [K in RecordTypeName]: (typeof RecordTypes)[K]["IsAttachmentCategoryRecordType"] extends true
    ? K
    : never;
}[RecordTypeName];
