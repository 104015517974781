import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addCommunityInvestment,
  updCommunityInvestment,
  delCommunityInvestment,
} from "./CommunityInvestmentSlice";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import { IppFormDivider } from "components/IppFormDivider";
import { IppCheckbox } from "components/IppCheckbox";
import { IppAutocomplete } from "components/IppAutocomplete";
import { fetchCategories } from "features/datalists/CategorySlice";
import { fetchIndigenousGroupsByClient } from "features/datalists/IndigenousGroupSlice";
import { fetchProjects } from "features/project/ProjectSlice";
import { fetchCompanies } from "features/benefits/companies/CompaniesSlice";
import { fetchProjectComponentsByProject } from "features/project/ProjectComponentSlice";
import { IppCurrencyField } from "components/IppCurrencyField";
import LoadingIndicator from "components/LoadingIndicator";
import SmallLoadingIndicator from "components/SmallLoadingIndicator";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import { Prompt } from "react-router";
import { IppDisplayField } from "components/IppDisplayField";
import { IppDatePicker } from "components/IppDatePicker";
import {
  ConvertDateOffset,
  GetJSDate,
  getDateFromDateString,
} from "utils/DateFunctions";
import { fetchCommunityInvestmentTypes } from "features/datalists/CommunityInvestmentTypeSlice";
import { IsMedSmall } from "utils/mediaQueries";
import { UserWriteAccess } from "utils/userAccess";
import { IppLocationAutoComplete } from "components/IppLocationAutoComplete";
import { CommunityInvestmentValidationSchema } from "./CommunityInvestmentValidation";
import { IppCurrencyDisplay } from "components/IppCurrencyDisplayField";
import { useTypedTranslation } from "utils/customHooks";
import { Company } from "api/companyAPI";
import { CompanyOptionsRender } from "utils/renderFunctions";

const PREFIX = "CommunityInvestmentForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const CommunityInvestmentForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt"]);

  const customBP = IsMedSmall();

  let invData = props.communityInvestment || {};

  const [isEditing, setIsEditing] = useState(
    invData.CommunityInvestmentID ? false : true
  );
  const [isAdding, setIsAdding] = useState(
    invData.CommunityInvestmentID ? false : true
  );
  const [projectID, setProjectID] = useState(invData.ProjectID || 0);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { currentProfile, currentUserRoleList, currentUserRolesById } =
    useSelector((state: RootState) => state.profile);

  const userRoles = currentUserRoleList.map((id) => currentUserRolesById[id]);

  const showEdit = UserWriteAccess("Benefits", invData.ProjectID);

  const {
    indigenousGroupList,
    indigenousGroupsById,
    error: indigenousGroupError,
    isLoading: indigenousGroupIsLoading,
  } = useSelector((state: RootState) => state.indigenousGroups);

  const indigenousGroups = indigenousGroupList.map(
    (IndigenousGroupID) => indigenousGroupsById[IndigenousGroupID]
  );

  const {
    companiesById,
    companyList,
    isLoading: companyIsLoading,
  } = useSelector((state: RootState) => state.companies);

  const companies = companyList.map((CompanyID) => companiesById[CompanyID]);
  const confirmedCompanies = companies.filter((comp) => comp.ListingConfirmed);

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
  } = useSelector((state: RootState) => state.projects);

  const projects = projectList
    .map((id) => projectsById[id])
    .filter((proj) => {
      if (currentProfile.IsClientAdmin) {
        return true;
      } else {
        return userRoles.some((role) => {
          return role.ProjectID === proj.ProjectID && role.ModuleID === 1;
        });
      }
    });

  const {
    projectComponentList,
    projectComponentsById,
    isLoading: projectComponentIsLoading,
    error: projectComponentError,
  } = useSelector((state: RootState) => state.projectComponents);

  const projectcomponents = projectComponentList.map(
    (id) => projectComponentsById[id]
  );

  const {
    communityInvestmentTypeList,
    communityInvestmentTypesById,
    isLoading: communityInvestmentTypeIsLoading,
  } = useSelector((state: RootState) => state.communityInvestmentTypes);

  const communityinvestmenttypes = communityInvestmentTypeList.map(
    (id) => communityInvestmentTypesById[id]
  );

  // const { communityInvestmentGroupList, communityInvestmentGroupsById } =
  //   useSelector((state: RootState) => state.communityInvestmentGroups);

  // const communityInvestmentGroups = communityInvestmentGroupList.map(
  //   (id) => communityInvestmentGroupsById[id].CommunityInvestmentGroupName
  // );

  // useEffect for when project changes
  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (projectID > 0) {
          dispatch(fetchProjectComponentsByProject(accessToken, projectID));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [projectID, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCategories(accessToken));
        dispatch(fetchIndigenousGroupsByClient(accessToken));
        dispatch(fetchProjects(accessToken));
        // dispatch(fetchProjectComponents(accessToken));
        dispatch(fetchCompanies(accessToken, clientId));
        dispatch(fetchCommunityInvestmentTypes(accessToken));
        // dispatch(fetchCommunityInvestmentGroups(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        values.ProjectID = projectID;
        dispatch(addCommunityInvestment(accessToken, values));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (invData.CommunityInvestmentID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          dispatch(
            updCommunityInvestment(
              accessToken,
              values.CommunityInvestmentID,
              values
            )
          );
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    invData.OrganizationName = "";
    invData.TownCity = "";
    invData.ProvinceState = "";
    invData.CountryName = "";
    invData.Indigenous = false;
    invData.NativeAmerican = false;
    invData.ProjectID = -1;
    invData.ProjectComponentID = -1;
    invData.CompanyID = -1;
    invData.SpendAmount = 0;
    invData.InvestmentDate = null;
    invData.CommunityInvestmentTypeID = -1;
    invData.InvestmentNotes = "";
    invData.Description = "";
    // invData.CommunityInvestmentGroup = undefined;
  }

  const handleDelete = () => {
    // function to delete current community investment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(
          delCommunityInvestment(
            accessToken,
            invData.CommunityInvestmentID,
            invData
          )
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const formik = useFormik({
    initialValues: invData,
    validationSchema: CommunityInvestmentValidationSchema(),
    onSubmit: submitFunc,
  });

  useEffect(() => {
    if (projectID && projectID > 0) {
      if (formik.values.ProjectID !== projectID)
        formik.setFieldValue("ProjectID", projectID);
    }
  }, [projectID]);

  // when editing, get project id
  if (!isAdding && projectID === 0) {
    if (!projectComponentIsLoading) {
      let pc = projectComponentsById[invData.ProjectComponentID];
      if (pc) {
        setProjectID(pc.ProjectID);
      }
    }
  }

  let viewForm = (
    <Root>
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>
              <Grid container spacing={1}>
                <IppFormHeader
                  title="Community Investment"
                  isEditing={isEditing}
                  isAdding={isAdding}
                  returnPath="/benefits/communityInvestments"
                />
                <IppFormDivider title="Organization Details" />
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Organization"
                    value={formik.values.OrganizationName}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEmphasis={false}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Location"
                    value={
                      (formik.values.TownCity || "") +
                      "  " +
                      (formik.values.ProvinceState || "") +
                      "  " +
                      (formik.values.CountryName || "")
                    }
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objPlt:objects.project.name")}
                    value={
                      !projectIsLoading && projectID > 0
                        ? projectsById[projectID].ProjectName
                        : ""
                    }
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objPlt:objects.projectcomponent.name")}
                    value={
                      !projectComponentIsLoading &&
                      !projectComponentError &&
                      formik.values.ProjectComponentID > 0 &&
                      projectID > 0
                        ? projectComponentsById[
                            formik.values.ProjectComponentID
                          ]?.ProjectComponentName
                        : ""
                    }
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Company"
                    value={
                      !companyIsLoading &&
                      formik.values.CompanyID > 0 &&
                      companiesById[formik.values.CompanyID]
                        ? companiesById[formik.values.CompanyID].CompanyName
                        : ""
                    }
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <IppDisplayField
                    label="Community Groups"
                    value={formik.values.CommunityInvestmentGroup}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objPlt:platformwide.indigenous.indigenousgroup")}
                    value={
                      !indigenousGroupIsLoading &&
                      !indigenousGroupError &&
                      formik.values.IndigenousGroupID > 0 &&
                      indigenousGroupsById[formik.values.IndigenousGroupID]
                        ? indigenousGroupsById[formik.values.IndigenousGroupID]
                            .IndigenousGroupName
                        : "Not Applicable"
                    }
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <IppFormDivider title="Investment Details" />
                <Grid item xs={12}>
                  <IppDisplayField
                    label="Description"
                    value={formik.values.Description}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppCurrencyDisplay
                    label="Investment Amount"
                    value={formik.values.SpendAmount}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Date"
                    value={getDateFromDateString(formik.values.InvestmentDate)}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Investment Type"
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    value={
                      !communityInvestmentTypeIsLoading &&
                      formik.values.CommunityInvestmentTypeID > 0
                        ? communityInvestmentTypesById[
                            formik.values.CommunityInvestmentTypeID
                          ]?.CommunityInvestmentTypeName
                        : ""
                    }
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Comments"
                    value={formik.values.InvestmentNotes}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>

                <Grid item xs={12}>
                  <IppFormButtons
                    isEditing={isEditing}
                    isAdding={isAdding}
                    setIsEditing={setIsEditing}
                    resetFunction={() => formik.resetForm()}
                    showDelete={showEdit}
                    deleteFunction={() => handleDelete()}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Root>
  );

  let editForm = (
    <Root>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message="You have unsaved changes; are you sure you want to leave this page?"
        />
      )}

      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <IppFormHeader
            title="Community Investment"
            isEditing={isEditing}
            isAdding={isAdding}
            returnPath="/benefits/communityInvestments"
          />
          <IppFormDivider title="Organization Details" />
          <Grid item xs={6}>
            <IppTextField
              id="OrganizationName"
              label="Organization*"
              value={formik.values.OrganizationName}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.OrganizationName}
              errorsExpression={formik.errors.OrganizationName}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <IppLocationAutoComplete
            formik={formik}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showAddress={false}
            postalCodeOptions={{ show: false, required: false }}
            townCityOptions={{ show: true, required: true }}
          />
          <Grid item xs={6}>
            <IppAutocomplete
              id="ProjectID"
              options={projects}
              value={projects.find((obj) => {
                return obj.ProjectID === projectID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  setProjectID(newValue.ProjectID);
                  formik.setFieldValue("ProjectID", newValue.ProjectID);
                  formik.setFieldValue("ProjectComponentID", -1);
                } else {
                  formik.setFieldValue("ProjectID", -1);
                }
              }}
              label={t("objPlt:objects.project.name")}
              required={true}
              isEditing={isEditing}
              touchedFunction={formik.touched.ProjectID}
              errorFunction={formik.errors.ProjectID}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.ProjectName}
              textValueFunction={
                !projectIsLoading && projectID > 0
                  ? projectsById[projectID].ProjectName
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6}>
            {projectComponentIsLoading ? (
              <SmallLoadingIndicator />
            ) : (
              <IppAutocomplete
                id="ProjectComponentID"
                options={projectID > 0 ? projectcomponents : []}
                value={projectcomponents.find((obj) => {
                  return (
                    obj.ProjectComponentID === formik.values.ProjectComponentID
                  );
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue(
                      "ProjectComponentID",
                      newValue.ProjectComponentID
                    );
                  } else {
                    formik.setFieldValue("ProjectComponentID", -1);
                  }
                }}
                label={t("objPlt:objects.projectcomponent.name")}
                required={true}
                touchedFunction={formik.touched.ProjectComponentID}
                errorFunction={formik.errors.ProjectComponentID}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) =>
                  option.ProjectComponentName
                }
                textValueFunction={
                  !projectComponentIsLoading &&
                  !projectComponentError &&
                  formik.values.ProjectComponentID > 0 &&
                  projectID > 0
                    ? formik.values.ProjectComponentName
                    : ""
                }
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="CompanyID"
              options={confirmedCompanies.sort((a, b) =>
                a.ShowAsActive.localeCompare(b.ShowAsActive)
              )}
              groupBy={(option: any) => option.ShowAsActive}
              value={confirmedCompanies.find((obj) => {
                return obj.CompanyID === formik.values.CompanyID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("CompanyID", newValue.CompanyID);
                } else {
                  formik.setFieldValue("CompanyID", -1);
                }
              }}
              label="Company*"
              touchedFunction={formik.touched.CompanyID}
              errorFunction={formik.errors.CompanyID}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.CompanyName}
              renderOption={(props: any, option: Company) => {
                return CompanyOptionsRender(props, option);
              }}
              textValueFunction={
                !companyIsLoading &&
                formik.values.CompanyID > 0 &&
                companiesById[formik.values.CompanyID]
                  ? companiesById[formik.values.CompanyID].CompanyName
                  : ""
              }
            />
          </Grid>
          {/* <Grid item xs={6}>
            <IppMultiTextField
              id="CommunityInvestmentGroup"
              label="Community Groups"
              required={false}
              options={communityInvestmentGroups}
              noOptionsText="No available options"
              value={formik.values.CommunityInvestmentGroup}
              setValue={(newValue: any) =>
                formik.setFieldValue(
                  "CommunityInvestmentGroup",
                  newValue.toString()
                )
              }
              touchedFunction={formik.touched.CommunityInvestmentGroup}
              errorFunction={formik.errors.CommunityInvestmentGroup}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              multiple={true}
              autoPopulate={false}
              freeSolo={true}
            />
          </Grid> */}
          <Grid item xs={6}>
            <IppCheckbox
              id="Indigenous"
              label={t("objPlt:platformwide.indigenous.name")}
              value={formik.values.Indigenous}
              isEditing={isEditing}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.handleChange(event);
                  formik.setFieldValue("IndigenousGroupID", null);
                  formik.setFieldValue("Indigenous", newValue);
                } else {
                  formik.setFieldValue("IndigenousGroupID", null);
                  formik.setFieldValue("Indigenous", false);
                }
              }}
            />
          </Grid>
          {formik.values.Indigenous === true && (
            <Grid item xs={6}>
              <IppAutocomplete
                id="IndigenousGroupID"
                options={indigenousGroups}
                value={indigenousGroups.find((obj) => {
                  return (
                    obj.IndigenousGroupID === formik.values.IndigenousGroupID
                  );
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue(
                      "IndigenousGroupID",
                      newValue.IndigenousGroupID
                    );
                  } else {
                    formik.setFieldValue("IndigenousGroupID", null);
                  }
                }}
                label={t("objPlt:platformwide.indigenous.indigenousgroup")}
                touchedFunction={formik.touched.IndigenousGroupID}
                errorFunction={formik.errors.IndigenousGroupID}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) =>
                  option.IndigenousGroupName
                }
                textValueFunction={
                  !indigenousGroupIsLoading &&
                  !indigenousGroupError &&
                  formik.values.IndigenousGroupID > 0 &&
                  indigenousGroupsById[formik.values.IndigenousGroupID]
                    ? indigenousGroupsById[formik.values.IndigenousGroupID]
                        .IndigenousGroupName
                    : ""
                }
              />
            </Grid>
          )}
          <IppFormDivider title="Investment Details" />
          <Grid item xs={12}>
            <IppTextField
              id="Description"
              label="Description"
              value={formik.values.Description}
              required={false}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.Description}
              errorsExpression={formik.errors.Description}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppCurrencyField
              id="SpendAmount"
              label="Investment Amount"
              required={true}
              value={formik.values.SpendAmount}
              onChangeFunction={(newValue) =>
                formik.setFieldValue("SpendAmount", newValue)
              }
              touchedExpression={formik.touched.SpendAmount}
              errorsExpression={formik.errors.SpendAmount}
              isEditing={isEditing}
              allowNegative={false}
            />
          </Grid>
          <Grid item xs={6}>
            <IppDatePicker
              id="InvestmentDate"
              label="Date*"
              value={ConvertDateOffset(formik.values.InvestmentDate)}
              onError={formik.errors.InvestmentDate}
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue(
                  "InvestmentDate",
                  GetJSDate(newValue),
                  true
                );
                formik.setFieldTouched("InvestmentDate", true, false);
              }}
              errorsExpression={formik.errors.InvestmentDate}
              touchedExpression={formik.touched.InvestmentDate}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="CommunityInvestmentTypeID"
              options={communityinvestmenttypes}
              value={communityinvestmenttypes.find((obj) => {
                return (
                  obj.CommunityInvestmentTypeID ===
                  formik.values.CommunityInvestmentTypeID
                );
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "CommunityInvestmentTypeID",
                    newValue.CommunityInvestmentTypeID
                  );
                } else {
                  formik.setFieldValue("CommunityInvestmentTypeID", -1);
                }
              }}
              label="Investment Type*"
              touchedFunction={formik.touched.CommunityInvestmentTypeID}
              errorFunction={formik.errors.CommunityInvestmentTypeID}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) =>
                option?.CommunityInvestmentTypeName
              }
              textValueFunction={
                !communityInvestmentTypeIsLoading &&
                formik.values.CommunityInvestmentTypeID > 0
                  ? communityInvestmentTypesById[
                      formik.values.CommunityInvestmentTypeID
                    ]?.CommunityInvestmentTypeName
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            <IppTextField
              id="InvestmentNotes"
              label="Comments"
              value={formik.values.InvestmentNotes}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.InvestmentNotes}
              errorsExpression={formik.errors.InvestmentNotes}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>

          <Grid item xs={12}>
            <IppFormButtons
              isEditing={isEditing}
              isAdding={isAdding}
              setIsEditing={setIsEditing}
              resetFunction={() => formik.resetForm()}
              showDelete={true}
              deleteFunction={() => handleDelete()}
            />
          </Grid>
        </Grid>
      </form>
    </Root>
  );

  let commInvForm =
    clientIsLoading ||
    indigenousGroupIsLoading ||
    companyIsLoading ||
    projectIsLoading ||
    communityInvestmentTypeIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>{editForm}</Paper>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xl={5} xs={customBP ? 12 : 5}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xl={7} xs={customBP ? 12 : 7}>
          <Box>
            <Paper>
              {!isAdding && (
                <Root>
                  <Box display="flex" justifyContent="center">
                    <Paper className={classes.boxSpace}>
                      <Grid container>
                        <IppAttachmentInventory
                          recordType="CommunityInvestments"
                          itemID={invData.CommunityInvestmentID}
                          companyID={formik.values.CompanyID}
                          projectID={projectID}
                          moduleID={1}
                          objectName="Community Investments"
                          categoryRecordType="CommunityInvestment"
                        />
                      </Grid>
                    </Paper>
                  </Box>
                </Root>
              )}
            </Paper>
          </Box>
        </Grid>
      </Grid>
    );

  return <Root>{commInvForm}</Root>;
};
