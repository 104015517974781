import { Grid } from "@mui/material";
import { AttachmentCategoryFormValues } from "api/attachmentCategoryAPI";
import { RootState } from "app/rootReducer";
import { IppMultiTextField } from "components/IppMultiTextField";
import { IppTextField } from "components/IppTextField";
import { FormikProps } from "formik";
import { useSelector } from "react-redux";
import { useTypedTranslation } from "utils/customHooks";
import { useRecordTypeLabel } from "utils/hooks/useRecordTypeLabel";

interface AttachmentCategoryFormFieldsProps {
  formik: FormikProps<AttachmentCategoryFormValues>;
}

export const AttachmentCategoryFormFields = (
  props: AttachmentCategoryFormFieldsProps
) => {
  const { formik } = props;
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const { getSortedLabels, getTypeByLabel } = useRecordTypeLabel();

  const { recordTypeList } = useSelector(
    (state: RootState) => state.recordTypes
  );

  return (
    <>
      <Grid item xs={12}>
        <IppTextField
          id="AttachmentCategoryName"
          label={t("objPlt:objects.attachmentcategories.fields.categoryname")}
          required={true}
          value={formik.values.AttachmentCategoryName}
          onChangeFunction={formik.handleChange}
          errorsExpression={formik.errors.AttachmentCategoryName}
          touchedExpression={formik.touched.AttachmentCategoryName}
          isEditing={true}
          setIsEditing={null}
        />
      </Grid>
      <Grid item xs={12}>
        <IppMultiTextField
          id="RecordTypes"
          label={t("objPlt:objects.attachmentcategories.fields.recordtypes")}
          options={
            getSortedLabels(recordTypeList)?.map((item) => item.label) ?? []
          }
          noOptionsText={t("strGen:components.nooptionstext")}
          value={
            getSortedLabels(formik.values.RecordTypes)?.map(
              (item) => item.label
            ) ?? []
          }
          setValue={(newValue: string | string[]) => {
            const setToArray = (value: string | string[]): string[] =>
              Array.isArray(value) ? value : value.split(",");

            const arrayValue = setToArray(newValue)
              .map(getTypeByLabel)
              .filter((val) => val !== null && val.trim() !== "");

            formik.setFieldValue("RecordTypes", arrayValue);
          }}
          touchedFunction={formik.touched.RecordTypes}
          errorFunction={formik.errors.RecordTypes}
          isEditing={true}
          setIsEditing={null}
          multiple={true}
          autoPopulate={false}
          freeSolo={false}
        />
      </Grid>
    </>
  );
};
