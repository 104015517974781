import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTypedTranslation } from "utils/customHooks";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";

const PREFIX = "FooterToolbar";

const classes = {
  menuIcon: `${PREFIX}-menuIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.menuIcon}`]: {
    paddingRight: theme.spacing(1),
  },
}));

function FooterToolbar() {
  const t = useTypedTranslation(["strGen"]);
  const { privacyPolicy } = useSelector((state: RootState) => state.client);

  const URL = privacyPolicy?.startsWith("http")
    ? privacyPolicy
    : `https://${
        privacyPolicy || "www.netbenefitsoftware.com/policies/privacy-policy"
      }`;

  return (
    <Root>
      <Typography variant="caption">{t("strGen:footer.legaltext")}</Typography>
      <Typography variant="caption" className={classes.menuIcon}>
        <a href={URL} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </Typography>
    </Root>
  );
}

export default FooterToolbar;
