import { useMemo } from "react";
import { useTypedTranslation } from "utils/customHooks";

export const useRecordTypeLabel = () => {
  const t = useTypedTranslation([
    "objPlt",
    "strGen",
    "objBen",
    "objCom",
    "objStk",
    "objSlr",
    "ObjSshe",
  ]);

  return useMemo(() => {
    const recordTypeMappings: Record<string, string> = {
      Asset: t("objBen:objects.asset.name"),
      Commitment: t("objCom:objects.commitment.name"),
      CommitmentAction: t("objCom:objects.commitmentaction.namefull"),
      CommitmentSource: t("objCom:objects.commitmentsource.name", {
        sourcecount: 1,
      }),
      CommunityInvestment: t("objBen:objects.communityinvestment.name"),
      Company: t("objBen:objects.company.name"),
      Contact: t("objPlt:objects.contact.name"),
      Contract: t("objBen:objects.contract.name"),
      CustomRnD: t("objBen:objects.customRnD.name"),
      EducationTraining: t("objBen:objects.educationtraining.name"),
      Employee: t("objBen:objects.employee.name"),
      EnTExpense: t("objBen:objects.entExpense.entexpenses"),
      Grievance: t("objStk:objects.grievance.name"),
      Initiative: t("objBen:objects.initiative.name"),
      Interaction: t("objStk:objects.interaction.name"),
      InteractionAction: t("objStk:objects.interactionaction.namefull"),
      InteractionComment: t("objStk:objects.interactioncomment.namefull"),
      Issue: t("objStk:objects.issue.name"),
      Payment: t("objBen:objects.payment.name"),
      PaymentPeriod: t("objBen:objects.spendreporting.name"),
      Phase: t("objPlt:objects.phase.name"),
      Project: t("objPlt:objects.project.name"),
      ProjectComponent: t("objPlt:objects.projectcomponent.fullname"),
      ProjectType: t("objPlt:objects.project.projecttype.name"),
      RnDExpense: t("objBen:objects.rndExpense.rndexpensefull"),
      SSHE: t("objSshe:ssheReports"),
      StakeholderGroup: t("objPlt:objects.group.name"),
      Supplier: t("objSlr:name"),
      Tag: t("objPlt:objects.tag.name_other"),
      Thread: t("objStk:objects.thread.name_other"),
      Tier: t("objBen:objects.tiers.name_other"),
      WorkHourDetail: t("objBen:objects.workhour.detail.fullname_other"),
      WorkHourPeriod: t("objBen:objects.workhour.period.fullname_other"),
    };

    // Function to get label for a given record type
    const getLabel = (recordType: string): string => {
      return recordTypeMappings[recordType] || recordType; // Default to original type if not found
    };

    // Function to get sorted labels from a list of record types
    const getSortedLabels = (
      recordTypes: string[]
    ): { value: string; label: string }[] => {
      return (
        recordTypes
          ?.map((type) => ({
            value: type,
            label: getLabel(type),
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) ?? []
      );
    };

    // Function to find the record type by label
    const getTypeByLabel = (label: string): string | null => {
      const entries = Object.entries(recordTypeMappings);
      for (const [type, mappedLabel] of entries) {
        if (mappedLabel === label) {
          return type;
        }
      }
      return null; // Return null if not found
    };

    return { getLabel, getSortedLabels, getTypeByLabel };
  }, [t]);
};
