import { useEffect, useState } from "react";
import {
  delAttachmentCategory,
  fetchAttachmentCategories,
} from "./AttachmentCategorySlice";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useTypedTranslation } from "utils/customHooks";
import { RootState } from "app/rootReducer";
import LoadingIndicator from "components/LoadingIndicator";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import * as Constants from "utils/snackBarConstants";
import IppConfirmDialog from "components/IppConfirmDialog";
import { AttachmentCategoryForm } from "./AttachmentCategoryForm";
import { AttachmentCategoryExtended } from "api/attachmentCategoryAPI";
import { fetchRecordTypes } from "features/datalists/RecordTypesSlice";
import { useRecordTypeLabel } from "utils/hooks/useRecordTypeLabel";

export const AttachmentCategoryInventory = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const { getSortedLabels } = useRecordTypeLabel();
  const [editID, setEditID] = useState<number | undefined>(undefined);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteID, setDeleteID] = useState<number | undefined>(undefined);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const {
    attachmentCategoriesById,
    attachmentCategoryList,
    isLoading: attachmentCategoryIsLoading,
    error: attachmentCategoryError,
  } = useSelector((state: RootState) => state.attachmentCategory);

  const attachmentCategories = attachmentCategoryList.map(
    (id) => attachmentCategoriesById[id] as AttachmentCategoryExtended
  );

  const mappedAttachmentCategories = attachmentCategories.map((category) => {
    return {
      ...category,
      RecordTypes: getSortedLabels(category.RecordTypes).map(
        (recordType) => recordType.label
      ),
    };
  });

  const { isLoading: recordTypeIsLoading } = useSelector(
    (state: RootState) => state.recordTypes
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchAttachmentCategories(accessToken));
        dispatch(fetchRecordTypes(accessToken, "attachmentCategory"));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const handleDelete = (attachmentCategoryID: number | undefined) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (attachmentCategoryID) {
          dispatch(delAttachmentCategory(accessToken, attachmentCategoryID));
          setDeleteOpen(false);
        } else {
          dispatch(openSnackBar(Constants.FAILED, "error"));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const dataColumns = [
    {
      field: "AttachmentCategoryName",
      title: t("objPlt:objects.attachmentcategories.fields.categoryname"),
    },
    {
      field: "RecordTypes",
      title: t("objPlt:objects.attachmentcategories.fields.recordtypes"),
      commaDelimitedChips: true,
      isRecordTypeChips: true,
    },
  ];

  if (attachmentCategoryError) {
    return <ErrorPage errMsg={attachmentCategoryError} />;
  }

  const attachmentCategoryInvView =
    attachmentCategoryIsLoading || recordTypeIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title={t("objPlt:objects.attachmentcategories.name", { count: 2 })}
          tableData={mappedAttachmentCategories}
          idField="AttachmentCategoryID"
          columns={dataColumns}
          optionsButtons={{
            showEdit: true,
            setEditID,
            setEditOpen,
            showDelete: true,
            setDeleteID,
            setDeleteOpen,
          }}
          isFiltered={false}
          showDetail={false}
          buttonProps={{
            showAdd: true,
            addBtnOnClick: () => setEditOpen(true),
          }}
          hideExport
        />

        {editOpen && (
          <AttachmentCategoryForm
            setEditOpen={setEditOpen}
            setEditID={setEditID}
            attachmentCategoryData={attachmentCategories.find(
              (category) => category.AttachmentCategoryID === editID
            )}
          />
        )}

        <IppConfirmDialog
          title={t("strGen:prompts.deletechecks.titles.attachmentcategory", {
            title:
              attachmentCategories.find(
                (category) => category.AttachmentCategoryID === deleteID
              )?.AttachmentCategoryName || "",
          })}
          open={deleteOpen}
          setOpen={() => setDeleteOpen(!deleteOpen)}
          onConfirm={() => handleDelete(deleteID)}
          recordID={deleteID}
          deleteText={t(
            "strGen:components.ippconfirmdialog.attachmentcategorywarning"
          )}
          deleteWarning={true}
        />
      </div>
    );

  return (
    <div id="attachment-categories-inventory-page">
      {attachmentCategoryInvView}
    </div>
  );
};
