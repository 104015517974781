import { AttachmentCategoryExtended } from "api/attachmentCategoryAPI";
import { RootState } from "app/rootReducer";
import { useSelector } from "react-redux";
import { useTypedTranslation } from "utils/customHooks";
import { formatStringForCompare } from "utils/functions";
import * as yup from "yup";

interface SchemaOptions {
  attachmentCategoryID: number | undefined;
}

export const AttachmentCategoryValidationSchema = (options: SchemaOptions) => {
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const { attachmentCategoriesById, attachmentCategoryList } = useSelector(
    (state: RootState) => state.attachmentCategory
  );

  const attachmentCategories = attachmentCategoryList.map(
    (id) => attachmentCategoriesById[id] as AttachmentCategoryExtended
  );

  const existingAttachmentCategoryNames = attachmentCategories
    .filter(
      (attachmentCategory) =>
        attachmentCategory.AttachmentCategoryID !== options.attachmentCategoryID
    )
    .map((attachmentCategory) =>
      formatStringForCompare(attachmentCategory.AttachmentCategoryName)
    );

  return yup.object({
    AttachmentCategoryName: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t(
            "objPlt:objects.attachmentcategories.fields.categoryname"
          ),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t(
            "objPlt:objects.attachmentcategories.fields.categoryname"
          ),
          max: 50,
        })
      )
      .test(
        "unique",
        t("strGen:validation.unique", {
          fieldname: t(
            "objPlt:objects.attachmentcategories.fields.categoryname"
          ),
        }),
        function (value) {
          const isDuplicate = existingAttachmentCategoryNames.find(
            (name) => name === formatStringForCompare(value)
          );
          if (isDuplicate) return false;

          return true;
        }
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t(
            "objPlt:objects.attachmentcategories.fields.categoryname"
          ),
        })
      ),
    RecordTypes: yup
      .array()
      .of(yup.string())
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t(
            "objPlt:objects.attachmentcategories.fields.recordtypes"
          ),
        })
      ),
  });
};
