import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addCommitmentSource,
  updCommitmentSource,
  delCommitmentSource,
} from "./CommitmentSourceSlice";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Box, Dialog, DialogContent, Grid, Paper } from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import LoadingIndicator from "components/LoadingIndicator";
import { Prompt } from "react-router";
import {
  ConvertDateOffset,
  ConvertToJSDate,
  GetJSDate,
} from "../../../utils/DateFunctions";
import { IppDisplayField } from "components/IppDisplayField";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import { IppDisplayRichText } from "components/IppDisplayRichText";
import { fetchCommitmentsBySource } from "../commitment/CommitmentSlice";
import { fetchClientCommitmentSourceTypes } from "features/platform/admin/clientCommitmentSourceTypes/ClientCommitmentSourceTypeSlice";
import { IppChildInventory } from "components/Inventory/IppChildInventory";
import { fetchDeleteChecksByCommitmentSource } from "features/deleteCheck/DeleteCheckSlice";
import { IppDatePicker } from "components/IppDatePicker";
import { countAttachments } from "api/attachmentsAPI";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IsMedSmall } from "utils/mediaQueries";
import { useTypedTranslation } from "utils/customHooks";
import { UserWriteAccess } from "utils/userAccess";
import { IppAmendment } from "components/IppAmendment";
import { fetchCommitmentSourceAmendments } from "../commitmentSourceAmendment/CommitmentSourceAmendmentSlice";
import { usePermissions } from "utils/permissions/usePermissions";
import { CommitmentsSourceOrAmendmentResource } from "utils/types/index.types";

const PREFIX = "CommitmentSourceForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const CommitmentSourceForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { isActionAllowed } = usePermissions();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const customBP = IsMedSmall();

  const [activeTab, setActiveTab] = useState(0);
  const [didSaveInventory, setDidSaveInventory] = useState(false);
  const [listComms, setListComms] = useState<Array<string>>([]);
  // used to display attachments count
  const [attachCount, setAttachCount] = useState<any>({ Count: 0 });

  const handleTabSelect = (e: TabStripSelectEventArguments) => {
    setActiveTab(e.selected);
  };

  let itemData = props.commitmentSource || {};

  //------------------Define Permissions------------------
  const editPermissionsResource: CommitmentsSourceOrAmendmentResource = {
    module: "Commitments",
    type: "Source",
    action: "edit",
  };

  const canEdit = useMemo(
    () => isActionAllowed(editPermissionsResource),
    [editPermissionsResource]
  );

  const deletePermissionsResource: CommitmentsSourceOrAmendmentResource = {
    module: "Commitments",
    type: "Source",
    action: "delete",
  };

  const canDelete = useMemo(
    () => isActionAllowed(deletePermissionsResource),
    [deletePermissionsResource]
  );
  //------------------------------------------------------

  const [isEditing, setIsEditing] = useState(
    itemData.CommitmentSourceID ? false : true
  );
  const [isAdding, setIsAdding] = useState(
    itemData.CommitmentSourceID ? false : true
  );

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { clientCommitmentSourceTypesById, clientCommitmentSourceTypeList } =
    useSelector((state: RootState) => state.clientCommitmentSourceTypes);

  const clientCommitmentSourceTypes = clientCommitmentSourceTypeList.map(
    (id) => clientCommitmentSourceTypesById[id]
  );

  const { isLoading: commitmentStatusIsLoading } = useSelector(
    (state: RootState) => state.commitmentStatusTypes
  );

  const {
    commitmentList,
    commitmentsById,
    isLoading: commitmentIsLoading,
  } = useSelector((state: RootState) => state.commitments);

  const commitments = commitmentList.map((id) => commitmentsById[id]);

  const { commitmentSourceAmendmentList, commitmentSourceAmendmentsById } =
    useSelector((state: RootState) => state.commitmentSourceAmendments);

  const amendments = commitmentSourceAmendmentList.map(
    (id) => commitmentSourceAmendmentsById[id]
  );

  const { isLoading: actionTypeIsLoading } = useSelector(
    (state: RootState) => state.actionTypes
  );

  //usestate for Rootstate of attachment
  const { attachmentsById, attachmentList } = useSelector(
    (state: RootState) => state.attachments
  );

  const attachments = attachmentList.map((id) => attachmentsById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchClientCommitmentSourceTypes(accessToken));
        if (itemData.CommitmentSourceID) {
          const count = await countAttachments(
            accessToken,
            "CommitmentSources",
            itemData.CommitmentSourceID
          );
          setAttachCount(count.attachments ? count.attachments : 0);
          dispatch(
            fetchCommitmentsBySource(accessToken, itemData.CommitmentSourceID)
          );
          dispatch(
            fetchCommitmentSourceAmendments(
              accessToken,
              itemData.CommitmentSourceID
            )
          );
        } else {
          setAttachCount(0);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, itemData.CommitmentSourceID, dispatch, getAccessTokenSilently]);

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (attachments.length > 0) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === itemData.CommitmentSourceID &&
              item.RecordType === "CommitmentSources"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  // get lists for commitments  autocomplete fields
  useEffect(() => {
    // effect
    if (!commitmentIsLoading) {
      let newList: string[] = [];
      commitments.map((item) => newList.push(item.CommitmentTitle));
      setListComms(newList);
    }
    return () => {
      // cleanup
    };
  }, [itemData.CommitmentSourceID, commitmentIsLoading, isEditing]);

  // save when changes made to child records
  useEffect(() => {
    if (didSaveInventory) {
      submitFunc(itemData);
      setDidSaveInventory(false);
    }
    return () => {
      // cleanup
    };
  }, [didSaveInventory]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(addCommitmentSource(accessToken, values, true));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (itemData.CommitmentSourceID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          dispatch(
            updCommitmentSource(
              accessToken,
              values.CommitmentSourceID,
              values,
              true
            )
          );
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    itemData.CommitmentSourceName = "";
    itemData.CommitmentSourceTypeID = -1;
    itemData.DocumentDate = null;
    itemData.ExpiryDate = null;
    itemData.SourceDescription = "";
  }

  const handleDelete = () => {
    // function to delete current community investment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delCommitmentSource(accessToken, itemData.CommitmentSourceID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const validationSchema = yup.object({
    CommitmentSourceName: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitmentsource.fields.sourcename"),
        })
      )
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objCom:objects.commitmentsource.fields.sourcename"),
          count: 250,
        })
      ),
    CommitmentSourceTypeID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitmentsource.fields.sourcetype"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitmentsource.fields.sourcetype"),
        })
      ),
    DocumentDate: yup
      .date()
      .nullable()
      .typeError(t("strGen:validation.date.improperformat")),
    ExpiryDate: yup
      .date()
      .nullable()
      .typeError(t("strGen:validation.date.improperformat")),
  });

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: validationSchema,
    onSubmit: submitFunc,
  });

  const data = commitments.map((p) => {
    let newItem = { ...p } as any;
    newItem.RecordedDate
      ? (newItem.RecordedDate = ConvertDateOffset(p.RecordedDate))
      : (newItem.RecordedDate = null);
    return newItem;
  });

  useEffect(() => {
    if (data.length > 0 && !commitmentIsLoading) {
      if (!data.every((comm) => comm.hasOwnProperty("PersonResponsibleName"))) {
        (async () => {
          try {
            const accessToken = await getAccessTokenSilently({
              authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
              },
            });
            dispatch(
              fetchCommitmentsBySource(accessToken, itemData.CommitmentSourceID)
            );
          } catch (e) {
            console.error(e);
          }
        })();
      }
    }
  }, [
    data,
    itemData.CommitmentSourceID,
    commitmentIsLoading,
    getAccessTokenSilently,
    dispatch,
  ]);

  const dataColumns = [
    {
      field: "SequenceID",
      title: t("objCom:objects.commitment.sequenceid"),
      format: "CMT-{0:0}",
      filter: "text",
      columnWidth: 80,
    },
    {
      field: "CommitmentTitle",
      title: t("objCom:objects.commitment.name"),
      filter: "text",
    },
    {
      field: "CommitmentReference",
      title: t("objCom:objects.commitment.fields.reference"),
      filter: "dropdown",
    },
    {
      field: "CommitmentStatusTypeName",
      title: t("objCom:objects.commitmentaction.fields.status"),
      columnWidth: 150,
      chips: true,
      filter: "dropdown",
    },
    {
      field: "RecordedDate",
      title: t("objPlt:platformwide.fields.date"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 110,
    },
  ];

  let viewForm = (
    <Box display="flex" justifyContent="center">
      <Grid container className={classes.editForm} spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.boxSpace}>
            <Grid container spacing={1}>
              <IppFormHeader
                title={t("objCom:objects.commitmentsource.name", {
                  sourcecount: 1,
                })}
                isEditing={isEditing}
                isAdding={isAdding}
              />
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objCom:objects.commitmentsource.fields.sourcename")}
                  value={formik.values.CommitmentSourceName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objCom:objects.commitmentsource.fields.sourcetype")}
                  value={formik.values.CommitmentSourceTypeName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objPlt:platformwide.fields.dateofdocument")}
                  value={ConvertToJSDate(formik.values.DocumentDate)}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objPlt:platformwide.fields.expirydate")}
                  value={ConvertToJSDate(formik.values.ExpiryDate)}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <IppDisplayRichText
                  label={t(
                    "objCom:objects.commitmentsource.fields.description"
                  )}
                  value={formik.values.SourceDescription}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={isEditing}
                  isAdding={isAdding}
                  setIsEditing={setIsEditing}
                  resetFunction={() => formik.resetForm()}
                  showDelete={canDelete}
                  deleteFunction={() => handleDelete()}
                  fetchFunc={fetchDeleteChecksByCommitmentSource}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  let editForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <form noValidate onSubmit={formik.handleSubmit}>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message={t("strGen:prompts.unsavedchanges")}
        />
      )}

      <Grid container spacing={1}>
        <IppFormHeader
          title={t("objCom:objects.commitmentsource.name", { sourcecount: 1 })}
          isEditing={isEditing}
          isAdding={isAdding}
        />
        <Grid item xs={6}>
          <IppTextField
            id="CommitmentSourceName"
            label={t("objCom:objects.commitmentsource.fields.sourcename")}
            required={true}
            value={formik.values.CommitmentSourceName}
            onChangeFunction={formik.handleChange}
            errorsExpression={formik.errors.CommitmentSourceName}
            touchedExpression={formik.touched.CommitmentSourceName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={6}>
          <IppAutocomplete
            id="CommitmentSourceTypeID"
            options={clientCommitmentSourceTypes}
            value={clientCommitmentSourceTypes.find((obj) => {
              return (
                obj.CommitmentSourceTypeID ===
                formik.values.CommitmentSourceTypeID
              );
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue(
                  "CommitmentSourceTypeID",
                  newValue.CommitmentSourceTypeID
                );
              } else {
                formik.setFieldValue("CommitmentSourceTypeID", -1);
              }
            }}
            label={t("objCom:objects.commitmentsource.fields.sourcetype")}
            required={true}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) =>
              option.CommitmentSourceTypeName
            }
            errorFunction={formik.errors.CommitmentSourceTypeID}
            touchedFunction={formik.touched.CommitmentSourceTypeID}
            textValueFunction={formik.values.CommitmentSourceTypeName}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDatePicker
            id="DocumentDate"
            label={t("objPlt:platformwide.fields.dateofdocument")}
            value={ConvertDateOffset(formik.values.DocumentDate)}
            onChangeFunction={(newValue: any) => {
              formik.setFieldValue("DocumentDate", GetJSDate(newValue), true);
              formik.setFieldTouched("DocumentDate", true, false);
            }}
            errorsExpression={formik.errors.DocumentDate}
            touchedExpression={formik.touched.DocumentDate}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDatePicker
            id="ExpiryDate"
            label={t("objPlt:platformwide.fields.expirydateifapplicable")}
            value={ConvertDateOffset(formik.values.ExpiryDate)}
            onChangeFunction={(newValue: any) => {
              formik.setFieldValue("ExpiryDate", GetJSDate(newValue), true);
              formik.setFieldTouched("ExpiryDate", true, false);
            }}
            errorsExpression={formik.errors.ExpiryDate}
            touchedExpression={formik.touched.ExpiryDate}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={12}>
          <IppTextField
            id="SourceDescription"
            label={t(
              "objCom:objects.commitmentsource.fields.sourcedescription"
            )}
            value={formik.values.SourceDescription}
            onChangeFunction={formik.handleChange}
            errorsExpression={formik.errors.SourceDescription}
            touchedExpression={formik.touched.SourceDescription}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            multiLine={true}
          />
        </Grid>

        <Grid item xs={12}>
          <IppFormButtons
            isEditing={isEditing}
            isAdding={isAdding}
            setIsEditing={setIsEditing}
            resetFunction={() => formik.resetForm()}
            showDelete={canDelete}
            deleteFunction={() => handleDelete()}
            fetchFunc={fetchDeleteChecksByCommitmentSource}
          />
        </Grid>
      </Grid>
    </form>
  );

  let pageForm =
    clientIsLoading &&
    actionTypeIsLoading &&
    commitmentIsLoading &&
    commitmentStatusIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>{editForm} </Paper>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xl={4} xs={customBP ? 12 : 5}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="md">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xl={8} xs={customBP ? 12 : 7}>
          <Box>
            <Paper>
              <TabStrip selected={activeTab} onSelect={handleTabSelect}>
                <TabStripTab
                  title={`${t("objCom:objects.commitment.name", {
                    count: 2,
                  })} (${commitments.length})`}
                >
                  <Box>
                    <IppChildInventory
                      title={t("objCom:objects.commitment.name")}
                      parentTitle={t("objCom:objects.commitmentsource.source")}
                      linkURL="commitments"
                      tableData={data}
                      idField="CommitmentID"
                      nameField="CommitmentTitle"
                      parentID={itemData.CommitmentSourceID}
                      relatedField="CommitmentID"
                      columns={dataColumns}
                      showAdd={true}
                      tableName="Commitment"
                      id="CommitmentList"
                      label="Communications"
                      options={commitments}
                      selectedValues={listComms}
                      setSelectedValues={setListComms}
                      setDidSaveInventory={setDidSaveInventory}
                      parentValue={itemData.CommitmentSourceName}
                      showOptions={true}
                      secondLinkCell={1}
                      showSearchBar={true}
                    />
                  </Box>
                </TabStripTab>
                <TabStripTab title={"Amendments (" + amendments.length + ")"}>
                  <Box>
                    <Grid className={classes.editForm}>
                      <IppAmendment
                        title="Commitment Source Amendments"
                        itemID={itemData.CommitmentSourceID}
                        commitmentSourceID={formik.values.CommitmentSourceID}
                      />
                    </Grid>
                  </Box>
                </TabStripTab>
                <TabStripTab
                  title={`${t(
                    "objCom:objects.commitmentsource.attachments"
                  )} (${attachCount.Count})`}
                >
                  <Box>
                    <Grid className={classes.editForm}>
                      <IppAttachmentInventory
                        recordType="CommitmentSources"
                        itemID={itemData.CommitmentSourceID}
                        companyID={formik.values.CompanyID}
                        projectID={0}
                        moduleID={3}
                        canEdit={canEdit}
                        title={t("objCom:objects.commitmentsource.attachments")}
                        categoryRecordType="CommitmentSource"
                      />
                    </Grid>
                  </Box>
                </TabStripTab>
              </TabStrip>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    );

  return <Root>{pageForm}</Root>;
};
