import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { addCompany, delCompany, updCompany } from "./CompaniesSlice";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import { IppFormDivider } from "components/IppFormDivider";
import { IppCheckbox } from "components/IppCheckbox";
import { IppAutocomplete } from "components/IppAutocomplete";
import { fetchCategories } from "features/datalists/CategorySlice";
import { fetchIndigenousGroupsByClient } from "features/datalists/IndigenousGroupSlice";
import { People, Star, StarBorder } from "@mui/icons-material";
import {
  Grid as KendoGrid,
  GridColumn,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { fetchUsers, updUser } from "features/users/UsersSlice";
import { push } from "redux-first-history";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchCompanyStructures } from "features/datalists/CompanyStructureSlice";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import { fetchDeleteChecksByCompany } from "features/deleteCheck/DeleteCheckSlice";
import { Prompt } from "react-router";
import { IppDisplayField } from "components/IppDisplayField";
import { CompanyCheckDuplicates } from "./CompanyCheckDuplicatePage";
import { countAttachments } from "api/attachmentsAPI";
import { IppTabPanel } from "components/IppTabPanel";
import { IsExtraLargeScreen, IsMedSmall } from "utils/mediaQueries";
import { IppDiversityCheckboxes } from "components/IppDiversityCheckboxes";
import { IppDiversityDisplay } from "components/IppDiversityDisplay";
import { UserWriteAccess } from "utils/userAccess";
import { IppChildInventory } from "components/Inventory/IppChildInventory";
import { fetchContractsByCompany } from "features/contract/ContractSlice";
import { fetchClientCompanyStructures } from "features/platform/admin/clientCompanyStructures/ClientCompanyStructureSlice";
import { ConvertDateOffset } from "utils/DateFunctions";
import { fetchWorkHourPeriodsByCompany } from "../workHours/WorkHourPeriodSlice";
import { IppLocationDisplay } from "components/IppLocationDisplay";
import { IppLocationAutoComplete } from "components/IppLocationAutoComplete";
import { IppCompanyProjectInventory } from "components/Inventory/IppCompanyProjectInventory";
import { CompanyValidationSchema } from "./CompanyValidation";
import { fetchAllTiers } from "../tier/TierSlice";
import { fetchPaymentPeriodsByCompany } from "../paymentPeriods/PaymentPeriodSlice";
import { CompanyRejectDialog } from "./CompanyRejectDialog";
import { useTypedTranslation } from "utils/customHooks";
import { fetchRegionsByRecordType } from "../regions/RegionSlice";
import { IppDisplayCheckbox } from "components/IppDisplayCheckbox";
import { fetchClientActivityLocations } from "features/platform/admin/clientActivityLocations/clientActivityLocationSlice";
import {
  IppNeedsReviewBanner,
  RenderDialogContentProps,
} from "components/IppNeedsReviewBanner";
import { IppButton } from "components/Buttons/IppButton";
import { IppMergeButton } from "components/Buttons/IppMergeButton";
import { fetchCommunityInvestmentsByCompany } from "../communityInvestments/CommunityInvestmentSlice";
import {
  fetchClientPayments,
  fetchIndirectPaymentsByCompany,
  fetchPaymentsByPeriod,
} from "../payments/PaymentSlice";
import { Payment } from "api/paymentAPI";
import { RecordHistoryDisplay } from "components/Reusable Utils/RecordHistoryDisplay";

const PREFIX = "CompanyForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1200,
  },
  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const CompanyForm = (company: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);
  const customBP = IsMedSmall();

  const [attachCount, setAttachCount] = useState({ Count: 0 } as any);
  const [hasDiversity, setHasDiversity] = useState(false);

  const [pageLoading, setPageloading] = useState(true);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const [activeTab, setActiveTab] = useState(0);

  let compData = company.company;
  const [isEditing, _setIsEditing] = useState(
    compData.CompanyID ? false : true
  );
  const setIsEditing = (edits: Boolean) => {
    _setIsEditing(edits && currentProfile.IsClient); // prevents non client users from doing any edits on this page
  };
  const [isAdding, setIsAdding] = useState(compData.CompanyID ? false : true);
  const [companyID, setCompanyID] = useState(compData ? compData.CompanyID : 0);
  const [showDuplicateCheck, setShowDuplicateCheck] = useState(false);
  const showEdit = UserWriteAccess("Benefits", compData.ProjectID);
  const [showRejectInfo, setShowRejectInfo] = useState(false);

  const {
    clientId,
    dm_WomanOwned,
    dm_VeteranOwned,
    dm_NorthernResident,
    dm_Disability,
    dm_VisibleMinority,
    dm_LGBTQ,
    ft_ben_Contract,
    ft_ben_Tier,
    ft_ben_ActivityLocation,
    ft_ben_BenefitsMenu,
    ft_ben_Region,
    ft_ben_BusinessCategory,
    ft_ben_SpendReport,
    ft_ben_WorkForce,
    ft_ben_Spend,
    ft_ben_CommunityInvestment,
  } = useSelector((state: RootState) => state.client);

  const { tierList, tiersById, tierIsLoading } = useSelector(
    (state: RootState) => state.tier
  );

  const tiers = tierList.map((id) => tiersById[id]);

  const { regionList, regionsById, regionIsLoading } = useSelector(
    (state: RootState) => state.region
  );

  const regions = ft_ben_Region ? regionList.map((id) => regionsById[id]) : [];
  const hasRegionTypes = !!(ft_ben_Region && regions.length > 0);

  const { clientActivityLocationList, clientActivityLocationsById } =
    useSelector((state: RootState) => state.clientActivityLocations);

  const activityLocations =
    ft_ben_ActivityLocation && currentProfile.IsClient
      ? clientActivityLocationList.map((id) => clientActivityLocationsById[id])
      : [];

  useEffect(() => {
    if (
      dm_WomanOwned ||
      dm_VeteranOwned ||
      dm_NorthernResident ||
      dm_Disability ||
      dm_VisibleMinority ||
      dm_LGBTQ
    ) {
      setHasDiversity(true);
    }
  }, []);

  const {
    companiesById,
    companyList,
    isLoading: companyIsLoading,
  } = useSelector((state: RootState) => state.companies);

  const companies = companyList.map((companyID) => companiesById[companyID]);

  const {
    categoriesById,
    categoryList,
    isLoading: categoryIsLoading,
  } = useSelector((state: RootState) => state.categories);

  const {
    indigenousGroupList,
    indigenousGroupsById,
    isLoading: indigenousGroupIsLoading,
  } = useSelector((state: RootState) => state.indigenousGroups);

  const categories = categoryList.map(
    (CategoryID) => categoriesById[CategoryID]
  );

  const visibleCategories = categories.filter((category) => !category.IsHidden);

  const indigenousGroups = indigenousGroupList.map(
    (IndigenousGroupID) => indigenousGroupsById[IndigenousGroupID]
  );

  const {
    userList,
    usersById,
    isLoading: userisLoading,
  } = useSelector((state: RootState) => state.users);

  // Filter for active users only
  const allUsers = userList.map((id) => usersById[id]);
  let users = allUsers.filter((item) => item.IsActive === true);

  const {
    companyStructureList,
    companyStructuresById,
    isLoading: structureIsLoading,
  } = useSelector((state: RootState) => state.companyStructures);

  const companyStructures = companyStructureList.map(
    (id) => companyStructuresById[id]
  );

  const {
    clientCompanyStructuresById,
    clientCompanyStructureList,
    isLoading: isLoadingClientCompanyStructures,
  } = useSelector((state: RootState) => state.clientCompanyStructures);
  const clientCompanyStructures = clientCompanyStructureList.map(
    (id) => clientCompanyStructuresById[id]
  );

  //usestate for Rootstate of attachment
  const { attachmentsById, attachmentList } = useSelector(
    (state: RootState) => state.attachments
  );

  const attachments = attachmentList.map((id) => attachmentsById[id]);

  const { contractList, contractsById } = useSelector(
    (state: RootState) => state.contracts
  );

  const contracts = contractList.map((id) => contractsById[id]);

  const cnList = contracts.map((p) => {
    let n = Object.assign({}, p);
    n.AwardDate = ConvertDateOffset(p.AwardDate);
    return n;
  });

  const { workHourPeriodList, workHourPeriodsById } = useSelector(
    (state: RootState) => state.workHourPeriods
  );

  const workHourPeriods = workHourPeriodList.map(
    (id) => workHourPeriodsById[id]
  );

  const whpList = workHourPeriods.map((p) => {
    let n = Object.assign({}, p) as any;
    n.StartDate = ConvertDateOffset(p.StartDate);
    n.EndDate = ConvertDateOffset(p.EndDate);
    n.TotalHours = p.RegHours + p.AltHours2 + p.AltHours3;
    return n;
  });

  const { paymentList, paymentsById } = useSelector(
    (state: RootState) => state.payments
  );

  const payments = paymentList.map((id) => paymentsById[id]);

  const pmts = payments.map((p) => {
    let newPayment = Object.assign({}, p) as any;

    newPayment.Type = p.PaymentPeriodID === null ? "Spend" : "Spend Report";

    newPayment.PaymentDate = ConvertDateOffset(p.PaymentDate);

    return newPayment;
  });

  const { paymentPeriodList, paymentPeriodsById } = useSelector(
    (state: RootState) => state.paymentPeriods
  );

  const rawPaymentPeriods = paymentPeriodList.map(
    (id) => paymentPeriodsById[id]
  );

  const filteredPaymentPeriods = rawPaymentPeriods.filter(
    (paymentPeriod) => paymentPeriod.CompanyID === compData.CompanyID
  );

  const paymentPeriods = filteredPaymentPeriods.map((p) => {
    let newPaymentPeriod = Object.assign({}, p) as any;
    newPaymentPeriod.PaymentPeriodDate = ConvertDateOffset(p.EndDate);
    let date = new Date(p.EndDate);
    // Format the PaymentPeriodDate into "YYYY-MMM" (e.g., 2023-DEC)
    const year = date.getFullYear();
    const month = date
      .toLocaleString("default", {
        month: "short",
      })
      .toUpperCase();

    newPaymentPeriod.Year = year;
    newPaymentPeriod.Month = month;

    return newPaymentPeriod;
  });

  const {
    communityInvestmentsById,
    communityInvestmentList,
    isLoading: communityInvestmentIsLoading,
  } = useSelector((state: RootState) => state.communityInvestments);

  const communityInvestments = communityInvestmentList.map(
    (communityInvestmentID) => communityInvestmentsById[communityInvestmentID]
  );

  const communityInvestmentData = communityInvestments.map((p) => {
    let n = Object.assign({}, p) as any;
    n.InvestmentDate = ConvertDateOffset(p.InvestmentDate);

    n.GroupName =
      !indigenousGroupIsLoading && indigenousGroupsById[n.IndigenousGroupID]
        ? indigenousGroupsById[n.IndigenousGroupID].IndigenousGroupName
        : "";

    return n;
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCategories(accessToken));
        dispatch(fetchIndigenousGroupsByClient(accessToken));
        dispatch(fetchCompanyStructures(accessToken));
        dispatch(fetchClientCompanyStructures(accessToken));
        if (ft_ben_Tier) {
          dispatch(fetchAllTiers(accessToken));
        }
        if (ft_ben_ActivityLocation && currentProfile.IsClient) {
          dispatch(fetchClientActivityLocations(accessToken));
        }
        if (ft_ben_Region) {
          dispatch(fetchRegionsByRecordType(accessToken, "Company"));
        }
        if (companyID) {
          if (ft_ben_Spend || ft_ben_SpendReport) {
            dispatch(fetchIndirectPaymentsByCompany(accessToken, companyID));
          }
          if (ft_ben_Contract) {
            dispatch(fetchContractsByCompany(accessToken, companyID));
          }
          if (ft_ben_CommunityInvestment) {
            dispatch(
              fetchCommunityInvestmentsByCompany(accessToken, companyID)
            );
          }
          dispatch(fetchUsers(accessToken, clientId, companyID));
          dispatch(fetchDeleteChecksByCompany(accessToken, companyID));
          if (ft_ben_WorkForce) {
            dispatch(fetchWorkHourPeriodsByCompany(accessToken, companyID));
          }
          dispatch(fetchPaymentPeriodsByCompany(accessToken, companyID));

          const count = await countAttachments(
            accessToken,
            "Company",
            companyID
          );
          setAttachCount(count.attachments ? count.attachments : 0);
        }

        setPageloading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, companyID, dispatch, getAccessTokenSilently]);

  const handleManageUsers = (event: React.MouseEvent<unknown>) => {
    dispatch(push(`/benefits/companies/${companyID}/users`));
  };

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (attachments.length > 0) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === compData.CompanyID &&
              item.RecordType === "Company"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  const onSub = (values: any) => {
    (async () => {
      // Check if VendorID is empty and show the warning if needed
      if (!values.VendorID.trim()) {
        // Display the warning message
        const shouldProceed = window.confirm(
          "You have not provided a value for VendorID. If this Company exists in an external vendor management tool, we recommend you include this value. Do you want to proceed without a VendorID?"
        );

        if (!shouldProceed) {
          return; // Cancel form submission
        }
      }
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        dispatch(addCompany(accessToken, values.ClientID, values));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleDelete = () => {
    // function to delete current company entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delCompany(accessToken, companyID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleTabSelect = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const handlePrimaryUser = (userData: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        const requiredUserData = {
          FirstName: userData.FirstName,
          Surname: userData.Surname,
          EmailAddress: userData.EmailAddress,
          CompanyID: userData.CompanyID,
          IsPrimary: !userData.IsPrimary,
        };
        dispatch(
          updUser(
            accessToken,
            clientId,
            userData.UserAccountID,
            requiredUserData
          )
        );

        // Filter users to find primary user, excluding the current user
        const foundPrimaryUser = users.filter(
          (item: any) =>
            item.UserAccountID !== userData.UserAccountID &&
            item.IsPrimary === true
        );
        // If foundPrimaryUser has results, update each user's properties to set IsPrimary to false
        if (foundPrimaryUser && foundPrimaryUser.length > 0) {
          foundPrimaryUser.forEach((item: any) => {
            const requiredPrimaryUserData = {
              FirstName: item.FirstName,
              Surname: item.Surname,
              EmailAddress: item.EmailAddress,
              CompanyID: item.CompanyID,
              IsPrimary: false,
            };
            dispatch(
              updUser(
                accessToken,
                clientId,
                item.UserAccountID,
                requiredPrimaryUserData
              )
            );
          });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const PrimaryIconCell = (props: any) => {
    const isPrimary = props.dataItem.IsPrimary;
    const clientToolTipText = isPrimary
      ? "Unset Primary User"
      : "Set Primary User";
    const toolTipText =
      "Primary Users can only be set or unset by Client users";

    return (
      <td>
        <Tooltip
          title={currentProfile.IsClient ? clientToolTipText : toolTipText}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              currentProfile.IsClient && handlePrimaryUser(props.dataItem);
            }}
          >
            <IconButton size="small">
              {props.dataItem.IsPrimary ? (
                <Star color={"primary"} />
              ) : (
                <StarBorder />
              )}
            </IconButton>
          </div>
        </Tooltip>
      </td>
    );
  };

  let submitFunc = onSub;

  if (compData.CompanyID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          dispatch(
            updCompany(accessToken, values.ClientID, values.CompanyID, values)
          );
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    //set defaults for create case
    compData.CompanyName = "";
    compData.VendorID = "";
    compData.Address = "";
    compData.TownCity = "";
    compData.ProvinceState = "";
    compData.CountryName = "";
    compData.PostalCode = "";
    compData.CategoryID = null;
    compData.CompanyStructureID = 1;
    compData.IndigenousGroupID = null;
    compData.WomanOwned = false;
    compData.VeteranOwned = false;
    compData.NorthernResident = false;
    compData.Disability = false;
    compData.VisibleMinority = false;
    compData.LGBTQ = false;
    compData.Local = false;
    compData.AdministrativeNotes = "";
    compData.IsClient = false;
    compData.CompanySourceID = 1;
    compData.IsActive = true;
    compData.Indigenous = false;
    compData.NativeAmerican = false;
    compData.ListingConfirmed = true;
    compData.ShowAsActive = "Active";
    compData.TierID = null;
    compData.CompanyNotes = "";
    compData.Reference = "";
    compData.RegionID = null;
    compData.ActivityLocationID = null;
  }

  const onCloseDuplicateCheck = () => {
    setShowDuplicateCheck(false);
  };

  const isActiveColumn = currentProfile.IsClient
    ? [
        {
          field: "IsActive",
          title: "Active?",
          checkbox: true,
          columnWidth: 80,
        },
      ]
    : [];

  const contractColumns = [
    {
      field: "ContractNumber",
      title: "Contract",
      columnWidth: IsExtraLargeScreen() ? 120 : 100,
    },
    {
      field: "ContractTitle",
      title: "Title",
    },
    { field: "ProjectName", title: "Project" },
    {
      field: "AwardDate",
      title: "Award Date",
      filter: "date",
      columnWidth: IsExtraLargeScreen() ? 150 : 120,
    },
    ...isActiveColumn,
  ];

  const periodColumns = [
    {
      field: "SequenceID",
      title: "ID",
      filter: "text",
      format: "SR-{0:0}",
      columnWidth: IsExtraLargeScreen() ? 80 : 60,
    },
    {
      field: "ProjectName",
      title: "Project",
    },
    ...(IsExtraLargeScreen()
      ? [
          {
            field: "ProjectComponentName",
            title: "Component",
          },
        ]
      : []),
    {
      field: "Month",
      title: t("objPlt:platformwide.fields.month"),
      columnWidth: 80,
    },
    {
      field: "Year",
      title: t("objPlt:platformwide.fields.year"),
      columnWidth: 80,
    },
    {
      field: "TotalPayment",
      title: IsExtraLargeScreen()
        ? t("objBen:objects.spendreporting.fields.totalspend")
        : t("objBen:objects.payment.name"),
      format: "{0:c}",
      filter: "numeric",
    },
    ...(ft_ben_Contract
      ? [
          {
            field: "ContractTitle",
            title: t("objBen:objects.contract.name"),
          },
        ]
      : []),
  ];

  const spendActivityColumn = [
    {
      field: "PaidByCompanyName",
      title: t("objBen:objects.payment.fields.paidby"),
    },
    {
      field: "PaymentDate",
      title: t("objPlt:platformwide.fields.date"),
      filter: "date",
    },
    {
      field: "PaymentAmount",
      title: t("objBen:objects.payment.fields.amount"),
      format: "{0:c}",
      filter: "numeric",
    },
    {
      field: "Type",
      title: t("objBen:objects.payment.spendtype"),
    },
  ];

  const whpColumns = [
    {
      field: "SequenceID",
      title: "ID",
      filter: "numeric",
      format: "W-{0:0}",
      columnWidth: 60,
    },
    { field: "ProjectName", title: "Project" },
    {
      field: "ProjectComponentName",
      title: "Component",
      columnWidth: IsExtraLargeScreen() ? "" : 120,
    },
    {
      field: "StartDate",
      title: "Start Date",
      filter: "date",
      format: "{0:d}",
      columnWidth: 110,
    },
    {
      field: "EndDate",
      title: "End Date",
      filter: "date",
      format: "{0:d}",
      columnWidth: 110,
    },
    {
      field: "EmployeeCount",
      title: IsExtraLargeScreen() ? "Employees" : "Emp",
      filter: "numeric",
      format: "{0:n0}",
      columnWidth: IsExtraLargeScreen() ? 100 : 70,
    },
    {
      field: "TotalHours",
      title: "Hours",
      filter: "numeric",
      format: "{0:n0}",
      columnWidth: 70,
    },
  ];

  const communityInvestmentColumns = [
    {
      field: "OrganizationName",
      title: "Organization Name",
    },
    {
      field: "TownCity",
      title: "Community",
    },
    {
      field: "GroupName",
      title: "Affiliation",
    },
    {
      field: "SpendAmount",
      title: "Amount",
      format: "{0:c}",
    },
  ];

  const formik = useFormik({
    initialValues: compData,
    validationSchema: CompanyValidationSchema({
      isChildSchema: false,
      companies: companies,
      compData: compData,
      isAdding: isAdding,
      tierEnabled: ft_ben_Tier,
      regionEnabled: hasRegionTypes,
      activityLocationEnabled:
        ft_ben_ActivityLocation && currentProfile.IsClient,
    }),
    validateOnMount: !isAdding,
    onSubmit: submitFunc,
  });

  // These props are passed to the IppNeedsReviewBanner component if the company record needs review and is a New Company Request
  const reviewBannerConditionalProps = {
    acceptButtonText: "Review & Accept",
    renderDialogContent: ({ setShowRejectInfo }: RenderDialogContentProps) => (
      <CompanyRejectDialog
        compData={compData}
        setShowRejectInfo={setShowRejectInfo}
      />
    ),
    showMerge: true,
    showReject: true,
    alertTitle: "Accept Company Record?",
    alertText: "This record was requested by a contractor.",
  };

  let viewForm = (
    <Root>
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>
              <Grid container spacing={1}>
                <IppFormHeader
                  title="Company"
                  isEditing={isEditing}
                  isAdding={isAdding}
                />

                {currentProfile.IsClient &&
                  (!formik.isValid || !compData.ListingConfirmed) && (
                    <IppNeedsReviewBanner
                      objectTitle="Company"
                      acceptOnClick={() => {
                        setIsEditing(true);
                        formik.setFieldValue("ShowAsActive", "Active");
                      }}
                      {...(compData.ListingConfirmed === false
                        ? reviewBannerConditionalProps
                        : {})}
                    />
                  )}

                <Grid item xs={6}>
                  <IppFormDivider title="Company Details" />
                </Grid>
                {currentProfile.IsClient &&
                  !compData.IsClient &&
                  showEdit &&
                  compData.ListingConfirmed && (
                    <Grid item xs={6}>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ paddingTop: 1 }}
                      >
                        <Tooltip title="Check and compare potential duplicate companies">
                          <IppMergeButton
                            onClick={() => setShowDuplicateCheck(true)}
                          />
                        </Tooltip>
                      </Box>
                    </Grid>
                  )}
                <Grid item xs={12}>
                  <IppDisplayField
                    showLabel={false}
                    value={formik.values.CompanyName}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEmphasis={true}
                    subText={
                      ft_ben_BusinessCategory &&
                      !categoryIsLoading &&
                      formik.values.CategoryID > 0
                        ? categoriesById[formik.values.CategoryID]?.CategoryName
                        : ""
                    }
                    showEdit={currentProfile.IsClient && showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Vendor ID"
                    value={formik.values.VendorID}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={currentProfile.IsClient && showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Show as"
                    value={
                      formik.values.ShowAsActive === "Active"
                        ? "Active"
                        : "Inactive"
                    }
                    color={formik.values.ShowAsActive === "Active" ? "" : "red"}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={currentProfile.IsClient && showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Internal Reference"
                    value={formik.values.Reference}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={currentProfile.IsClient && showEdit}
                    isWebLink={formik.values.Reference ? true : false}
                    route={formik.values.Reference}
                    openInNewTab={true}
                    tooltip="This link will open in a new tab"
                  />
                </Grid>
                {ft_ben_Tier && (
                  <Grid item xs={6}>
                    <IppDisplayField
                      label={t("objBen:objects.tiers.name")}
                      value={formik.values.TierName}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={currentProfile.IsClient && showEdit}
                    />
                  </Grid>
                )}
                <IppLocationDisplay
                  address={formik.values.Address}
                  townCity={formik.values.TownCity}
                  provinceState={formik.values.ProvinceState}
                  country={formik.values.CountryName}
                  postalCode={formik.values.PostalCode}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={currentProfile.IsClient && showEdit}
                />
                {hasRegionTypes && (
                  <Grid item xs={6}>
                    <IppDisplayField
                      label={t("objBen:objects.company.fields.region.name")}
                      value={formik.values.RegionName}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={currentProfile.IsClient && showEdit}
                    />
                  </Grid>
                )}

                {/* activity location is toggled by ft_ben_region, plus need to have at least one available option in list  */}
                {ft_ben_ActivityLocation && currentProfile.IsClient && (
                  <Grid item xs={6}>
                    <IppDisplayField
                      label={t(
                        "objBen:objects.company.fields.activitylocation.name"
                      )}
                      value={formik.values.ActivityLocationName}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={currentProfile.IsClient && showEdit}
                    />
                  </Grid>
                )}

                <IppFormDivider
                  title={t("objBen:objects.company.fields.indigenousdetails")}
                />
                <Grid item xs={formik.values.Indigenous ? 12 : 6}>
                  <IppDisplayCheckbox
                    label={t("objPlt:platformwide.indigenous.name")}
                    isChecked={formik.values.Indigenous}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={currentProfile.IsClient && showEdit}
                  />
                </Grid>
                {(formik.values.Indigenous || formik.values.NativeAmerican) && (
                  <>
                    <Grid item xs={6}>
                      <IppDisplayField
                        label={t(
                          "objBen:objects.company.fields.ownershipstructure"
                        )}
                        value={
                          !indigenousGroupIsLoading &&
                          formik.values.IndigenousGroupID > 0 &&
                          indigenousGroupsById[formik.values.IndigenousGroupID]
                            ? indigenousGroupsById[
                                formik.values.IndigenousGroupID
                              ].IndigenousGroupName
                            : ""
                        }
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        showEdit={currentProfile.IsClient && showEdit}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <IppDisplayField
                        label={t(
                          "objBen:objects.company.fields.ownershipstructure"
                        )}
                        value={
                          !structureIsLoading &&
                          formik.values.CompanyStructureID > 0 &&
                          companyStructuresById[
                            formik.values.CompanyStructureID
                          ]
                            ? companyStructuresById[
                                formik.values.CompanyStructureID
                              ].CompanyStructureName
                            : ""
                        }
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        showEdit={currentProfile.IsClient && showEdit}
                      />
                    </Grid>
                  </>
                )}
                {hasDiversity && (
                  <>
                    <IppFormDivider title="Diversity Profile" />

                    <Grid item xs={12}>
                      <IppDiversityDisplay
                        parentTitle="Company"
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        showEdit={currentProfile.IsClient && showEdit}
                        womanOwned={formik.values.WomanOwned}
                        veteranOwned={formik.values.VeteranOwned}
                        northernResident={formik.values.NorthernResident}
                        disability={formik.values.Disability}
                        visibleMinority={formik.values.VisibleMinority}
                        lgbtq={formik.values.LGBTQ}
                        local={formik.values.Local}
                      />
                    </Grid>
                  </>
                )}
                <IppFormDivider title="Notes" />
                <Grid item xs={12}>
                  <IppDisplayField
                    value={formik.values.CompanyNotes}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={currentProfile.IsClient && showEdit}
                  />
                </Grid>

                <RecordHistoryDisplay
                  createdBy={formik.values.CreatedBy}
                  createDate={formik.values.CreateDate}
                  lastUpdated={formik.values.LastUpdated}
                  lastUpdatedBy={formik.values.LastUpdatedBy}
                />

                <Grid item xs={12}>
                  <IppFormButtons
                    isEditing={isEditing}
                    isAdding={isAdding}
                    setIsEditing={setIsEditing}
                    resetFunction={() => formik.resetForm()}
                    showDelete={currentProfile.IsClient && showEdit}
                    deleteFunction={() => handleDelete()}
                    fetchFunc={fetchDeleteChecksByCompany}
                    info="Unlink the above items. Go to the appropriate tab, click the Unlink button and try again."
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {
        <CompanyCheckDuplicates
          originalID={formik.values.CompanyID}
          isOpen={showDuplicateCheck}
          closeWindow={onCloseDuplicateCheck}
        />
      }
    </Root>
  );

  let editForm = (
    <form onSubmit={formik.handleSubmit}>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message="You have unsaved changes; are you sure you want to leave this page?"
        />
      )}

      <Grid container spacing={1}>
        <IppFormHeader
          title="Company"
          isEditing={isEditing}
          isAdding={isAdding}
        />
        <IppFormDivider title="Company Details" />
        <Grid item xs={6}>
          <IppTextField
            id="CompanyName"
            label="Company Name*"
            value={formik.values.CompanyName}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.CompanyName}
            errorsExpression={formik.errors.CompanyName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={6}>
          <IppTextField
            id="VendorID"
            label="Vendor ID"
            value={formik.values.VendorID}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.VendorID}
            errorsExpression={formik.errors.VendorID}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <IppLocationAutoComplete
          formik={formik}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          townCityOptions={{ show: true, required: true }}
          provinceStateOptions={{ show: true, required: true }}
          countryOptions={{ show: true, required: true }}
        />
        {ft_ben_BusinessCategory && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="CategoryID"
              options={visibleCategories}
              value={categories.find((obj) => {
                return obj.CategoryID === formik.values.CategoryID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("CategoryID", newValue.CategoryID);
                } else {
                  formik.setFieldValue("CategoryID", null);
                }
              }}
              label={t("objBen:objects.company.fields.businesscategory")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.CategoryName}
              touchedFunction={formik.touched.CategoryID}
              errorFunction={formik.errors.CategoryID}
              textValueFunction={
                !categoryIsLoading && formik.values.CategoryID > 0
                  ? categoriesById[formik.values.CategoryID]?.CategoryName
                  : ""
              }
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <IppTextField
            id="Reference"
            label="Internal Reference"
            value={formik.values.Reference}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.Reference}
            errorsExpression={formik.errors.Reference}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        {ft_ben_Tier && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="TierID"
              options={tiers}
              value={tiers.find((obj) => {
                return obj.TierID === formik.values.TierID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("TierID", newValue.TierID);
                } else {
                  formik.setFieldValue("TierID", null);
                }
              }}
              label={t("objBen:objects.tiers.name")}
              required={true}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.TierName}
              touchedFunction={formik.touched.TierID}
              errorFunction={formik.errors.TierID}
              textValueFunction={formik.values.TierName}
            />
          </Grid>
        )}
        {hasRegionTypes && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="RegionID"
              options={regions}
              value={regions.find((obj) => {
                return obj.RegionID === formik.values.RegionID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("RegionID", newValue.RegionID);
                } else {
                  formik.setFieldValue("RegionID", null);
                }
              }}
              label={t("objBen:objects.company.fields.region.name")}
              required={true}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.RegionName}
              touchedFunction={formik.touched.RegionID}
              errorFunction={formik.errors.RegionID}
              textValueFunction={formik.values.RegionName}
            />
          </Grid>
        )}
        {/* only show activity location if ft_ben_activityLocation is on and at least one option in the activity location list */}
        {ft_ben_ActivityLocation && currentProfile.IsClient && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="ActivityLocationID"
              options={activityLocations}
              value={activityLocations.find((obj) => {
                return (
                  obj.ActivityLocationID === formik.values.ActivityLocationID
                );
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "ActivityLocationID",
                    newValue.ActivityLocationID
                  );
                } else {
                  formik.setFieldValue("ActivityLocationID", null);
                }
              }}
              label={t("objBen:objects.company.fields.activitylocation.name")}
              required={true}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.ActivityLocationName}
              touchedFunction={formik.touched.ActivityLocationID}
              errorFunction={formik.errors.ActivityLocationID}
              textValueFunction={formik.values.ActivityLocationName}
            />
          </Grid>
        )}
        <IppFormDivider title="Company Status" />
        <Grid item xs={6}>
          <IppCheckbox
            id="ShowAsActive"
            label="Show as Active"
            value={formik.values.ShowAsActive === "Active" ? true : false}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue(
                  "ShowAsActive",
                  newValue === true ? "Active" : "Inactive"
                );
              } else {
                formik.setFieldValue("ShowAsActive", "Inactive");
              }
            }}
            isEditing={isEditing}
          />
        </Grid>

        <IppFormDivider
          title={`${t("objPlt:platformwide.indigenous.name")} Details`}
        />
        <Grid item xs={6}>
          <IppCheckbox
            id="Indigenous"
            label={t("objPlt:platformwide.indigenous.name")}
            value={formik.values.Indigenous}
            onChangeFunction={async (event: ChangeEvent, newValue: any) => {
              formik.setFieldValue("Indigenous", newValue);
              if (!newValue) {
                await formik.setFieldValue("CompanyStructureID", 1);
                await formik.setFieldValue("IndigenousGroupID", null);
              }
            }}
            isEditing={isEditing}
          />
        </Grid>
        <Grid item xs={6}>
          {/* <IppCheckbox
            id="NativeAmerican"
            label="Native American (US)"
            value={formik.values.NativeAmerican}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("NativeAmerican", newValue);
                clearCheck();
              } else {
                formik.setFieldValue("NativeAmerican", false);
              }
            }}
            isEditing={isEditing}
          /> */}
        </Grid>
        {(formik.values.Indigenous || formik.values.NativeAmerican) && (
          <>
            <Grid item xs={6}>
              <IppAutocomplete
                id="IndigenousGroupID"
                options={indigenousGroups}
                value={indigenousGroups.find((obj) => {
                  return (
                    obj.IndigenousGroupID === formik.values.IndigenousGroupID
                  );
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue(
                      "IndigenousGroupID",
                      newValue.IndigenousGroupID
                    );
                  } else {
                    formik.setFieldValue("IndigenousGroupID", null);
                  }
                }}
                label={t("objPlt:platformwide.indigenous.indigenousgroup")}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) =>
                  option.IndigenousGroupName
                }
                touchedFunction={formik.touched.IndigenousGroupID}
                errorFunction={formik.errors.IndigenousGroupID}
                textValueFunction={
                  !indigenousGroupIsLoading &&
                  formik.values.IndigenousGroupID > 0 &&
                  indigenousGroupsById[formik.values.IndigenousGroupID]
                    ? indigenousGroupsById[formik.values.IndigenousGroupID]
                        .IndigenousGroupName
                    : ""
                }
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {formik.values.IndigenousGroupRaw && (
                  <Typography variant="caption">
                    You uploaded:{" "}
                    <strong>{formik.values.IndigenousGroupRaw}</strong>
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <IppAutocomplete
                id="CompanyStructureID"
                options={clientCompanyStructures}
                value={companyStructures.find((obj) => {
                  return (
                    obj.CompanyStructureID === formik.values.CompanyStructureID
                  );
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue(
                      "CompanyStructureID",
                      newValue.CompanyStructureID
                    );
                  } else {
                    formik.setFieldValue("CompanyStructureID", 1);
                  }
                }}
                label={t("objBen:objects.company.fields.ownershipstructure")}
                required={true}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) =>
                  option.CompanyStructureName
                }
                touchedFunction={formik.touched.CompanyStructureID}
                errorFunction={formik.errors.CompanyStructureID}
                textValueFunction={
                  !structureIsLoading &&
                  formik.values.CompanyStructureID > 0 &&
                  companyStructuresById[formik.values.CompanyStructureID]
                    ? companyStructuresById[formik.values.CompanyStructureID]
                        .CompanyStructureName
                    : ""
                }
              />
            </Grid>
          </>
        )}

        {hasDiversity && (
          <>
            <IppFormDivider title="Diversity Profile" />

            <Grid item xs={12}>
              <IppDiversityCheckboxes
                parentTitle="Company"
                isEditing={isEditing}
                womanOwned={formik.values.WomanOwned}
                veteranOwned={formik.values.VeteranOwned}
                northernResident={formik.values.NorthernResident}
                disability={formik.values.Disability}
                visibleMinority={formik.values.VisibleMinority}
                lgbtq={formik.values.LGBTQ}
                local={formik.values.Local}
                handleChange={formik.handleChange}
              />
            </Grid>
          </>
        )}
        <IppFormDivider title="Notes" />
        <Grid item xs={12}>
          <IppTextField
            id="CompanyNotes"
            label=""
            value={formik.values.CompanyNotes}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.CompanyNotes}
            errorsExpression={formik.errors.CompanyNotes}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>

        <Grid item xs={12}>
          <IppFormButtons
            isEditing={isEditing}
            isAdding={isAdding}
            setIsEditing={setIsEditing}
            resetFunction={() => formik.resetForm()}
            showDelete={currentProfile.IsClient}
            deleteFunction={handleDelete}
            fetchFunc={fetchDeleteChecksByCompany}
          />
        </Grid>
      </Grid>
    </form>
  );

  let companyDetForm =
    pageLoading ||
    indigenousGroupIsLoading ||
    userisLoading ||
    categoryIsLoading ||
    companyIsLoading ||
    isLoadingClientCompanyStructures ||
    structureIsLoading ||
    companyIsLoading ||
    (ft_ben_Tier && tierIsLoading) ||
    (ft_ben_Region && regionIsLoading) ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Root>
        <Box display="flex" justifyContent="center">
          <Paper className={classes.boxSpace}>
            <Box p={1}>
              <Grid container className={classes.editForm} spacing={1}>
                {editForm}
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Root>
    ) : (
      <Root>
        <Box display="flex" justifyContent="center">
          <Grid container spacing={1}>
            <Grid item xs={customBP ? 12 : 5}>
              {isEditing ? (
                <Dialog open={isEditing} fullWidth maxWidth="lg">
                  <DialogContent>{editForm}</DialogContent>
                </Dialog>
              ) : (
                <div>{viewForm}</div>
              )}
            </Grid>
            <Grid item xs={customBP ? 12 : 7}>
              <Paper>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={activeTab}
                      onChange={handleTabSelect}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                    >
                      <Tab value={0} label={"Users (" + users.length + ")"} />
                      {ft_ben_Contract && ft_ben_BenefitsMenu && (
                        <Tab
                          value={1}
                          label={"Contracts (" + contracts.length + ")"}
                        />
                      )}
                      {ft_ben_BenefitsMenu && ft_ben_WorkForce && (
                        <Tab
                          value={2}
                          label={"Work Hours (" + workHourPeriods.length + ")"}
                        />
                      )}
                      {ft_ben_CommunityInvestment && (
                        <Tab
                          value={3}
                          label={`Community Investments (${communityInvestments.length})`}
                        />
                      )}
                      {(ft_ben_Spend || ft_ben_SpendReport) &&
                        currentProfile.IsClient &&
                        ft_ben_BenefitsMenu && (
                          <Tab
                            value={4}
                            label={`Spend Activity (${pmts.length})`}
                          />
                        )}
                      {ft_ben_SpendReport && ft_ben_BenefitsMenu && (
                        <Tab
                          value={5}
                          label={`Spend Reports (${paymentPeriods.length})`}
                        />
                      )}
                      {currentProfile.IsClientAdmin && !ft_ben_Contract && (
                        <Tab
                          value={6}
                          label={`Projects (${compData.ProjectCount})`}
                        />
                      )}

                      <Tab
                        value={7}
                        label={`${t("objBen:objects.company.attachments")} (${
                          attachCount.Count
                        })`}
                      />
                    </Tabs>
                  </Box>
                  <IppTabPanel value={activeTab} index={0}>
                    <Box>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Typography variant="h5">
                            Users for this Company
                          </Typography>
                        </Grid>
                        {currentProfile.IsClientAdmin && (
                          <Grid item xs>
                            <Grid container justifyContent="flex-end">
                              {compData.ListingConfirmed && (
                                <IppButton
                                  color={"secondary"}
                                  startIcon={<People />}
                                  variant="text"
                                  onClick={handleManageUsers}
                                  disabled={!currentProfile.IsClient}
                                >
                                  Manage Users
                                </IppButton>
                              )}
                            </Grid>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          {userisLoading ? (
                            <LoadingIndicator />
                          ) : (
                            <>
                              <KendoGrid data={users}>
                                <GridNoRecords>
                                  ** No users added for this company **
                                </GridNoRecords>
                                <GridColumn
                                  field="IsPrimary"
                                  title=" "
                                  cell={PrimaryIconCell}
                                  width={50}
                                />
                                <GridColumn field="Surname" title="Surname" />
                                <GridColumn
                                  field="FirstName"
                                  title="First Name"
                                />
                                <GridColumn
                                  field="EmailAddress"
                                  title="Email"
                                />
                              </KendoGrid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </IppTabPanel>
                  {ft_ben_Contract && (
                    <IppTabPanel value={activeTab} index={1}>
                      <Box>
                        <IppChildInventory
                          title="Contract"
                          parentTitle="Company"
                          linkURL="contracts"
                          tableData={cnList}
                          idField="ContractID"
                          nameField="ContractTitle"
                          parentID={compData.CompanyID}
                          parentProjectID={null}
                          relatedField="ContractID"
                          columns={contractColumns}
                          tableName="Contracts"
                          id="ContractList"
                          label="Contracts"
                          options={contracts}
                          selectedValues={[]}
                          setSelectedValues={setCompanyID}
                          setDidSaveInventory={false}
                          parentValue={null}
                          showOptions={false}
                          noLinkCell={!currentProfile.IsClient}
                        />
                      </Box>
                    </IppTabPanel>
                  )}
                  {ft_ben_WorkForce && (
                    <IppTabPanel value={activeTab} index={2}>
                      <Box>
                        <IppChildInventory
                          title="Work Hour"
                          parentTitle="Company"
                          linkURL="workhours"
                          tableData={whpList}
                          idField="PeriodID"
                          nameField="ProjectName"
                          parentID={compData.CompanyID}
                          parentProjectID={null}
                          relatedField="PeriodID"
                          columns={whpColumns}
                          tableName="WorkHourPeriods"
                          id="WorkHourPeriodList"
                          label="Work Hours"
                          options={workHourPeriods}
                          selectedValues={[]}
                          setSelectedValues={setCompanyID}
                          setDidSaveInventory={false}
                          parentValue={null}
                          showOptions={false}
                        />
                      </Box>
                    </IppTabPanel>
                  )}
                  {ft_ben_CommunityInvestment && (
                    <IppTabPanel value={activeTab} index={3}>
                      <Box>
                        <IppChildInventory
                          title="Community Investment"
                          parentTitle="Company"
                          linkURL="communityinvestments"
                          tableData={communityInvestmentData}
                          idField="CommunityInvestmentID"
                          nameField="CommunityInvestmentID"
                          parentID={compData.CompanyID}
                          parentProjectID={null}
                          relatedField="CommunityInvestmentID"
                          columns={communityInvestmentColumns}
                          tableName="CommunityInvestment"
                          id="CommunityInvestmentList"
                          label="Community Investments"
                          options={communityInvestments}
                          selectedValues={[]}
                          setSelectedValues={setCompanyID}
                          setDidSaveInventory={false}
                          parentValue={null}
                          showOptions={false}
                        />
                      </Box>
                    </IppTabPanel>
                  )}
                  {(ft_ben_Spend || ft_ben_SpendReport) &&
                    ft_ben_BenefitsMenu &&
                    currentProfile.IsClient && (
                      <IppTabPanel value={activeTab} index={4}>
                        <Box>
                          <IppChildInventory
                            title={t("objBen:objects.payment.spendactivity")}
                            parentTitle={t("objBen:objects.company.name")}
                            linkURL="payments"
                            tableData={pmts}
                            idField="PaymentID"
                            nameField="PaymentID"
                            parentID={compData.CompanyID}
                            relatedField="PaymentID"
                            columns={spendActivityColumn}
                            tableName="Payments"
                            id="PaymentList"
                            label={t("objBen:objects.payment.name")}
                            options={payments}
                            selectedValues={pmts}
                            setSelectedValues={setCompanyID}
                            setDidSaveInventory={false}
                            parentValue={compData}
                            showOptions={false}
                          />
                        </Box>
                      </IppTabPanel>
                    )}
                  {ft_ben_SpendReport && (
                    <IppTabPanel value={activeTab} index={5}>
                      <Box>
                        <IppChildInventory
                          title={t("objBen:objects.spendreporting.name")}
                          parentTitle="Company"
                          linkURL="paymentperiods"
                          tableData={paymentPeriods}
                          idField="PaymentPeriodID"
                          nameField="PaymentPeriodID"
                          parentID={companyID}
                          parentProjectID={null}
                          relatedField="PaymentPeriodID"
                          columns={periodColumns}
                          tableName="Periods"
                          id="PaymentPeriodList"
                          label={t(
                            "objBen:objects.spendreporting.report_plural"
                          )}
                          options={paymentPeriods}
                          selectedValues={[]}
                          setSelectedValues={null}
                          setDidSaveInventory={false}
                          parentValue={compData}
                          showOptions={false}
                        />
                      </Box>
                    </IppTabPanel>
                  )}
                  {currentProfile.IsClientAdmin && !ft_ben_Contract && (
                    <IppTabPanel value={activeTab} index={6}>
                      <Box>
                        <IppCompanyProjectInventory
                          title={t(
                            "objPlt:objects.project.activefornotifications"
                          )}
                          fetchID={companyID}
                          //itemID={companyID}
                          showField="ProjectName"
                          showFieldDescriptor="Project"
                          companyName={compData.CompanyName}
                        />
                      </Box>
                    </IppTabPanel>
                  )}
                  <IppTabPanel value={activeTab} index={7}>
                    <Box>
                      <IppAttachmentInventory
                        recordType="Company"
                        itemID={companyID}
                        companyID={formik.values.CompanyID}
                        projectID={0}
                        moduleID={1}
                        title={t("objBen:objects.company.attachments")}
                        categoryRecordType="Company"
                      />
                    </Box>
                  </IppTabPanel>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Root>
    );

  return <div id="company-form">{companyDetForm}</div>;
};
