import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { addIssue, updIssue, delIssue, fetchIssues } from "./IssueSlice";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Tabs,
  Tab,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchInteractionIssuesByIssue } from "../interactionIssue/InteractionIssueSlice";
import { fetchGrievanceIssuesByIssue } from "../grievanceIssue/GrievanceIssueSlice";
import { IppChildInventory } from "components/Inventory/IppChildInventory";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import { fetchDeleteChecksByIssue } from "features/deleteCheck/DeleteCheckSlice";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppDisplayField } from "components/IppDisplayField";
import { IppTabPanel } from "components/IppTabPanel";
import { countAttachments } from "api/attachmentsAPI";
import { fetchStakeholderGroups } from "features/platform/groups/StakeholderGroupSlice";
import { fetchGroupIssuesByIssue } from "../groupIssue/GroupIssueSlice";
import { IppFormDivider } from "components/IppFormDivider";
import { IppTokenAutocomplete } from "components/IppTokenAutoComplete";
import { fetchContactIssuesByIssue } from "../contactIssue/ContactIssueSlice";
import { fetchContacts } from "features/platform/contacts/ContactSlice";
import { Contact } from "api/stakeholder/contactAPI";
import { IppContactAutoComplete } from "components/IppContactAutoComplete";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { UserWriteAccess } from "utils/userAccess";

const PREFIX = "IssueForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const IssueForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const snackbarConstants = useSnackBarConstants();

  const [didSaveInventory, setDidSaveInventory] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  // used to display attachments count
  const [attachCount, setAttachCount] = useState<any>({ Count: 0 });

  let itemData = props.issue || {};
  //let index: number[] = [];

  const [isEditing, setIsEditing] = useState(itemData.IssueID ? false : true);
  const [isAdding, setIsAdding] = useState(itemData.IssueID ? false : true);
  const [listGroups, setListGroups] = useState<Array<string>>([]);
  const showEdit = UserWriteAccess("Engagement");

  const {
    clientId,
    isLoading: clientIsLoading,
    ft_eng_Sentiment,
    ft_eng_GroupIssue,
    ft_eng_ContactIssue,
    ft_eng_Grievance,
  } = useSelector((state: RootState) => state.client);

  const {
    stakeholderGroupList,
    stakeholderGroupsById,
    isLoading: groupIsLoading,
    error: groupError,
  } = useSelector((state: RootState) => state.stakeholderGroups);

  const stakeholderGroups = stakeholderGroupList.map(
    (id) => stakeholderGroupsById[id]
  );

  const {
    groupIssueList,
    groupIssuesById,
    error: groupIssueError,
    isLoading: groupIssueIsLoading,
  } = useSelector((state: RootState) => state.groupIssues);

  const groupIssuesUnfiltered = groupIssueList.map((id) => groupIssuesById[id]);
  const groupIssues = groupIssuesUnfiltered.filter(
    (groupIssue) => groupIssue.IssueID === itemData.IssueID
  );

  const {
    interactionIssueList,
    interactionIssuesById,
    error: interactionIssueError,
    isLoading: interactionIssueIsLoading,
  } = useSelector((state: RootState) => state.interactionIssues);

  const interactionIssues = interactionIssueList.map(
    (id) => interactionIssuesById[id]
  );

  const data = interactionIssues.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.InteractionDate !== null) {
      n.InteractionDate = ConvertDateOffset(p.InteractionDate);
    } else {
      n.InteractionDate = null;
    }
    return n;
  });

  const {
    grievanceIssueList,
    grievanceIssuesById,
    error: grievanceIssuesError,
    isLoading: grievanceIssueIsLoading,
  } = useSelector((state: RootState) => state.grievanceIssues);

  const grievanceIssues = grievanceIssueList.map(
    (id) => grievanceIssuesById[id]
  );

  const gg = grievanceIssues.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.ReportedDate !== null) {
      n.ReportedDate = ConvertDateOffset(p.ReportedDate);
    } else {
      n.ReportedDate = null;
    }

    if (p.ResolutionDate !== null) {
      n.ResolutionDate = ConvertDateOffset(p.ResolutionDate);
    } else {
      n.ResolutionDate = null;
    }

    return n;
  });

  const { grievanceList, grievancesById, subGrievanceisLoading } = useSelector(
    (state: RootState) => state.grievances
  );

  const grievances = grievanceList.map((id) => grievancesById[id]);

  const {
    issueList,
    issuesById,
    isLoading: issueIsLoading,
    isLoaded: issueIsLoaded,
    error: issueError,
  } = useSelector((state: RootState) => state.issues);

  let issues = issueList.map((id) => issuesById[id]);

  // if editing, remove the current issue from the list of issues used
  // for parent issue dropdown
  // helper function to filter out issues that can cause a loop and itself
  // set() reduces the time complexity
  const noLoop = (issueid: number) => {
    const excludedIssues = new Set();
    const stack = [issueid];

    while (stack.length > 0) {
      const currentIssueId = stack.pop();
      const currentIssue = issues.find(
        (issue) => issue.IssueID === currentIssueId
      );

      if (currentIssue) {
        excludedIssues.add(currentIssue);
        const children = issues.filter(
          (issue) => issue.ParentIssueID === currentIssueId
        );
        stack.push(...children.map((child) => child.IssueID));
      }
    }

    return issues.filter((issue) => !excludedIssues.has(issue));
  };

  if (!isAdding) {
    //    issues = issues.filter((item) => item.IssueID !== itemData.IssueID);
    issues = noLoop(itemData.IssueID);
  }

  const {
    contactIssueList,
    contactIssuesById,
    error: contactIssueError,
    isLoading: contactIssueIsLoading,
  } = useSelector((state: RootState) => state.contactIssues);

  const contactIssuesUnfiltered = contactIssueList.map(
    (id) => contactIssuesById[id]
  );

  const contactIssues = contactIssuesUnfiltered
    .filter((contact) => contact.IssueID === itemData.IssueID)
    .map((p) => {
      let n = Object.assign({}, p) as any;
      n.ContactName = p.ContactName
        ? p.ContactName.trim() !== ""
          ? p.ContactName.trim()
          : `* ${t("objPlt:objects.contact.missingname")} *`
        : `* ${t("objPlt:objects.contact.missingname")} *`;
      return n;
    });

  const {
    contactList,
    contactsById,
    isLoading: contactIsLoading,
    error: contactError,
  } = useSelector((state: RootState) => state.contacts);

  const contacts = contactList.map((id) => contactsById[id]);

  //usestate for Rootstate of attachment
  const { attachmentsById, attachmentList, error, isLoading } = useSelector(
    (state: RootState) => state.attachments
  );

  const attachments = attachmentList.map((id) => attachmentsById[id]);

  //routing path for parent issue
  const parentRoute =
    `${window.location.href.substring(
      0,
      window.location.href.lastIndexOf("issues")
    )}` +
    "issues/" +
    itemData.ParentIssueID;

  // reset once itemdata changes
  useEffect(() => {
    if (itemData?.IssueID) {
      setIsAdding(false);
      setIsEditing(false);
    }
  }, [itemData]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        //Fetch Issues on page refresh or when skipping the inventory page.
        if (!issueIsLoaded) {
          dispatch(fetchIssues(accessToken));
        }
        dispatch(fetchInteractionIssuesByIssue(accessToken, itemData.IssueID));
        dispatch(fetchGrievanceIssuesByIssue(accessToken, itemData.IssueID));
        dispatch(fetchStakeholderGroups(accessToken));
        dispatch(fetchGroupIssuesByIssue(accessToken, itemData.IssueID));
        dispatch(fetchContacts(accessToken));
        dispatch(fetchContactIssuesByIssue(accessToken, itemData.IssueID));
        const count = await countAttachments(
          accessToken,
          "Issues",
          itemData.IssueID
        );
        setAttachCount(count.attachments ? count.attachments : 0);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, itemData.IssueID, dispatch, getAccessTokenSilently]);

  // get lists for contact  autocomplete fields
  useEffect(() => {
    // effect
    if (!contactIssueIsLoading) {
      let concat = contacts.filter((contact) =>
        contactIssues.some((issue) => issue.ContactID === contact.ContactID)
      );
      setSelectedContacts(concat);
    }
    return () => {
      // cleanup
    };
  }, [itemData.IssueID, contactIssueIsLoading, isEditing, contactIsLoading]);

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (attachments.length > 0) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === itemData.IssueID && item.RecordType === "Issues"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  // get list of groups for token autocomplete field
  useEffect(() => {
    if (!groupIssueIsLoading) {
      let newList: string[] = [];
      groupIssues.map((item) => newList.push(item.GroupName));
      setListGroups(newList);
    }
    return () => {
      // cleanup
    };
  }, [itemData.IssueID, groupIssueIsLoading, isEditing]);

  // save when changes made to child records
  useEffect(() => {
    if (didSaveInventory) {
      submitFunc(itemData);
      setDidSaveInventory(false);
    }
    return () => {
      // cleanup
    };
  }, [didSaveInventory]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(
          addIssue(
            accessToken,
            values,
            listGroups,
            selectedContacts,
            true,
            snackbarConstants
          )
        );
        setIsEditing(false);
        setIsAdding(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (itemData.IssueID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          dispatch(
            updIssue(
              accessToken,
              values.IssueID,
              values,
              listGroups,
              selectedContacts,
              true,
              snackbarConstants
            )
          );
          setIsEditing(false);
          setIsAdding(false);
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    itemData.IssueDescription = "";
    itemData.IssueName = "";
    itemData.ParentIssueID = -1;
  }

  const handleDelete = () => {
    // function to delete current community investment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delIssue(accessToken, itemData.IssueID, snackbarConstants));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleTabSelect = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  //Add or remove Asterisk * when changing validation
  const validationSchema = yup.object({
    IssueName: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.issue.name"),
        })
      )
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objStk:objects.issue.name"),
          count: 250,
        })
      )
      .test(
        "checkDuplicate",
        t("strGen:validation.exists", {
          fieldname: t("objStk:objects.issue.name"),
        }),
        function (value: any) {
          if (value === itemData.IssueName) {
            return true;
          }

          let name = value ? value : "";

          let checkDupe = issues.filter(
            (obj: any) => obj.IssueName.toLowerCase() === name.toLowerCase()
          ).length;

          if (checkDupe === 0) {
            return true;
          } else {
            return false;
          }
        }
      ),
  });

  const formik = useFormik({
    initialValues: itemData,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: submitFunc,
  });

  let viewForm = (
    <Box display="flex" justifyContent="center">
      <Grid container className={classes.editForm} spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.boxSpace}>
            <Grid container spacing={1}>
              <IppFormHeader
                title={t("objStk:objects.issue.name")}
                isEditing={isEditing}
                isAdding={isAdding}
                showReturn={false}
              />
              <Grid item xs={6} xl={12}>
                <IppDisplayField
                  label={t("objStk:objects.issue.fields.title")}
                  value={formik.values.IssueName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6} xl={12}>
                <IppDisplayField
                  label={
                    t("objStk:objects.issue.fields.parentissueifapplicable") ||
                    ""
                  }
                  value={
                    formik.values.ParentIssueID > 0 &&
                    issuesById[formik.values.ParentIssueID]
                      ? issuesById[formik.values.ParentIssueID].IssueName
                      : t("strGen:components.none")
                  }
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  isWebLink={formik.values.ParentIssueID > 0 ? true : false}
                  route={parentRoute}
                  openInNewTab={false}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <IppDisplayField
                  label={t("objStk:objects.issue.fields.description")}
                  value={formik.values.IssueDescription}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>

              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={isEditing}
                  isAdding={isAdding}
                  setIsEditing={setIsEditing}
                  resetFunction={() => formik.resetForm()}
                  showDelete={showEdit}
                  deleteFunction={() => handleDelete()}
                  fetchFunc={fetchDeleteChecksByIssue}
                  info={t("strGen:prompts.deletechecks.unlinkitems")}
                  recordID={itemData.IssueID}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  let editForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <IppFormHeader
          title={t("objStk:objects.issue.name")}
          isEditing={isEditing}
          isAdding={isAdding}
          showReturn={false}
        />
        <Grid item xs={12}>
          <IppTextField
            id="IssueName"
            required
            label={t("objStk:objects.issue.fields.title")}
            value={formik.values.IssueName}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.IssueName}
            errorsExpression={formik.errors.IssueName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>

        <Grid item xs={12}>
          <IppTextField
            id="IssueDescription"
            label={t("objStk:objects.issue.fields.description")}
            required={false}
            value={formik.values.IssueDescription}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.IssueDescription}
            errorsExpression={formik.errors.IssueDescription}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            multiLine={true}
          />
        </Grid>

        <Grid item xs={12}>
          <IppAutocomplete
            id="ParentIssueID"
            options={issues}
            required={false}
            autoPopulate={false}
            value={issues.find((obj) => {
              return obj.IssueID === formik.values.ParentIssueID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("ParentIssueID", newValue.IssueID);
              } else {
                formik.setFieldValue("ParentIssueID", -1);
              }
            }}
            label={t("objStk:objects.issue.fields.parentissueifapplicable")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) => option.IssueName}
            textValueFunction={
              formik.values.ParentIssueID > 0 &&
              issuesById[formik.values.ParentIssueID]
                ? issuesById[formik.values.ParentIssueID].IssueName
                : ""
            }
            touchedFunction={formik.touched.ParentIssueID}
            errorFunction={formik.errors.ParentIssueID}
            toolTip={t("strGen:tooltips.issue.fields.parentissue")}
          />
        </Grid>
        {ft_eng_GroupIssue && isAdding && (
          <>
            <IppFormDivider
              title={t("objStk:objects.issue.headers.associatedgroups", {
                count: 2,
              })}
            />
            <Grid item xs={12}>
              <IppTokenAutocomplete
                id="GroupList"
                label={t("objStk:objects.issue.fields.groupsinvolved", {
                  count: 2,
                })}
                options={stakeholderGroups.map((option) => option.GroupName)}
                selectedValues={listGroups}
                setSelectedValues={setListGroups}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          </>
        )}
        {ft_eng_ContactIssue && isAdding && (
          <>
            <IppFormDivider
              title={t("objStk:objects.issue.headers.associatedcontacts", {
                count: 2,
              })}
            />
            <Grid item xs={12}>
              <IppContactAutoComplete
                options={contacts}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <IppFormButtons
            isEditing={isEditing}
            isAdding={isAdding}
            setIsEditing={setIsEditing}
            resetFunction={() => {
              formik.resetForm();
              setIsEditing(false);
              setIsAdding(false);
            }}
            showDelete={true}
            showCancel={true}
            deleteFunction={() => handleDelete()}
            fetchFunc={fetchDeleteChecksByIssue}
            info={t("strGen:prompts.deletechecks.unlinkitems")}
          />
        </Grid>
      </Grid>
    </form>
  );

  const groupColumns = [
    { field: "GroupName", title: t("objPlt:objects.group.name") },
    {
      field: "OrganizationTypeName",
      title: t("objPlt:objects.group.fields.grouptype"),
    },
    {
      field: "TownCity",
      title: t("objPlt:platformwide.fields.towncity"),
    },
    {
      field: "ProvinceState",
      title: t("objPlt:platformwide.fields.provincestate"),
    },
  ];

  const grievanceColumns = [
    {
      field: "SequenceID",
      title: t("objStk:objects.grievance.sequenceid"),
      format: "G-{0:0}",
      filter: "text",
      columnWidth: 60,
    },
    {
      field: "GrievanceName",
      title: t("objStk:objects.grievance.columnname"),
    },
    {
      field: "ReportedDate",
      title: t("objStk:objects.grievance.fields.datereported"),
      format: "{0:d}",
      filter: "date",
    },
    {
      field: "ResolutionDate",
      title: t("objStk:objects.grievance.fields.dateresolved"),
      format: "{0:d}",
      filter: "date",
    },
    {
      field: "GrievanceStatusText",
      title: t("objStk:objects.grievance.fields.status"),
    },
  ];

  const interactionColumns = [
    {
      field: "CommunicationID",
      title: t("objStk:objects.interaction.sequenceid"),
      format: "C-{0:0}",
      filter: "string",
      columnWidth: 70,
    },
    {
      field: "InteractionTitle",
      title: t("objStk:objects.interaction.fields.title"),
    },
    {
      field: "InteractionTypeName",
      title: t("objStk:objects.interaction.fields.type"),
      icons: true,
      columnWidth: 60,
    },
    {
      field: "InteractionDate",
      title: t("objStk:objects.interaction.fields.date"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 150,
    },
    ...(ft_eng_Sentiment
      ? [
          {
            field: "SentimentLevel",
            title: t("objStk:objects.interaction.fields.sentiment"),
            icons: true,
            columnWidth: 150,
          },
        ]
      : []),
  ];

  const contactColumns = [
    { field: "ContactName", title: t("objPlt:objects.contact.name") },
    { field: "ContactTitle", title: t("objPlt:objects.contact.fields.title") },
    { field: "PrimaryPhone", title: t("objPlt:objects.contact.fields.phone") },
    {
      field: "Email",
      title: t("objPlt:objects.contact.fields.email"),
      commaDelimited: true,
    },
  ];

  // useEffect(() => {
  //   if (props && itemData !== formik.values) {
  //     formik.values = itemData;
  //   }
  // }, [itemData]);

  let pageForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : isAdding ? (
    <Box display="flex">
      <Grid container className={classes.editForm} spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.boxSpace}>{editForm}</Paper>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={4}>
        {isEditing ? (
          <Dialog open={isEditing} fullWidth maxWidth="md">
            <DialogContent>{editForm}</DialogContent>
          </Dialog>
        ) : (
          <div>{viewForm}</div>
        )}
      </Grid>
      <Grid item xl={8} xs={12}>
        <Paper>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={activeTab}
                onChange={handleTabSelect}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab
                  value={0}
                  label={`${t("objStk:objects.interaction.name", {
                    count: 2,
                  })} (${interactionIssues.length})`}
                />
                {ft_eng_ContactIssue && (
                  <Tab
                    value={1}
                    label={`${t("objPlt:objects.contact.name", {
                      count: 2,
                    })} (${contactIssues.length})`}
                  />
                )}
                {ft_eng_GroupIssue && (
                  <Tab
                    value={2}
                    label={`${t("objPlt:objects.group.name", {
                      count: 2,
                    })} (${groupIssues.length})`}
                  />
                )}
                {ft_eng_Grievance && (
                  <Tab
                    value={3}
                    label={`${t("objStk:objects.grievance.name", {
                      count: 2,
                    })} (${grievanceIssues.length})`}
                  />
                )}
                <Tab
                  value={4}
                  label={`${t("objStk:objects.issue.attachments")} (${
                    attachCount.Count
                  })`}
                />
              </Tabs>
            </Box>
            <IppTabPanel value={activeTab} index={0}>
              <Box>
                <IppChildInventory
                  title={t("objStk:objects.interaction.name")}
                  parentTitle={t("objStk:objects.issue.name")}
                  linkURL="communications"
                  tableData={data}
                  idField="InteractionIssueID"
                  nameField="InteractionTitle"
                  parentID={itemData.IssueID}
                  relatedField="InteractionID"
                  columns={interactionColumns}
                  showAdd={true}
                  tableName="InteractionIssue"
                  id="CommunicationList"
                  label={t("objStk:objects.interaction.communicationhistory")}
                  options={interactionIssues}
                  selectedValues={interactionIssues}
                  setSelectedValues={null}
                  setDidSaveInventory={setDidSaveInventory}
                  parentValue={itemData.IssueName}
                  secondLinkCell={1}
                />
              </Box>
            </IppTabPanel>
            {ft_eng_ContactIssue && (
              <IppTabPanel value={activeTab} index={1}>
                <Box>
                  <IppChildInventory
                    title={t("objPlt:objects.contact.name")}
                    parentTitle={t("objStk:objects.issue.name")}
                    linkURL="contacts"
                    tableData={contactIssues}
                    idField="ContactIssueID"
                    nameField="ContactName"
                    parentID={itemData.IssueID}
                    relatedField="ContactID"
                    columns={contactColumns}
                    showEdit={true}
                    tableName="ContactIssue"
                    id="ContactList"
                    label={t("objPlt:objects.contact.relatedcontacts", {
                      count: 2,
                    })}
                    options={contacts}
                    selectedValues={selectedContacts}
                    setSelectedValues={setSelectedContacts}
                    setDidSaveInventory={setDidSaveInventory}
                    parentValue={null}
                    showOptions={true}
                  />
                </Box>
              </IppTabPanel>
            )}
            {ft_eng_GroupIssue && (
              <IppTabPanel value={activeTab} index={2}>
                <Box>
                  <IppChildInventory
                    title={t("objPlt:objects.group.name")}
                    parentTitle={t("objStk:objects.issue.name")}
                    linkURL="groups"
                    tableData={groupIssues}
                    idField="GroupIssueID"
                    nameField="GroupName"
                    parentID={itemData.IssueID}
                    relatedField="GroupID"
                    columns={groupColumns}
                    showEdit={true}
                    tableName="GroupIssue"
                    id="GroupList"
                    label={t("objStk:objects.issue.fields.groupsinvolved", {
                      count: 2,
                    })}
                    options={stakeholderGroups}
                    selectedValues={listGroups}
                    setSelectedValues={setListGroups}
                    setDidSaveInventory={setDidSaveInventory}
                    parentValue={null}
                    showOptions={true}
                  />
                </Box>
              </IppTabPanel>
            )}
            {ft_eng_Grievance && (
              <IppTabPanel value={activeTab} index={3}>
                <Box>
                  <IppChildInventory
                    title={t("objStk:objects.grievance.name")}
                    parentTitle={t("objStk:objects.issue.name")}
                    linkURL="grievances"
                    tableData={gg}
                    idField="GrievanceIssueID"
                    nameField="GrievanceName"
                    parentID={itemData.IssueID}
                    relatedField="GrievanceID"
                    columns={grievanceColumns}
                    showAdd={true}
                    tableName="GrievanceGroup"
                    id="GrivanceList"
                    label={t("objStk:objects.grievance.grievancehistory")}
                    options={grievanceIssues}
                    selectedValues={interactionIssues}
                    setSelectedValues={null}
                    setDidSaveInventory={setDidSaveInventory}
                    parentValue={itemData.IssueName}
                    secondLinkCell={1}
                  />
                </Box>
              </IppTabPanel>
            )}
            <IppTabPanel value={activeTab} index={4}>
              <Box>
                <Grid spacing={1} className={classes.editForm}>
                  <IppAttachmentInventory
                    recordType="Issues"
                    itemID={itemData.IssueID}
                    companyID={formik.values.CompanyID}
                    projectID={0}
                    moduleID={3}
                    title={t("objStk:objects.issue.attachments")}
                    categoryRecordType="Issue"
                  />
                </Grid>
              </Box>
            </IppTabPanel>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );

  return <Root>{pageForm}</Root>;
};
