import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { RootState } from "app/rootReducer";
import { IppCompanyProjectInventory } from "components/Inventory/IppCompanyProjectInventory";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppDisplayField } from "components/IppDisplayField";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppFormDivider } from "components/IppFormDivider";
import { IppFormHeader } from "components/IppFormHeader";
import { IppLocationAutoComplete } from "components/IppLocationAutoComplete";
import { IppTextField } from "components/IppTextField";
import { IppDisplayChip } from "components/IppDisplayChip";
import { IppTokenMultiSelect } from "components/IppTokenMultiSelect";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchDeleteChecksByProject } from "features/deleteCheck/DeleteCheckSlice";
import { ChangeEvent, useEffect, useState } from "react";
import { Prompt } from "react-router";
import { useTypedTranslation } from "utils/customHooks";
import { ShowAsPercent } from "utils/functions";
import { IsMedSmall } from "utils/mediaQueries";
import { UserWriteAccess } from "utils/userAccess";
import { fetchProjectComponentsByProject } from "./ProjectComponentSlice";
import {
  addProject,
  delProject,
  fetchProjects,
  updProject,
} from "./ProjectSlice";
import { fetchProjectTypes } from "./projectTypes/ProjectTypesSlice";
import { ProjectValidationSchema } from "./ProjectValidation";
import { countAttachments } from "api/attachmentsAPI";
import { IppTabPanel } from "components/IppTabPanel";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import { fetchTagsByRecordType } from "features/platform/admin/tag/TagSlice";
import { fetchRegionsByRecordType } from "features/benefits/regions/RegionSlice";
import { fetchUsers } from "features/users/UsersSlice";
import { ProjectComponentForm } from "./ProjectComponentForm";

const PREFIX = "ProjectForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  page: `${PREFIX}-page`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
  },
  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const ProjectForm = (project: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const customBP = IsMedSmall();

  const [attachCount, setAttachCount] = useState({ Count: 0 } as any);

  const handleTabSelect = (_: any, newValue: any) => {
    setActiveTab(newValue);
  };

  let projData = project.project || {};

  const [isEditing, setIsEditing] = useState(projData.ProjectID ? false : true);
  const [isAdding, setIsAdding] = useState(projData.ProjectID ? false : true);
  const [tagItems, setTagItems] = useState(
    projData.Tags ? projData.Tags : undefined
  );

  const showEdit = UserWriteAccess("Benefits", projData.ProjectID);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    clientId,
    clientName,
    ft_all_ProjectTypes,
    ft_all_Tag,
    ft_ben_Region,
    ft_all_ProjectLead,
    ft_ben_Contract,
    isLoading: clientIsLoading,
  } = useSelector((state: RootState) => state.client);

  const { clientModuleList, clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );

  const clientModules = clientModuleList.map((id) => clientModulesById[id]);

  const clientHasBenefits = clientModules
    .map((module) => module.ModuleID)
    .includes(1);

  const clientHasStk = clientModules
    .map((module) => module.ModuleID)
    .includes(3);

  const { userList, usersById } = useSelector(
    (state: RootState) => state.users
  );

  const allUsers = userList.map((id) => usersById[id]);

  // Filter for admin users only
  const adminUsers = allUsers.filter((item) => item.IsClientAdmin === true);

  const { regionList, regionsById, regionIsLoading } = useSelector(
    (state: RootState) => state.region
  );

  const regions = ft_ben_Region ? regionList.map((id) => regionsById[id]) : [];
  const hasRegionTypes = !!(ft_ben_Region && regions.length > 0);

  const { projectList, projectsById } = useSelector(
    (state: RootState) => state.projects
  );

  const projects = projectList.map((id) => projectsById[id]);

  const projectCodes = projects.map((proj) =>
    proj.ProjectID === projData.ProjectID ? null : proj.ProjectCode
  );

  const {
    projectTypeList,
    projectTypesById,
    isLoading: projectTypeIsLoading,
    error: projectTypeError,
  } = useSelector((state: RootState) => state.projectTypes);

  const projectTypes = projectTypeList.map((id) => projectTypesById[id]);

  const { isLoading: projectComponentIsLoading } = useSelector(
    (state: RootState) => state.projectComponents
  );

  const { companyProjectList, companyProjectsById } = useSelector(
    (state: RootState) => state.companyProjects
  );

  const companyProjects = companyProjectList.map(
    (id) => companyProjectsById[id]
  );

  const {
    tagList,
    tagsById,
    isLoading: tagIsLoading,
  } = useSelector((state: RootState) => state.tag);

  const tags = ft_all_Tag ? tagList.map((id) => tagsById[id]) : [];

  const [activeTab, setActiveTab] = useState(clientHasBenefits ? 0 : 1);

  const { attachmentsById, attachmentList } = useSelector(
    (state: RootState) => state.attachments
  );

  const attachments = attachmentList.map((id) => attachmentsById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchUsers(accessToken, clientId));

        if (clientHasBenefits) {
          dispatch(
            fetchProjectComponentsByProject(accessToken, projData.ProjectID)
          );
        }
        dispatch(fetchDeleteChecksByProject(accessToken, projData.ProjectID));
        if (ft_all_ProjectTypes) dispatch(fetchProjectTypes(accessToken));
        if (!projData.ProjectID) {
          dispatch(fetchProjects(accessToken));
        }
        if (ft_all_Tag) {
          dispatch(fetchTagsByRecordType(accessToken, "Project"));
        }
        if (ft_ben_Region) {
          dispatch(fetchRegionsByRecordType(accessToken, "Project"));
        }
        if (projData.ProjectID) {
          const count = await countAttachments(
            accessToken,
            "Project",
            projData.ProjectID
          );
          setAttachCount(count.attachments ? count.attachments : 0);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [
    projData.ProjectID,
    projData.ProjectComponentID,
    ft_all_ProjectTypes,
    ft_all_Tag,
  ]);

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (attachments.length > 0 && projData.ProjectID) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === projData.ProjectID &&
              item.RecordType === "Project"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  // convert tags to objects for multi autocomplete field
  useEffect(() => {
    // effect
    if (
      ft_all_Tag &&
      tags &&
      tags.length > 0 &&
      !tagIsLoading &&
      projData.Tags
    ) {
      let newList = tags.filter((item) => projData.Tags.includes(item.Name));
      setTagItems(newList);
    }
    return () => {
      // cleanup
    };
  }, [tagIsLoading, isEditing]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(addProject(accessToken, values));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleDelete = () => {
    // function to delete current project entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delProject(accessToken, projData.ProjectID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (projData.ProjectID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          dispatch(updProject(accessToken, values.ProjectID, values));
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    //set defaults for create case
    projData.ProjectName = "";
    projData.ProjectCode = "";
    projData.Location = "";
    projData.RegionID = null;
    projData.ProjectDescription = "";
    projData.ProvinceState = "";
    projData.CountryName = "";
    projData.ProjectTypeID = null;
    projData.EmployeeTarget = 0;
    projData.HoursTarget = 0;
    projData.ExpenditureTarget = 0;
    projData.Tags = [];
    projData.ProjectLeadID = null;
  }

  const formik = useFormik({
    initialValues: projData,
    validationSchema: ProjectValidationSchema(projectCodes, hasRegionTypes),
    onSubmit: submitFunc,
  });

  let editForm = (
    <form onSubmit={formik.handleSubmit}>
      {!isAdding && (
        <Prompt
          when={formik.dirty}
          message="You have unsaved changes; are you sure you want to leave this page?"
        />
      )}

      <Grid container spacing={1}>
        <IppFormHeader
          title={t("objPlt:objects.project.name")}
          isEditing={isEditing}
          isAdding={isAdding}
          returnPath="/admin/projects"
        />
        <Grid item xs={6}>
          <IppTextField
            id="ProjectName"
            label={t("objPlt:objects.project.fields.projectname")}
            required={true}
            value={formik.values.ProjectName}
            onChangeFunction={formik.handleChange}
            errorsExpression={formik.errors.ProjectName}
            touchedExpression={formik.touched.ProjectName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={6}>
          <IppTextField
            id="ProjectCode"
            label={t("objPlt:objects.project.fields.projectcode")}
            value={formik.values.ProjectCode}
            onChangeFunction={(event: any) => {
              formik.setFieldValue(
                "ProjectCode",
                event.target.value.toUpperCase()
              );
            }}
            errorsExpression={formik.errors.ProjectCode}
            touchedExpression={formik.touched.ProjectCode}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        {ft_all_ProjectTypes && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="ProjectTypeID"
              options={projectTypes}
              value={projectTypes.find((obj) => {
                return obj.ProjectTypeID === formik.values.ProjectTypeID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("ProjectTypeID", newValue.ProjectTypeID);
                } else {
                  formik.setFieldValue("ProjectTypeID", null);
                }
              }}
              label={t("objPlt:objects.project.projecttype.name")}
              required={false}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.ProjectTypeName}
              touchedFunction={formik.touched.ProjectTypeID}
              errorFunction={formik.errors.ProjectTypeID}
              textValueFunction={
                !projectTypeIsLoading &&
                !projectTypeError &&
                formik.values.ProjectTypeID &&
                formik.values.ProjectTypeID > 0
                  ? projectTypesById[formik.values.ProjectTypeID]
                      ?.ProjectTypeName
                  : ""
              }
              autoPopulate={false}
            />
          </Grid>
        )}
        {ft_all_ProjectLead && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="ProjectLeadID"
              options={adminUsers}
              value={adminUsers.find((obj) => {
                return obj.UserAccountID === formik.values.ProjectLeadID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("ProjectLeadID", newValue.UserAccountID);
                } else {
                  formik.setFieldValue("ProjectLeadID", null);
                }
              }}
              label={t("objPlt:objects.project.fields.projectlead")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) =>
                option.FirstName + " " + option.Surname
              }
              errorFunction={formik.errors.ProjectLeadID}
              touchedFunction={formik.touched.ProjectLeadID}
              textValueFunction={
                usersById && usersById[formik.values.ProjectLeadID]
                  ? usersById[formik.values.ProjectLeadID].FirstName +
                    " " +
                    usersById[formik.values.ProjectLeadID].Surname
                  : ""
              }
            />
          </Grid>
        )}
        {hasRegionTypes && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="RegionID"
              options={regions}
              value={regions.find((obj) => {
                return obj.RegionID === formik.values.RegionID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("RegionID", newValue.RegionID);
                } else {
                  formik.setFieldValue("RegionID", null);
                }
              }}
              label={t("objPlt:objects.project.fields.region")}
              required={hasRegionTypes ? true : false}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.RegionName}
              touchedFunction={formik.touched.RegionID}
              errorFunction={formik.errors.RegionID}
              textValueFunction={
                regionsById[formik.values.RegionID]?.RegionName ?? ""
              }
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <IppTextField
            id="Location"
            label={t("objPlt:objects.project.fields.location")}
            value={formik.values.Location || ""}
            onChangeFunction={formik.handleChange}
            errorsExpression={formik.errors.Location}
            touchedExpression={formik.touched.Location}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <IppLocationAutoComplete
          formik={formik}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          showAddress={false}
          townCityOptions={{ show: false }}
          provinceStateOptions={{ show: true, required: true }}
          countryOptions={{ show: true, required: true }}
          postalCodeOptions={{ show: false }}
        />
        <Grid item xs={12}>
          <IppTextField
            id="ProjectDescription"
            label={t("objPlt:objects.project.fields.description")}
            required={true}
            value={formik.values.ProjectDescription}
            onChangeFunction={formik.handleChange}
            errorsExpression={formik.errors.ProjectDescription}
            touchedExpression={formik.touched.ProjectDescription}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            multiLine={true}
          />
        </Grid>
        {ft_all_Tag && tags.length > 0 && (
          <Grid item xs={12}>
            {!isEditing ? (
              <IppDisplayChip
                label={t("objPlt:objects.tag.name", {
                  count: projData.Tags ? projData.Tags.length : 1,
                })}
                value={projData.Tags}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                showEdit={showEdit}
              />
            ) : (
              <IppTokenMultiSelect
                id="Tags"
                label={t("objPlt:objects.tag.name_other", {
                  count: formik.values.Tags ? formik.values.Tags.length : 1,
                })}
                required={false}
                options={tags}
                noOptionsText={t("strGen:components.nooptionstext")}
                value={tagItems}
                setValue={(newValue: any) => {
                  setTagItems(newValue);
                  formik.setFieldValue("Tags", newValue);
                }}
                touchedFunction={formik.touched.Tags}
                errorFunction={formik.errors.Tags}
                isEditing={true}
                optionLabelFunction={(option: any) => option.Name}
                setIsEditing={null}
              />
            )}
          </Grid>
        )}
        {clientHasBenefits && (
          <>
            <IppFormDivider title="Targets" />
            {!isEditing ? (
              <>
                <Grid item xs={4}>
                  <IppDisplayField
                    label="Employees"
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit && currentProfile.IsClient}
                    value={ShowAsPercent(formik.values.EmployeeTarget)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <IppDisplayField
                    label="Hours"
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit && currentProfile.IsClient}
                    value={ShowAsPercent(formik.values.HoursTarget)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <IppDisplayField
                    label="Expenditures"
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit && currentProfile.IsClient}
                    value={ShowAsPercent(formik.values.ExpenditureTarget)}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={4}>
                  <IppTextField
                    id="EmployeeTarget"
                    label="Employees"
                    value={formik.values.EmployeeTarget}
                    onChangeFunction={formik.handleChange}
                    touchedExpression={formik.touched.EmployeeTarget}
                    errorsExpression={formik.errors.EmployeeTarget}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                  />
                </Grid>
                <Grid item xs={4}>
                  <IppTextField
                    id="HoursTarget"
                    label="Hours"
                    value={formik.values.HoursTarget}
                    onChangeFunction={formik.handleChange}
                    touchedExpression={formik.touched.HoursTarget}
                    errorsExpression={formik.errors.HoursTarget}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                  />
                </Grid>
                <Grid item xs={4}>
                  <IppTextField
                    id="ExpenditureTarget"
                    label="Expenditures"
                    value={formik.values.ExpenditureTarget}
                    onChangeFunction={formik.handleChange}
                    touchedExpression={formik.touched.ExpenditureTarget}
                    errorsExpression={formik.errors.ExpenditureTarget}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                  />
                </Grid>
              </>
            )}
          </>
        )}
        {clientHasStk && (
          <Grid item xs={12}>
            {formik.values.ProjectCode ? (
              <Typography>
                Note:
                <Divider />
                To save emails for this Project, enter the following address in
                the BCC field:
                <br />
                <b>
                  {formik.values.ProjectCode}.{clientName}
                  @comm.netbenefitsoftware.com
                </b>
              </Typography>
            ) : (
              <Typography>
                Note:
                <Divider />
                To save emails for this Project, set a Project Code.
              </Typography>
            )}
          </Grid>
        )}
        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <IppFormButtons
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            isAdding={isAdding}
            showDelete={true}
            deleteFunction={handleDelete}
            resetFunction={() => formik.resetForm()}
            fetchFunc={fetchDeleteChecksByProject}
          />
        </Grid>
      </Grid>
    </form>
  );

  let projectDetForm =
    clientIsLoading ||
    projectComponentIsLoading ||
    projectTypeIsLoading ||
    regionIsLoading ||
    tagIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Root>
        <Box display="flex" justifyContent="center">
          <Paper className={classes.boxSpace}>
            <Box p={1}>
              <Grid container spacing={1} className={classes.editForm}>
                {editForm}
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Root>
    ) : (
      <Root>
        <Box display="flex" justifyContent="center">
          <Grid container spacing={1}>
            <Grid item xs={customBP ? 12 : 5}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper className={classes.boxSpace}>{editForm}</Paper>
                </Grid>
                {clientHasBenefits && (
                  <ProjectComponentForm
                    projData={projData}
                    isAdding={isAdding}
                    isEditing={isEditing}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item xs={customBP ? 12 : 7}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper>
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={activeTab}
                          onChange={handleTabSelect}
                          variant="scrollable"
                          scrollButtons
                          allowScrollButtonsMobile
                        >
                          {clientHasBenefits && !ft_ben_Contract && (
                            <Tab
                              value={0}
                              label={`${t(
                                "objBen:objects.company.name_other"
                              )} (${companyProjects.length})`}
                            />
                          )}
                          <Tab
                            value={!ft_ben_Contract ? 1 : 0}
                            label={`${t(
                              "objPlt:objects.project.attachments"
                            )} (${attachCount.Count})`}
                          />
                        </Tabs>
                      </Box>
                      {clientHasBenefits && !ft_ben_Contract && (
                        <IppTabPanel value={activeTab} index={0}>
                          <Box>
                            <IppCompanyProjectInventory
                              title={t(
                                "objBen:objects.company.activefornotifications"
                              )}
                              fetchID={projData.ProjectID}
                              showField="CompanyName"
                              showFieldDescriptor="Company"
                              projectName={formik.values.ProjectName}
                            />
                          </Box>
                        </IppTabPanel>
                      )}
                      <IppTabPanel
                        value={activeTab}
                        index={!ft_ben_Contract ? 1 : 0}
                      >
                        <Box>
                          <IppAttachmentInventory
                            recordType="Project"
                            itemID={projData.ProjectID}
                            companyID={0}
                            projectID={formik.values.ProjectID}
                            moduleID={1}
                            title={t("objPlt:objects.project.attachments")}
                            categoryRecordType="Project"
                          />
                        </Box>
                      </IppTabPanel>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Root>
    );

  return projectDetForm;
};
