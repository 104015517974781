import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { Prompt } from "react-router";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { IppFormDivider } from "components/IppFormDivider";
import { IppTextField } from "components/IppTextField";
import { updClient } from "features/client/ClientSlice";
import { IppSubmitButton } from "components/Buttons/IppSubmitButton";
import { useTypedTranslation } from "utils/customHooks";

export const BrandingForm = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["strGen"]);

  const { clientId } = useSelector((state: RootState) => state.client);

  const {
    clientLogo,
    clientName,
    clientSearchString,
    headerColor,
    primaryColor,
    secondaryColor,
    privacyPolicy,
  } = useSelector((state: RootState) => state.client);

  const validationSchema = yup.object({
    ClientName: yup
      .string()
      .required("Client Name is required")
      .max(50, "Client Name should be less than 50 characters"),
    HeaderColor: yup
      .string()
      .optional()
      //.trim()
      .matches(
        /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
        "Value must be in hex color format (ie '#faf000)"
      ),
    PrimaryColor: yup
      .string()
      .optional()
      //.trim()
      .matches(
        /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
        "Value must be in hex color format (ie '#faf000)"
      ),
    SecondaryColor: yup
      .string()
      .optional()
      //.trim()
      .matches(
        /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
        "Value must be in hex color format (ie '#faf000)"
      ),
  });

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(updClient(accessToken, clientId, values, false));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let formData = {
    ClientName: clientName,
    ClientSearchString: clientSearchString || "",
    HeaderColor: headerColor || "",
    PrimaryColor: primaryColor || "",
    SecondaryColor: secondaryColor || "",
    ClientLogo: clientLogo || "",
    PrivacyPolicy: privacyPolicy || "",
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: onSub,
  });

  const editForm = (
    <div>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message="You have unsaved changes; are you sure you want to leave this page?"
        />
      )}

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h1">
              Client Branding
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              This form is used to add or update custom branding for your
              application on the NetBenefit platform. This will change the
              appearance of all modules of the application for all your users.
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2">
                      <strong>Header Color:</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      This will change the background color of the Header Menu.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2">
                      <strong>Primary Color:</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      This will change the color of Form Headers, the Chips for
                      Token fields, and the Toggle buttons on the Feature
                      Toggles page.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2">
                      <strong>Secondary Color:</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      This will change the color of most buttons on the
                      Platform.
                    </Typography>
                    <Typography variant="body2">
                      (Note: The color of all Delete buttons will remain Red).
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <IppTextField
              id="PrivacyPolicy"
              value={formik.values.PrivacyPolicy}
              label="Privacy Policy URL"
              onChangeFunction={formik.handleChange}
              isEditing={true}
              setIsEditing={null}
              errorsExpression={formik.errors.PrivacyPolicy}
              touchedExpression={formik.touched.PrivacyPolicy}
            />
            <Typography
              variant="caption"
              //sx={{ color: "text.secondary" }}
              color="secondary"
            >
              Please provide a URL for your Privacy Policy. If this field is
              left empty, the platform's default Privacy Policy will be used.
            </Typography>
          </Grid>
          {/*  <Grid item xs={12}>
            <IppTextField
              id="ClientName"
              value={formik.values.ClientName}
              label="Client Display Name"
              onChangeFunction={formik.handleChange}
              isEditing={true}
              setIsEditing={null}
              errorsExpression={formik.errors.ClientName}
              touchedExpression={formik.touched.ClientName}
            />
          </Grid> */}
          <IppFormDivider title="Site Colors" />
          <Grid item xs={12}>
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              Color values should be in 6-character hex color format, preceded
              by the # symbol. For example,{" "}
              <span style={{ color: "#ff0000" }}>
                <strong>#ff0000</strong>
                (red)
              </span>
              ,{" "}
              <span style={{ color: "#006600" }}>
                <strong>#006600</strong> (green)
              </span>
              ,{" "}
              <span style={{ color: "#0000cc" }}>
                <strong>#0000cc</strong> (blue)
              </span>
              .
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <IppTextField
              id="HeaderColor"
              value={formik.values.HeaderColor}
              label="Header Color (hex)"
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue(
                  "HeaderColor",
                  newValue.target.value.trim()
                );
              }}
              isEditing={true}
              setIsEditing={null}
              errorsExpression={formik.errors.HeaderColor}
              touchedExpression={formik.touched.HeaderColor}
            />
          </Grid>
          <Grid item xs={4}>
            <IppTextField
              id="PrimaryColor"
              value={formik.values.PrimaryColor}
              label="Primary Color (hex)"
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue(
                  "PrimaryColor",
                  newValue.target.value.trim()
                );
              }}
              isEditing={true}
              setIsEditing={null}
              errorsExpression={formik.errors.PrimaryColor}
              touchedExpression={formik.touched.PrimaryColor}
            />
          </Grid>
          <Grid item xs={4}>
            <IppTextField
              id="SecondaryColor"
              value={formik.values.SecondaryColor}
              label="Secondary Color (hex)"
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue(
                  "SecondaryColor",
                  newValue.target.value.trim()
                );
              }}
              isEditing={true}
              setIsEditing={null}
              errorsExpression={formik.errors.SecondaryColor}
              touchedExpression={formik.touched.SecondaryColor}
            />
          </Grid>
          <Grid item xs={12}>
            <IppTextField
              id="ClientSearchString"
              value={formik.values.ClientSearchString}
              label="Client Search String"
              onChangeFunction={formik.handleChange}
              isEditing={true}
              setIsEditing={null}
              errorsExpression={formik.errors.ClientSearchString}
              touchedExpression={formik.touched.ClientSearchString}
            />
            <Typography
              variant="caption"
              //sx={{ color: "text.secondary" }}
              color="secondary"
            >
              Search string can contain multiple words or phrases, separated by
              commas. Use double quotes ("example phrase") around phrases to
              limit results to the entire phrase. *Including multiple words will
              result in articles that contain ALL search terms, NOT articles
              exclusive to each.*
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <IppSubmitButton
              buttonText={t("strGen:buttons.saveobjchanges", {
                objectname: t("strGen:pages.branding.name"),
              })}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );

  return <div>{editForm}</div>;
};
