import { IppFormDialog } from "components/IppFormDialog";
import { AttachmentCategoryFormFields } from "./AttachmentCategoryFormFields";
import {
  AttachmentCategoryExtended,
  AttachmentCategoryFormValues,
} from "api/attachmentCategoryAPI";
import {
  addAttachmentCategory,
  updAttachmentCategory,
} from "./AttachmentCategorySlice";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useTypedTranslation } from "utils/customHooks";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { AttachmentCategoryValidationSchema } from "./AttachmentCategoryValidationSchema";

interface AttachmentCategoryFormProps {
  setEditOpen: (value: boolean) => void;
  setEditID: (value: number | undefined) => void;
  // attachmentCategories: AttachmentCategoryExtended[];
  attachmentCategoryData: AttachmentCategoryExtended | undefined;
}

export const AttachmentCategoryForm = (props: AttachmentCategoryFormProps) => {
  const {
    setEditOpen,
    setEditID,
    // attachmentCategories,
    attachmentCategoryData,
  } = props;

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const attachmentCategoryID =
    attachmentCategoryData?.AttachmentCategoryID ?? undefined;

  const [isAdding, setIsAdding] = useState(!attachmentCategoryID);

  const handleEditClose = () => {
    setEditOpen(false);
    if (attachmentCategoryID) setEditID(undefined);
    if (isAdding) setIsAdding(false);
  };

  const onSub = (values: AttachmentCategoryFormValues) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (attachmentCategoryID) {
          dispatch(
            updAttachmentCategory(accessToken, attachmentCategoryID, values)
          );
        } else {
          dispatch(addAttachmentCategory(accessToken, values));
        }
        handleEditClose();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const initialValues = {
    AttachmentCategoryName:
      attachmentCategoryData?.AttachmentCategoryName ?? "",
    RecordTypes: attachmentCategoryData?.RecordTypes ?? [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: AttachmentCategoryValidationSchema({
      attachmentCategoryID: attachmentCategoryData?.AttachmentCategoryID,
    }),
    onSubmit: onSub,
    enableReinitialize: true,
  });

  const editForm = (
    <IppFormDialog
      open={true}
      onClose={handleEditClose}
      title={
        isAdding
          ? t("strGen:prompts.add.addnewtitle", {
              fieldname: t("objPlt:objects.attachmentcategories.name"),
            })
          : t("strGen:prompts.edit.edittitle", {
              fieldname: t("objPlt:objects.attachmentcategories.name"),
            })
      }
      formik={formik}
      isAdding={isAdding}
      showCancel={true}
    >
      <AttachmentCategoryFormFields formik={formik} />
    </IppFormDialog>
  );

  return <div>{editForm}</div>;
};
