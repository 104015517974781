import { RecordTypeName } from "utils/types/General.types";
import axiosClient from ".././axiosClient";

// can be extended to be a union of all subset names
export type SubsetName = "attachmentCategory";

export const getRecordTypes = async (
  accessToken: string,
  subsetName?: SubsetName
): Promise<RecordTypeName[]> => {
  const url = `/recordTypes${subsetName ? `/${subsetName}` : ""}`;

  try {
    const { data } = await axiosClient.get<{ [key: string]: RecordTypeName[] }>(
      url,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // Extract the array from the response
    return Object.values(data).flat();
  } catch (err: any) {
    throw err;
  }
};
