import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { IppFormButtons } from "components/Buttons/IppFormButtons";

interface IppFormDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  formik: any;
  isAdding: boolean;
  showCancel?: boolean;
  children: React.ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
}

export const IppFormDialog = (props: IppFormDialogProps) => {
  const {
    open,
    onClose,
    title,
    formik,
    isAdding,
    showCancel,
    children,
    maxWidth = "md",
  } = props;

  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            {children}
            <Grid item xs={12}>
              <IppFormButtons
                isEditing={true}
                setIsEditing={null}
                isAdding={isAdding}
                showCancel={showCancel}
                resetFunction={onClose}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
