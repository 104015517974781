import { Box, Dialog, DialogContent, Grid } from "@mui/material";
import { IppAttachmentInventory } from "./IppAttachmentInventory";
import { useTypedTranslation } from "utils/customHooks";
import { AttachmentCategoryRecordTypeName } from "utils/types/index.types";

interface IppAttachmentModalProps {
  attachmentModalOpen: boolean;
  handleAttachmentClose: () => void;
  recordType: string;
  itemID: number;
  companyID: number;
  projectID: number;
  moduleID: number;
  disabled: boolean;
  objectName?: string;
  categoryRecordType: AttachmentCategoryRecordTypeName;
}

export const IppAttachmentModal = ({
  attachmentModalOpen,
  handleAttachmentClose,
  recordType,
  itemID,
  companyID,
  projectID,
  moduleID,
  disabled,
  objectName,
  categoryRecordType,
}: IppAttachmentModalProps) => {
  const t = useTypedTranslation(["objPlt", "strGen"]);

  return (
    <Dialog
      open={attachmentModalOpen}
      onClose={handleAttachmentClose}
      maxWidth="md"
    >
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box>
              <IppAttachmentInventory
                recordType={recordType}
                itemID={itemID}
                companyID={companyID}
                projectID={projectID}
                moduleID={moduleID}
                disabled={disabled}
                title={t("objPlt:objects.attachment.manageattachments")}
                isModalView={true}
                handleModalClose={handleAttachmentClose}
                objectName={objectName}
                categoryRecordType={categoryRecordType}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
