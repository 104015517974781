import axiosClient from "./axiosClient";

export interface EditableFeatureToggles {
  FT_ENG_LvlOfEngagement: boolean;
  FT_ENG_Comment: boolean;
  FT_ENG_Grievance: boolean;
  FT_BEN_Contract: boolean;
  FT_ALL_ExternalPersonRes: boolean;
  FT_ENG_Sentiment: boolean;
  FT_ENG_InternalNotes: boolean;
  FT_ENG_PILSD: boolean;
  FT_COMENG_CommitmentInteraction: boolean;
  FT_COM_Phase: boolean;
  FT_ENG_Phase: boolean;
  FT_ALL_ProjectTypes: boolean;
  FT_BEN_AltWorkHours2: boolean;
  FT_BEN_AltWorkHours3: boolean;
  FT_ALL_Tag: boolean;
  FT_BEN_Spend: boolean;
  FT_ENG_BCER: boolean;
  FT_BEN_TempEmployee: boolean;
  FT_BEN_JobPosition: boolean;
  FT_BEN_JobHistory: boolean;
  FT_BEN_DiversityDeclined: boolean;
  FT_BEN_Region: boolean;
  FT_BEN_RND: boolean;
  FT_BEN_Asset: boolean;
  FT_BEN_CommunityInvestment: boolean;
  FT_BEN_AllocatedDate: boolean;
  FT_BEN_BusinessCategory: boolean;
  FT_ALL_ProjectLead: boolean;
  FT_COM_Priority: boolean;
  FT_COM_DateApplicable: boolean;
  FT_COM_InternalNotes: boolean;
  FT_BEN_SpendReport: boolean;
  FT_BEN_CustomJobPosition: boolean;
  FT_ENG_IntenalCommunication: boolean;
  FT_BEN_WorkForce: boolean;
  FT_BEN_Ethnicity: boolean;
  FT_ENG_PreferredCommunicationMethod: boolean;
  FT_BEN_CustomEmpCategory: boolean;
  FT_BEN_EmployeePostalCode: boolean;
  FT_COM_ShowCommitmentHierarchy: boolean;
  FT_BEN_WageAmount: boolean;
  FT_COM_AssociatedGroups: boolean;
  FT_COM_AssociatedContacts: boolean;
  FT_ENG_ShowProjectCode: boolean;
  FT_COM_ShowReference: boolean;
  FT_BEN_HideIndigenousFromContractor: boolean;
  FT_BEN_HideDiversityFromContractor: boolean;
  FT_BEN_HideCategoryFromContractor: boolean;
  FT_BEN_HideRegionTierFromContractor: boolean;
}

// These toggles are not surfaced to Users and can only be toggled by the System User
export interface AllFeatureToggles extends EditableFeatureToggles {
  FT_ENG_Importance: boolean;
  FT_ENG_RelOwner: boolean;
  FT_ENG_GroupIssue: boolean;
  FT_ENG_ContactIssue: boolean;
  FT_BEN_Tier: boolean;
  FT_BEN_BenefitsMenu: boolean;
  FT_BEN_CustomRnD: boolean;
  FT_BEN_ActivityLocation: boolean;
  FT_ENG_Thread: boolean;
  FT_ENG_ThreadQuickAdd: boolean;
  FT_BEN_WorkHourSubContractor: boolean;
}

export interface TriggeredNotifications {
  TN_CompanyReject: boolean;
  TN_CompanyAccept: boolean;
  TN_PaymentPeriodReject: boolean;
  TN_PaymentPeriodAccepted: boolean;
  TN_PersonResponsibleAssigned: boolean;
}

export interface DiversityMonitoring {
  DM_WomanOwned: boolean;
  DM_Disability: boolean;
  DM_VisibleMinority: boolean;
  DM_LGBTQ: boolean;
  DM_Local: boolean;
  DM_VeteranOwned: boolean;
  DM_NorthernResident: boolean;
}

export interface Client
  extends AllFeatureToggles,
    TriggeredNotifications,
    DiversityMonitoring {
  ClientID: number;
  ClientName: string;
  ClientLogo: string;
  HeaderColor: string;
  PrimaryColor: string;
  SecondaryColor: string;
  ClientSearchString: string;
  LogoPath: string;
  LogoIsUploaded: boolean;
  CustomTrainingUrl: string;
  AuthOrgID: string;
  FT_DEV: boolean;
  FT_BEN_SpendReportDate: boolean; // Remove if toggle gets surfaced
  FT_ENG_Thread: boolean; // Remove if toggle gets surfaced
  AiEnabled: boolean;
  PrivacyPolicy: string;
}

export interface ClientResult {
  client: Client;
}

export async function getClientId(
  accessToken: String,
  name: String
): Promise<Client> {
  const url = `/client/${name}`;

  try {
    const { data } = await axiosClient.get<Client>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClient(
  accessToken: String,
  clientID: number,
  newClient: Partial<Client>
): Promise<Client> {
  const url = `/client`;
  try {
    const { data } = await axiosClient.post<Client>(url, newClient, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientLogo(
  accessToken: String,
  clientID: number,
  newClient: Partial<Client>
): Promise<Client> {
  const url = `/client/logo`;

  try {
    const { data } = await axiosClient.post<Client>(url, newClient, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientFeatureToggles(
  accessToken: String,
  updToggles: Partial<AllFeatureToggles>
): Promise<Client> {
  const url = `/client/featureToggles`;

  try {
    const { data } = await axiosClient.post<Client>(url, updToggles, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientDiversity(
  accessToken: String,
  clientID: number,
  newClient: Partial<Client>
): Promise<Client> {
  const url = `/client/diversityMonitoring`;

  try {
    const { data } = await axiosClient.post<Client>(url, newClient, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientTrigeredNotifs(
  accessToken: String,
  newClient: Partial<Client>
): Promise<Client> {
  const url = `/client/triggeredNotifications`;

  try {
    const { data } = await axiosClient.post<Client>(url, newClient, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

//There is currently no way to access Auth0 Login APIs without this ID
//Until they provide a way to pass the Org Name instead, we will use this endpoint
//Unauthenticed endpoint to get AuthOrgID from the client shortname
export async function getClientAuthOrgId(): Promise<string> {
  const url = `/orglookup`;

  try {
    const { data } = await axiosClient.get<string>(url);

    return data;
  } catch (err: any) {
    throw err;
  }
}

//There is currently no way to redirect from Auth0 after password changes
//Until they provide a way to pass a redirect, we will use this
//Unauthenticed endpoint to get Client Shortname from AuthOrgID
export async function getClientShortnameFromAuthOrgId(
  orgID: string
): Promise<string> {
  const basePath = process.env.REACT_APP_API;
  const url = `${basePath}/reverseorglookup/${orgID}`;

  try {
    const { data } = await axiosClient.get<string>(url);

    return data;
  } catch (err: any) {
    throw err;
  }
}

//This is used to look up a Shortname if a custom domain is being used
//Unauthenticed endpoint to get Client Shortname for Custom Domain
export async function getClientShortnameFromCustomDomain(): Promise<string> {
  const basePath = process.env.REACT_APP_API;
  const url = `${basePath}/customDomainLookup`;

  try {
    const { data } = await axiosClient.get<string>(url);

    return data;
  } catch (err: any) {
    throw err;
  }
}
