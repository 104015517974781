import { useAuth0 } from "@auth0/auth0-react";
import { Alert, AlertTitle, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { RootState } from "app/rootReducer";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingIndicator from "./LoadingIndicator";
import { IppCancelButton } from "./Buttons/IppCancelButton";
import { IppDeleteButton } from "./Buttons/IppDeleteButton";
import { useTypedTranslation } from "utils/customHooks";
import { IppButton } from "./Buttons/IppButton";
import { IppCompleteButton } from "./Buttons/IppCompleteButton";

interface ippConfirmDialogProps {
  title: string;
  htmlDetails?: string;
  open: boolean;
  setOpen: any;
  onConfirm: any;
  info?: any;
  fetchFunc?: any;
  recordID?: number;
  content?: any; // alternative dialog content
  confirmText?: string; // alternative confirm text
  deleteText?: string; // alternative delete text
  deleteWarning?: boolean;
}

const IppConfirmDialog = (props: ippConfirmDialogProps) => {
  const {
    title,
    htmlDetails,
    open,
    setOpen,
    onConfirm,
    fetchFunc,
    info,
    recordID,
    content = "",
    confirmText = "",
    deleteText = "",
    deleteWarning = false,
  } = props;
  const dispatch = useDispatch();
  const t = useTypedTranslation(["strGen"]);
  const { getAccessTokenSilently } = useAuth0();

  const pathArray = window.location.pathname.split("/");
  const ItemID = recordID
    ? recordID
    : (pathArray[pathArray.length - 1] as unknown as number);

  const { deleteChecksArray: deleteChecks, isLoading } = useSelector(
    (state: RootState) => state.deleteChecks
  );

  let disDel = false;
  let display = t("strGen:components.ippconfirmdialog.nothinghereyet");
  let displays = [""];
  let disList;

  if (deleteChecks) {
    const Record = deleteChecks.find((d: any) => d.ItemID == ItemID);

    if (Record) {
      disDel = Record.HasChildren;
      display = Record.ChildDetails;
      displays = Record.ChildDetails.split(",");
      disList = displays.map((e) => {
        return <p key={e}>{e}</p>;
      });
    } else disDel = false;
  }

  useEffect(() => {
    (async () => {
      try {
        if (open && fetchFunc) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchFunc(accessToken, ItemID));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {content ? (
            <DialogContent>{content}</DialogContent>
          ) : (
            <DialogContent>
              {htmlDetails && (
                <Box
                  sx={{ display: "flex", justifyContent: "center", padding: 2 }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: htmlDetails || "" }}
                  />
                </Box>
              )}
              {!disDel &&
                deleteText &&
                (deleteWarning ? (
                  <Alert severity="warning">
                    <AlertTitle>{deleteText}</AlertTitle>
                  </Alert>
                ) : (
                  <div>{deleteText}</div>
                ))}
              {!disDel &&
                !deleteText &&
                t("strGen:components.ippconfirmdialog.confirmdelete")}
              {disDel && (
                <div>
                  <b>{t("strGen:components.ippconfirmdialog.cannotdelete")}</b>
                  <ul>{disList}</ul>
                  {info && (
                    <Alert severity="warning">
                      <AlertTitle>
                        {t("strGen:components.ippconfirmdialog.trythis")}
                      </AlertTitle>
                      {info}
                    </Alert>
                  )}
                </div>
              )}
            </DialogContent>
          )}
          <DialogActions>
            <IppCancelButton onClick={() => setOpen(false)} />
            {confirmText === "Complete" ? (
              <IppCompleteButton
                onClick={() => {
                  onConfirm();
                  setOpen(false);
                }}
                buttonText="Complete"
              />
            ) : confirmText ? (
              <IppButton
                onClick={() => {
                  onConfirm();
                }}
              >
                {confirmText}
              </IppButton>
            ) : (
              <IppDeleteButton
                onClick={() => {
                  setOpen(false);
                  onConfirm();
                }}
                disabled={disDel}
              />
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default IppConfirmDialog;
