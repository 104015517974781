import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AttachmentCategoriesExtendedResult,
  AttachmentCategoriesResult,
  AttachmentCategory,
  AttachmentCategoryExtended,
  AttachmentCategoryFormValues,
  createAttachmentCategory,
  deleteAttachmentCategory,
  getAttachmentCategories,
  getAttachmentCategoriesByRecordType,
  updateAttachmentCategory,
} from "api/attachmentCategoryAPI";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { AttachmentCategoryRecordTypeName } from "utils/types/index.types";
import * as Constants from "utils/snackBarConstants";

interface AttachmentCategoryState {
  attachmentCategoriesById: Record<
    number,
    AttachmentCategory | AttachmentCategoryExtended
  >;
  attachmentCategoryList: number[];
  isLoading: boolean;
  error: string | null;
}

const AttachmentCategoryInitialState: AttachmentCategoryState = {
  attachmentCategoriesById: {},
  attachmentCategoryList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: AttachmentCategoryState) {
  state.isLoading = true;
}

function loadingFailed(
  state: AttachmentCategoryState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const attachmentCategories = createSlice({
  name: "attachmentCategories",
  initialState: AttachmentCategoryInitialState,
  reducers: {
    getAttachmentCategoriesStart: startLoading,
    getAttachmentCategoriesSuccess(
      state,
      {
        payload,
      }: PayloadAction<
        AttachmentCategoriesResult | AttachmentCategoriesExtendedResult
      >
    ) {
      const { attachmentCategories } = payload;
      state.isLoading = false;
      state.error = null;

      attachmentCategories.forEach((attachmentCategory) => {
        state.attachmentCategoriesById[
          attachmentCategory.AttachmentCategoryID
        ] = attachmentCategory;
      });

      state.attachmentCategoryList = attachmentCategories.map(
        (attachmentCategory) => attachmentCategory.AttachmentCategoryID
      );
    },
    getAttachmentCategoriesFailed: loadingFailed,
    createAttachmentCategoryStart: startLoading,
    createAttachmentCategorySuccess(
      state,
      { payload }: PayloadAction<AttachmentCategoryExtended>
    ) {
      state.isLoading = false;
      state.error = null;
      state.attachmentCategoriesById[payload.AttachmentCategoryID] = payload;
      state.attachmentCategoryList.push(payload.AttachmentCategoryID);
    },
    createAttachmentCategoryFailed: loadingFailed,
    updateAttachmentCategoryStart: startLoading,
    updateAttachmentCategorySuccess(
      state,
      { payload }: PayloadAction<AttachmentCategoryExtended>
    ) {
      state.isLoading = false;
      state.error = null;

      // Perform immutable state update
      state.attachmentCategoriesById = {
        ...state.attachmentCategoriesById,
        [payload.AttachmentCategoryID]: {
          ...state.attachmentCategoriesById[payload.AttachmentCategoryID],
          ...payload,
        },
      };
    },
    updateAttachmentCategoryFailed: loadingFailed,
    deleteAttachmentCategoryStart: startLoading,
    deleteAttachmentCategorySuccess(state, { payload }: PayloadAction<number>) {
      state.isLoading = false;
      state.error = null;
      delete state.attachmentCategoriesById[payload];
      state.attachmentCategoryList = state.attachmentCategoryList.filter(
        (id) => id !== payload
      );
    },
    deleteAttachmentCategoryFailed: loadingFailed,
  },
});

export const {
  getAttachmentCategoriesStart,
  getAttachmentCategoriesSuccess,
  getAttachmentCategoriesFailed,
  createAttachmentCategoryStart,
  createAttachmentCategorySuccess,
  createAttachmentCategoryFailed,
  updateAttachmentCategoryStart,
  updateAttachmentCategorySuccess,
  updateAttachmentCategoryFailed,
  deleteAttachmentCategoryStart,
  deleteAttachmentCategorySuccess,
  deleteAttachmentCategoryFailed,
} = attachmentCategories.actions;

export default attachmentCategories.reducer;

export const fetchAttachmentCategories =
  (accessToken: string) => async (dispatch: any) => {
    try {
      dispatch(getAttachmentCategoriesStart());
      const data = await getAttachmentCategories(accessToken);
      dispatch(getAttachmentCategoriesSuccess(data));
    } catch (err: any) {
      dispatch(getAttachmentCategoriesFailed(err.message));
    }
  };

export const fetchAttachmentCategoriesByRecordType =
  (accessToken: string, recordType: AttachmentCategoryRecordTypeName) =>
  async (dispatch: any) => {
    try {
      dispatch(getAttachmentCategoriesStart());
      const data = await getAttachmentCategoriesByRecordType(
        accessToken,
        recordType
      );
      dispatch(getAttachmentCategoriesSuccess(data));
    } catch (err: any) {
      dispatch(getAttachmentCategoriesFailed(err.message));
    }
  };

export const addAttachmentCategory =
  (accessToken: string, attachmentCategoryData: AttachmentCategoryFormValues) =>
  async (dispatch: any) => {
    try {
      dispatch(createAttachmentCategoryStart());
      const data = await createAttachmentCategory(
        accessToken,
        attachmentCategoryData
      );
      dispatch(createAttachmentCategorySuccess(data));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createAttachmentCategoryFailed(err.message));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updAttachmentCategory =
  (
    accessToken: string,
    attachmentCategoryID: number,
    attachmentCategoryData: AttachmentCategoryFormValues
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(updateAttachmentCategoryStart());
      const data = await updateAttachmentCategory(
        accessToken,
        attachmentCategoryID,
        attachmentCategoryData
      );

      dispatch(updateAttachmentCategorySuccess(data));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(updateAttachmentCategoryFailed(err.message));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delAttachmentCategory =
  (accessToken: string, attachmentCategoryID: number) =>
  async (dispatch: any) => {
    try {
      dispatch(deleteAttachmentCategoryStart());
      await deleteAttachmentCategory(accessToken, attachmentCategoryID);
      dispatch(deleteAttachmentCategorySuccess(attachmentCategoryID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(deleteAttachmentCategoryFailed(err.message));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
