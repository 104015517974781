import { AttachmentCategoryRecordTypeName } from "utils/types/index.types";
import axiosClient from "./axiosClient";

export interface AttachmentCategory {
  AttachmentCategoryID: number;
  ClientID: number;
  AttachmentCategoryName: string;
}

export interface AttachmentCategoryExtended extends AttachmentCategory {
  RecordTypes: AttachmentCategoryRecordTypeName[];
}

export type AttachmentCategoryFormValues = Omit<
  AttachmentCategoryExtended,
  "AttachmentCategoryID" | "ClientID"
>;

export interface AttachmentCategoriesResult {
  attachmentCategories: AttachmentCategory[];
}

export interface AttachmentCategoriesExtendedResult {
  attachmentCategories: AttachmentCategoryExtended[];
}

export const getAttachmentCategories = async (
  accessToken: string
): Promise<AttachmentCategoriesExtendedResult> => {
  const url = `/attachmentCategory`;

  try {
    const { data } = await axiosClient.get<AttachmentCategoriesExtendedResult>(
      url,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const getAttachmentCategoriesByRecordType = async (
  accessToken: string,
  recordType: AttachmentCategoryRecordTypeName
): Promise<AttachmentCategoriesResult> => {
  const url = `/attachmentCategory/recordType/${recordType}`;

  try {
    const { data } = await axiosClient.get<AttachmentCategoriesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const createAttachmentCategory = async (
  accessToken: string,
  attachmentCategoryData: AttachmentCategoryFormValues
): Promise<AttachmentCategoryExtended> => {
  const url = `/attachmentCategory`;

  const { RecordTypes, ...newAttachmentCategory } = attachmentCategoryData;

  try {
    const { data } = await axiosClient.post<AttachmentCategoryExtended>(
      url,
      {
        attachmentCategoryData: newAttachmentCategory,
        recordTypes: RecordTypes,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const updateAttachmentCategory = async (
  accessToken: string,
  attachmentCategoryID: number,
  attachmentCategoryData: AttachmentCategoryFormValues
): Promise<AttachmentCategoryExtended> => {
  const url = `/attachmentCategory/${attachmentCategoryID}`;

  const { RecordTypes, ...updAttachmentCategory } = attachmentCategoryData;

  try {
    const { data } = await axiosClient.post<AttachmentCategoryExtended>(
      url,
      {
        attachmentCategoryData: updAttachmentCategory,
        recordTypes: RecordTypes,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const deleteAttachmentCategory = async (
  accessToken: string,
  attachmentCategoryID: number
): Promise<string> => {
  const url = `/attachmentCategory/${attachmentCategoryID}`;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};
