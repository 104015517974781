import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { ChangeEvent, useEffect, useState } from "react";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addInteraction,
  fetchInteractions,
  updInteraction,
} from "./InteractionSlice";
import { Grid, Typography } from "@mui/material";
import { IppTextField } from "components/IppTextField";
import { Contact } from "api/stakeholder/contactAPI";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppTokenAutocomplete } from "components/IppTokenAutoComplete";
import { IppContactAutoComplete } from "components/IppContactAutoComplete";
import { fetchStatusTypes } from "features/datalists/StatusTypeSlice";
import { ConvertDateOffset, GetJSDate } from "utils/DateFunctions";
import { fetchProjects } from "features/project/ProjectSlice";
import { fetchInteractionTypes } from "features/datalists/InteractionTypeSlice";
import { fetchClientInteractionTypes } from "features/platform/admin/clientInteractionTypes/ClientInteractionTypeSlice";
import { fetchIssues } from "../issue/IssueSlice";
import { fetchContacts } from "../../platform/contacts/ContactSlice";
import { fetchStakeholderGroups } from "../../platform/groups/StakeholderGroupSlice";
import { fetchUsers } from "features/users/UsersSlice";
import InitiatedByValues from "components/InitiatedByValues";
import { IppDatePicker } from "components/IppDatePicker";
import { IppPersonResponsible } from "components/IppPersonResponsible";
import { IppMultiTextField } from "components/IppMultiTextField";
import LoadingIndicator from "components/LoadingIndicator";
import {
  useRoleChecks,
  useSnackBarConstants,
  useTypedTranslation,
} from "utils/customHooks";
import { fetchPhases } from "features/platform/admin/phase/PhaseSlice";
import { fetchInteractionPhasesByInteraction } from "../interactionPhase/InteractionPhaseSlice";
import { IppTokenMultiSelect } from "components/IppTokenMultiSelect";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { InteractionValidationSchema } from "./InteractionValidationSchema";
import { IppCheckbox } from "components/IppCheckbox";
import { generateThreadSummaryWithAi, Thread } from "api/stakeholder/threadAPI";
import { fetchThreads, updThread } from "../thread/ThreadSlice";
import { fetchChildTagsByRecordType } from "features/platform/admin/tag/TagSlice";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { IppTokenTable } from "components/IppTokenTable";
import { IppDisplayRichText } from "components/IppDisplayRichText";
import { generateInteractionDetailsWithAi } from "api/stakeholder/interactionAPI";
import { IppGenerateSummaryDialog } from "components/IppGenerateSummaryDialog";
import { IppGenerateSummaryButton } from "components/Buttons/IppGenerateSummaryButton";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import * as Constants from "utils/snackBarConstants";

interface interactionChildFormProps {
  closeAction: any; // action to close dialog
  setDidSaveInventory: any;
  parentTitle: string; // name of field the form is called from
  parentValue: any; // if called from child grid, init parent value
  parentID?: any;
  interaction?: any; // existing interaction to be edited
}

export const InteractionChildForm = (props: interactionChildFormProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const snackbarConstants = useSnackBarConstants();
  const [generateDialogOpen, setGenerateDialogOpen] = useState(false);
  const [detailsKey, setDetailsKey] = useState(0);
  const [generateObjectType, setGenerateObjectType] = useState<
    "thread" | "interaction"
  >("interaction");
  const [threadData, setThreadData] = useState<any>(null);

  const {
    closeAction,
    setDidSaveInventory,
    parentTitle,
    parentValue,
    parentID,
    interaction,
  } = props;

  let currentInteraction = interaction || {};

  const { interactionList, interactionsById } = useSelector(
    (state: RootState) => state.interactions
  );

  if (parentTitle === t("objCom:objects.commitment.name")) {
    const currentInteractionResult = interactionList
      .map((id) => interactionsById[id])
      .filter((int) => int.InteractionID === interaction.InteractionID);

    currentInteraction = currentInteractionResult[0];
  }

  const [listGroups, setListGroups] = useState<Array<string>>([]);
  const [listIssues, setListIssues] = useState<Array<string>>([]);
  const [listGrievances, setListGrievances] = useState<Array<number>>([]);
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [threadTokenOptions, setThreadTokenOptions] = useState<Thread[]>([]);
  const [isEditing, setIsEditing] = useState(true);
  const [canSave, setCanSave] = useState(false);

  const {
    clientId,
    aiEnabled,
    ft_eng_PILSD,
    ft_eng_InternalNotes,
    ft_eng_Phase,
    ft_eng_IntenalCommunication,
    ft_eng_Thread,
    ft_all_Tag,
    ft_eng_ThreadQuickAdd,
  } = useSelector((state: RootState) => state.client);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  let thisDate = new Date();

  let itemData = props.interaction || {};
  let presetProjectID = ["Thread"].includes(parentTitle)
    ? parentValue?.ProjectID
    : -1;

  const [tagItems, setTagItems] = useState(
    itemData.InteractionID && itemData.Tags ? itemData.Tags : undefined
  );

  if (Object.keys(currentInteraction).length > 0) {
    itemData = currentInteraction;
  } else {
    itemData = {
      Details: "",
      InteractionDate: thisDate.toISOString().slice(0, 10),
      InteractionTitle: "",
      InteractionTypeID: -1,
      ProjectID: presetProjectID,
      StatusTypeID: -1,
      PersonResponsible: currentProfile.UserAccountID,
      PersonResponsibleName:
        currentProfile.FirstName + " " + currentProfile.Surname,
      InitiatedBy: "",
      LegalSubdivision: undefined,
      InternalNotes: "",
      Internal: false,
      Tags: [],
      InteractionPhase: [],
      ThreadInteraction: parentTitle === "Thread" ? [parentValue] : [],
    };
  }

  const {
    childTagList,
    childTagsById,
    isChildLoading: tagIsLoading,
  } = useSelector((state: RootState) => state.tag);

  const tags = ft_all_Tag ? childTagList.map((id) => childTagsById[id]) : [];

  const { contactList, contactsById } = useSelector(
    (state: RootState) => state.contacts
  );

  const contacts = contactList.map((id) => contactsById[id]);

  const { phaseList, phasesById } = useSelector(
    (state: RootState) => state.phase
  );
  const phases = phaseList.map((id) => phasesById[id]);

  const {
    interactionPhaseList,
    interactionPhasesById,
    isLoading: interactionPhaseIsLoading,
  } = useSelector((state: RootState) => state.interactionPhases);

  const InteractionPhase = interactionPhaseList.map(
    (id) => interactionPhasesById[id]
  );

  const { interactionTypesById, isLoading: typeIsLoading } = useSelector(
    (state: RootState) => state.interactionTypes
  );

  const { clientInteractionTypesById, clientInteractionTypeList } = useSelector(
    (state: RootState) => state.clientInteractionTypes
  );

  const clientInteractionTypes = clientInteractionTypeList.map(
    (id) => clientInteractionTypesById[id]
  );

  const {
    statusTypeList,
    statusTypesById,
    isLoading: statusIsLoading,
  } = useSelector((state: RootState) => state.statusTypes);

  const statusTypes = statusTypeList.map((id) => statusTypesById[id]);

  const { getEngagementsPowerUserRoles } = useRoleChecks();

  const engagementPowerUserRoles = getEngagementsPowerUserRoles();

  // list of project IDs available in this Module
  const accessibleProjectIDs = engagementPowerUserRoles
    .filter((role) => role.UserAccountID === currentProfile.UserAccountID)
    .map((role) => role.ProjectID);

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
  } = useSelector((state: RootState) => state.projects);

  // if admin, show all projects
  // if non-admin, only show projects included in accessibleProjectIds
  const projects = currentProfile.IsClientAdmin
    ? projectList.map((id) => projectsById[id])
    : projectList
        .map((id) => projectsById[id])
        .filter((project) => accessibleProjectIDs.includes(project.ProjectID));

  const { stakeholderGroupList, stakeholderGroupsById } = useSelector(
    (state: RootState) => state.stakeholderGroups
  );

  const stakeholderGroups = stakeholderGroupList.map(
    (id) => stakeholderGroupsById[id]
  );

  const { issueList, issuesById } = useSelector(
    (state: RootState) => state.issues
  );

  const issues = issueList.map((id) => issuesById[id]);

  const { userList, usersById } = useSelector(
    (state: RootState) => state.users
  );

  // Filter for active users only
  const allUsers = userList.map((id) => usersById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchStatusTypes(accessToken));
        dispatch(fetchInteractionTypes(accessToken));
        dispatch(fetchClientInteractionTypes(accessToken));
        dispatch(fetchUsers(accessToken, clientId));
        if (parentTitle !== t("objStk:objects.issue.name")) {
          dispatch(fetchIssues(accessToken));
        }
        if (parentTitle !== t("objCom:objects.commitment.name")) {
          dispatch(fetchProjects(accessToken));
          if (parentTitle !== t("objPlt:objects.contact.name")) {
            dispatch(fetchContacts(accessToken));
          }
          if (parentTitle !== t("objPlt:objects.group.name")) {
            dispatch(fetchStakeholderGroups(accessToken));
          }
        }
        if (parentTitle === t("objCom:objects.commitment.name")) {
          dispatch(fetchInteractions(accessToken));
        }
        if (ft_eng_Phase) {
          dispatch(fetchPhases(accessToken));
          dispatch(
            fetchInteractionPhasesByInteraction(
              accessToken,
              itemData.InteractionID
            )
          );
        }
        ft_eng_Thread &&
          // prevent page loading in Thread Page
          parentTitle !== "Thread" &&
          dispatch(fetchThreads(accessToken));
        if (ft_all_Tag) {
          dispatch(fetchChildTagsByRecordType(accessToken, "Interaction"));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, parentTitle, getAccessTokenSilently]);

  // initialize values of array for parent item
  useEffect(() => {
    switch (parentTitle) {
      case t("objPlt:objects.group.name"):
        setListGroups([parentValue]);
        break;

      case t("objPlt:objects.contact.name"):
        setSelectedContacts([parentValue]);
        break;

      case t("objStk:objects.issue.name"):
        setListIssues([parentValue]);
        break;

      case t("objStk:objects.grievance.name"):
        setListGrievances([parentValue]);
        break;

      default:
        break;
    }
    return () => {
      // cleanup
    };
  }, []);

  // convert tags to objects for multi autocomplete field
  useEffect(() => {
    // effect
    if (
      ft_all_Tag &&
      !tagIsLoading &&
      tags &&
      tags.length > 0 &&
      itemData.InteractionID &&
      itemData.Tags
    ) {
      let newList = tags.filter((item) => itemData.Tags.includes(item.Name));
      setTagItems(newList);
    }
  }, [isEditing, tagIsLoading]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (Object.keys(currentInteraction).length > 0) {
          dispatch(
            updInteraction(
              accessToken,
              values.InteractionID,
              values,
              null,
              null,
              null,
              true,
              snackbarConstants,
              parentTitle?.toLowerCase() === "thread" ? null : parentID
            )
          );
        } else {
          dispatch(
            addInteraction(
              accessToken,
              values,
              selectedContacts,
              listGroups,
              listIssues,
              listGrievances,
              false,
              snackbarConstants
            )
          );
          setDidSaveInventory(true);
        }
        closeAction();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  // get lists for threads
  const { threadList, threadsById } = useSelector(
    (state: RootState) => state.threads
  );
  const unfilteredThreads = threadList?.map((id) => threadsById[id]);

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: InteractionValidationSchema(),
    onSubmit: submitFunc,
  });

  const threadColumns = [
    {
      field: "SequenceID",
      title: t("objStk:objects.thread.fields.id"),
      format: "T-{0:0}",
      filter: "string",
      columnWidth: 100,
      noLink: true,
    },
    {
      field: "ThreadTitle",
      title: t("objStk:objects.thread.name"),
      columnWidth: 350,
    },
    {
      field: "Contacts",
      title: t("objStk:objects.interaction.fields.contact", { count: 2 }),
      commaDelimited: true,
    },
    {
      field: "Groups",
      title: t("objStk:objects.interaction.fields.groups"),
      commaDelimited: true,
    },
    {
      field: "InteractionCount",
      title: t("objStk:objects.thread.interactioncount"),
    },
    {
      field: "lastInteractionDate",
      title: t("objStk:objects.thread.lastinteractiondate"),
      filter: "date",
      format: "{0:d}",
    },
  ];
  const DetailComponent = (props: any) => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <IppDisplayRichText
            label={t("objStk:objects.thread.fields.summary")}
            value={props.dataItem?.Summary}
            isEditing={false}
            setIsEditing={() => {}}
            showEdit={false}
            height={props.dataItem?.Summary ? "80px" : ""}
            button={
              aiEnabled ? (
                <IppGenerateSummaryButton
                  onClick={() => {
                    setGenerateObjectType("thread");
                    setThreadData(props.dataItem);
                    setGenerateDialogOpen(true);
                  }}
                  disabled={
                    !props.dataItem?.ThreadTitle || !props.dataItem?.ProjectID
                  }
                  toolTipMessage={
                    !props.dataItem?.ThreadTitle || !props.dataItem?.ProjectID
                      ? t("strGen:buttons.tooltip.generatesummarydisabled", {
                          fieldname: `a ${t(
                            "objStk:objects.thread.fields.summary"
                          )}`,
                        })
                      : t("strGen:buttons.tooltip.generatesummary")
                  }
                  isIconButton={false}
                  variant="text"
                  child={t("strGen:buttons.generatefieldname", {
                    fieldname: t("objStk:objects.thread.fields.summary"),
                  })}
                  sx={{
                    fontSize: "0.7rem",
                  }}
                />
              ) : undefined
            }
          />
        </Grid>
      </Grid>
    );
  };

  const threadSubmitHandler = (values: any) => {
    if (!values.ThreadID || !values.ThreadTitle || !values.ProjectID) {
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(updThread(accessToken, values.ThreadID, values, true));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let threadOptionProps = {
    optionColumns: threadColumns,
    optionDetail: DetailComponent,
    optionSearch: "ThreadTitle",
  };

  useEffect(() => {
    const threadIDs = (formik.values?.ThreadInteraction || []).map(
      (ti: any) => ti.ThreadID
    );
    // if projectID changes, reset ThreadOptions, empty the ThreadInteraction
    let threads = unfilteredThreads
      .filter(
        (thread) =>
          thread.ProjectID === formik.values.ProjectID &&
          !threadIDs.includes(thread.ThreadID)
      )
      .map((t: any) => {
        return {
          ...t,
          DisplayValue: `T-${t.SequenceID}  ${t.ThreadTitle}`,
          lastInteractionDate: ConvertDateOffset(t.lastInteractionDate),
        };
      });
    setThreadTokenOptions(threads);
  }, [
    itemData.InteractionID,
    formik.values?.ProjectID,
    formik.values?.ThreadInteraction,
  ]);

  useEffect(() => {
    if (isEditing) {
      let selectedThreads = itemData.ThreadInteraction?.map((t: any) => {
        return {
          ...t,
          DisplayValue: `T-${t.SequenceID}  ${t.ThreadTitle?.replace(
            /\n/g,
            "\\n"
          )}`,
        };
      });
      formik.setFieldValue("ThreadInteraction", selectedThreads);
    }
  }, [isEditing]);

  let detailForm =
    typeIsLoading || statusIsLoading || interactionPhaseIsLoading ? (
      <LoadingIndicator />
    ) : (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {Object.keys(currentInteraction).length > 0 && isEditing && (
            <Grid item xs={12}>
              <Typography variant="h6">
                {t("strGen:prompts.edit.edittitle", {
                  fieldname: t("objStk:objects.interaction.name"),
                })}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <IppTextField
              id="InteractionTitle"
              required
              label={t("objStk:objects.interaction.fields.title")}
              value={formik.values.InteractionTitle}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.InteractionTitle}
              errorsExpression={formik.errors.InteractionTitle}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppDatePicker
              id="InteractionDate"
              required
              label={t("objStk:objects.interaction.fields.date")}
              value={ConvertDateOffset(formik.values.InteractionDate)}
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue(
                  "InteractionDate",
                  GetJSDate(newValue),
                  true
                );
                formik.setFieldTouched("InteractionDate", true, false);
              }}
              errorsExpression={formik.errors.InteractionDate}
              touchedExpression={formik.touched.InteractionDate}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="InteractionTypeID"
              required
              options={clientInteractionTypes}
              value={clientInteractionTypes.find((obj) => {
                return (
                  obj.InteractionTypeID === formik.values.InteractionTypeID
                );
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "InteractionTypeID",
                    newValue.InteractionTypeID
                  );
                } else {
                  formik.setFieldValue("InteractionTypeID", -1);
                }
              }}
              errorFunction={formik.errors.InteractionTypeID}
              touchedFunction={formik.touched.InteractionTypeID}
              label={t("objStk:objects.interaction.fields.communicationtype")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.InteractionTypeName}
              textValueFunction={
                !typeIsLoading &&
                interactionTypesById[formik.values.InteractionTypeID] &&
                formik.values.InteractionTypeID > 0
                  ? interactionTypesById[formik.values.InteractionTypeID]
                      .InteractionTypeName
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="StatusTypeID"
              required
              options={statusTypes}
              value={statusTypes.find((obj) => {
                return obj.StatusTypeID === formik.values.StatusTypeID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("StatusTypeID", newValue.StatusTypeID);
                } else {
                  formik.setFieldValue("StatusTypeID", -1);
                }
              }}
              errorFunction={formik.errors.StatusTypeID}
              touchedFunction={formik.touched.StatusTypeID}
              label={t("objStk:objects.interaction.fields.status")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.StatusTypeName}
              textValueFunction={
                !statusIsLoading &&
                statusTypesById[formik.values.StatusTypeID] &&
                formik.values.StatusTypeID > 0
                  ? statusTypesById[formik.values.StatusTypeID].StatusTypeName
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="ProjectID"
              required
              options={projects}
              value={projects.find((obj) => {
                return obj.ProjectID === formik.values.ProjectID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("ProjectID", newValue.ProjectID);
                  formik.setFieldValue("ThreadInteraction", []);
                } else {
                  formik.setFieldValue("ProjectID", -1);
                  formik.setFieldValue("ThreadInteraction", []);
                }
              }}
              errorFunction={formik.errors.ProjectID}
              touchedFunction={formik.touched.ProjectID}
              label={t("objStk:objects.interaction.fields.project")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.ProjectName}
              textValueFunction={
                !projectIsLoading &&
                projectsById[formik.values.ProjectID] &&
                formik.values.ProjectID > 0
                  ? projectsById[formik.values.ProjectID].ProjectName
                  : ""
              }
              disabled={presetProjectID !== -1}
            />
          </Grid>
          {ft_eng_Phase && (
            <Grid item xs={6}>
              <IppTokenMultiSelect
                id="InteractionPhase"
                label={t("objStk:objects.interaction.fields.phase", {
                  count: formik.values.InteractionPhase
                    ? formik.values.InteractionPhase.length
                    : 1,
                })}
                required={false}
                options={phases}
                noOptionsText={t("strGen:components.nooptionstext")}
                value={
                  formik.values.InteractionPhase
                    ? formik.values.InteractionPhase
                    : InteractionPhase
                }
                setValue={(newValue: any) => {
                  formik.setFieldValue("InteractionPhase", newValue);
                }}
                touchedFunction={formik.touched.InteractionPhase}
                errorFunction={formik.errors.InteractionPhase}
                isEditing={true}
                optionLabelFunction={(option: any) => option.Name}
                setIsEditing={null}
              />
            </Grid>
          )}
          {ft_all_Tag && tags.length > 0 && (
            <Grid item xs={6}>
              <IppTokenMultiSelect
                id="Tags"
                label={t("objPlt:objects.tag.name_other", {
                  count: formik.values.Tags ? formik.values.Tags.length : 1,
                })}
                required={false}
                options={tags}
                noOptionsText={t("strGen:components.nooptionstext")}
                value={tagItems}
                setValue={(newValue: any) => {
                  setTagItems(newValue);
                  formik.setFieldValue("Tags", newValue);
                }}
                touchedFunction={formik.touched.Tags}
                errorFunction={formik.errors.Tags}
                isEditing={true}
                optionLabelFunction={(option: any) => option.Name}
                setIsEditing={null}
                toolTip={t("strGen:tooltips.tags")}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <IppPersonResponsible
              id="PersonResponsible"
              required
              value={allUsers.find((obj) => {
                return obj.UserAccountID === formik.values.PersonResponsible;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "PersonResponsible",
                    newValue.UserAccountID
                  );
                } else {
                  formik.setFieldValue("PersonResponsible", null);
                }
              }}
              label={t("objStk:objects.interaction.fields.personresponsible")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) =>
                option.FirstName + " " + option.Surname
              }
              errorFunction={formik.errors.PersonResponsible}
              touchedFunction={formik.touched.PersonResponsible}
              textValueFunction={formik.values.PersonResponsibleName}
              projectID={formik.values.ProjectID}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="InitiatedBy"
              required
              options={InitiatedByValues}
              value={formik.values.InitiatedBy}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("InitiatedBy", newValue);
                } else {
                  formik.setFieldValue("InitiatedBy", "");
                }
              }}
              label={t("objStk:objects.interaction.fields.initiatedby")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option}
              errorFunction={formik.errors.InitiatedBy}
              touchedFunction={formik.touched.InitiatedBy}
              textValueFunction={formik.values.InitiatedBy}
              toolTip={t("strGen:tooltips.interaction.fields.initiatedby")}
            />
          </Grid>
          {ft_eng_IntenalCommunication && (
            <Grid item xs={6}>
              <IppCheckbox
                id="Internal"
                label={
                  t("objStk:objects.interaction.fields.internalcommunication") +
                  "?"
                }
                value={formik.values.Internal}
                onChangeFunction={formik.handleChange}
                isEditing={isEditing}
              />
            </Grid>
          )}
          {Object.keys(currentInteraction).length === 0 && (
            <>
              <Grid item xs={12}>
                <IppContactAutoComplete
                  options={contacts}
                  selectedContacts={selectedContacts}
                  setSelectedContacts={setSelectedContacts}
                />
              </Grid>
              <Grid item xs={12}>
                <IppTokenAutocomplete
                  id="GroupList"
                  label={t("objStk:objects.interaction.fields.groupsinvolved", {
                    count: 2,
                  })}
                  options={stakeholderGroups.map((option) => option.GroupName)}
                  selectedValues={listGroups}
                  setSelectedValues={setListGroups}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  setCanSave={setCanSave}
                />
              </Grid>
              <Grid item xs={12}>
                <IppTokenAutocomplete
                  id="IssueList"
                  label={t("objStk:objects.interaction.fields.relatedissues", {
                    count: 2,
                  })}
                  options={issues.map((option) => option.IssueName)}
                  selectedValues={listIssues}
                  setSelectedValues={setListIssues}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  setCanSave={setCanSave}
                />
              </Grid>
            </>
          )}

          {ft_eng_PILSD && (
            <Grid item xs={12}>
              <IppMultiTextField
                id="LegalSubdivision"
                label={t("objStk:objects.interaction.fields.legalsubdivision")}
                required={false}
                options={[]}
                noOptionsText={t("strGen:components.nooptionstext")}
                value={formik.values.LegalSubdivision}
                setValue={(newValue: any) =>
                  formik.setFieldValue("LegalSubdivision", newValue.toString())
                }
                touchedFunction={formik.touched.LegalSubdivision}
                errorFunction={formik.errors.LegalSubdivision}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                multiple={true}
                autoPopulate={false}
                freeSolo={true}
              />
            </Grid>
          )}
          {ft_eng_Thread && ft_eng_ThreadQuickAdd && (
            <Grid item xs={12}>
              <IppTokenTable
                id="ThreadInteraction"
                label={t("objStk:objects.interaction.fields.thread")}
                value={formik.values.ThreadInteraction}
                setValue={(newValue: any) => {
                  formik.setFieldValue("ThreadInteraction", newValue);
                }}
                optionLabelFunction={(option) => option.DisplayValue}
                disabled={
                  formik.values.ProjectID === -1
                    ? t("strGen:tooltips.thread.buttons.disabledchildadd")
                    : ""
                }
                required={false}
                title={t("objStk:objects.thread.name")}
                parentTitle={t("objStk:objects.interaction.name")}
                idField="ThreadInteractionID"
                relatedField="ThreadID"
                options={threadTokenOptions}
                optionTableProps={threadOptionProps}
                tableName="InteractionThread"
              />
            </Grid>
          )}
          {ft_eng_InternalNotes && (
            <Grid item xs={12}>
              {/* <IppTextField
                id="InternalNotes"
                label={t("objStk:objects.interaction.fields.internalnotes")}
                value={formik.values.InternalNotes}
                required={false}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.InternalNotes}
                errorsExpression={formik.errors.InternalNotes}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                multiLine={true}
                toolTip={t("strGen:tooltips.interaction.fields.internalnotes")}
              /> */}
              <IppRichTextEditor
                id="InternalNotes"
                label={t("objStk:objects.interaction.fields.internalnotes")}
                value={formik.values.InternalNotes}
                setFieldValue={formik.setFieldValue}
                touchedExpression={formik.touched.InternalNotes}
                errorsExpression={formik.errors.InternalNotes}
                isEditing={isEditing}
                height={150}
                toolTip={t("strGen:tooltips.interaction.fields.internalnotes")}
                standardStatementsRecordType="InteractionInternalNotes"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            {/* <IppTextField
              id="Details"
              label={t("objStk:objects.interaction.fields.details")}
              value={formik.values.Details}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.Details}
              errorsExpression={formik.errors.Details}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              multiLine={true}
            /> */}
            <IppRichTextEditor
              key={detailsKey}
              id="Details"
              label={t("objStk:objects.interaction.fields.details")}
              value={formik.values.Details}
              setFieldValue={formik.setFieldValue}
              touchedExpression={formik.touched.Details}
              errorsExpression={formik.errors.Details}
              isEditing={isEditing}
              height={150}
              standardStatementsRecordType="InteractionDetails"
              customEditorTool={
                aiEnabled && itemData.InteractionID ? (
                  <IppGenerateSummaryButton
                    onClick={() => {
                      setGenerateObjectType("interaction");
                      setGenerateDialogOpen(true);
                    }}
                    toolTipMessage={t("strGen:buttons.tooltip.generatedetails")}
                    onMouseDown={(e) => e.preventDefault()}
                    onPointerDown={(e) => e.preventDefault()}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    sx={{
                      textTransform: "none",
                      fontWeight: "normal",
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      padding: "8px",
                      height: "38px",
                      width: "38px",
                      "& .MuiSvgIcon-root": {
                        fontSize: "1.25rem",
                      },
                    }}
                  />
                ) : undefined
              }
            />
          </Grid>
          <Grid item xs={12}>
            <IppFormButtons
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              isAdding={!itemData.InteractionID}
              showCancel={true}
              resetFunction={closeAction}
            />
          </Grid>
        </Grid>
      </form>
    );

  return (
    <div>
      {detailForm}
      {aiEnabled &&
      generateDialogOpen &&
      generateObjectType === "interaction" ? (
        <IppGenerateSummaryDialog
          titleKey="interactions"
          fieldname={t("objStk:objects.interaction.fields.details")}
          data={formik.values}
          initialValue={formik.values.Details}
          aiSummaryApiFunction={generateInteractionDetailsWithAi}
          open={generateDialogOpen}
          setOpen={setGenerateDialogOpen}
          onSubmit={(value: string) => {
            formik.setFieldValue("Details", value);
            setDetailsKey((prev) => prev + 1);

            if (!isEditing) formik.handleSubmit();
          }}
          standardStatementsRecordType="InteractionDetails"
        />
      ) : (
        <IppGenerateSummaryDialog
          titleKey="threads"
          fieldname={t("objStk:objects.thread.fields.summary")}
          data={threadData}
          initialValue={threadData?.Summary}
          aiSummaryApiFunction={generateThreadSummaryWithAi}
          open={generateDialogOpen}
          setOpen={setGenerateDialogOpen}
          onSubmit={(value: string) => {
            setThreadData({ ...threadData, Summary: value });
            threadSubmitHandler({ ...threadData, Summary: value });
          }}
          standardStatementsRecordType="InteractionThreadSummary"
        />
      )}
    </div>
  );
};
